import { Loader } from '@components/Loader';
import { useEditCustomerMutation } from '@modules/dashboard/account/account-api-slice';
import { Formik } from 'formik';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BillingDetails, Countries, Customer } from 'src/types/types';
import BillingDetailsForm from './BillingDetailsForm';

type SettingsProps = {
  countries: Countries;
  customer: Customer;
  customerResponseIsFetching: boolean;
  billingDetails: BillingDetails;
  updateBillingDetails: (details: Partial<BillingDetails>) => void;
  billingDetailsAreReady: boolean;
};
const Settings = ({
  countries,
  customerResponseIsFetching,
  billingDetails,
  updateBillingDetails,
  billingDetailsAreReady,
}: SettingsProps) => {
  const { t } = useTranslation();
  // EditCustomer
  const [editCustomer] = useEditCustomerMutation();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await editCustomer({
        company_name: values.companyName,
        address: {
          zip: values.zipCode,
          country: values.country,
          street1: values.primaryAddress,
          street2: values.secondaryAddress,
          state: values.state,
          city: values.city,
        },
        tax: {
          tax_number: values.vat,
        },
        invoice: {
          invoice_ref: values.purchaseOrder,
          invoice_attendee: values.attentionTo,
        },
        email: {
          email_invoice: values.email_invoice,
        },
      }).unwrap();
      updateBillingDetails(values);
      toast.success(t('billing.settings.billingDetailsUpdated'));
    } catch (error) {
      const errorMessage = error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch');
      toast.error(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  if (customerResponseIsFetching || !billingDetailsAreReady) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={billingDetails}
      onSubmit={handleSubmit}
      validate={(values) => {
        interface Errors {
          attentionTo?: string;
          companyName?: string;
          purchaseOrder?: string;
        }
        const errors: Errors = {};
        if (!values.attentionTo) {
          errors.attentionTo = 'Required';
        }
        return errors;
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="w-65">
          <h3 className="fw-500 mb-2">General</h3>
          <Form.Group className="mb-3">
            <Form.Label>Company name</Form.Label>
            <InputGroup className="p-0">
              <FormControl
                value={values.companyName}
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                name="companyName"
              />
            </InputGroup>
            <span className="text-danger">
              {errors.companyName && touched.companyName && (errors.companyName as string)}
            </span>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-0">Purchase Order (PO) Number</Form.Label>
            <small className="form-text text-muted">
              Purchase order (PO) number shows on the invoice and will go into effect immediately.
            </small>
            <InputGroup className="p-0">
              <FormControl
                value={values.purchaseOrder}
                onBlur={handleBlur}
                onChange={handleChange}
                type="string"
                name="purchaseOrder"
              />
            </InputGroup>
            <span className="text-danger">
              {errors.purchaseOrder && touched.purchaseOrder && (errors.purchaseOrder as string)}
            </span>
          </Form.Group>
          <hr className="my-5" />
          {countries && (
            <BillingDetailsForm
              errors={errors}
              handleBlur={handleBlur}
              countries={countries}
              handleChange={(e) => {
                handleChange(e);
                updateBillingDetails({ [e.target.name]: e.target.value });
              }}
              touched={touched}
              setFieldValue={(field, value) => {
                setFieldValue(field, value);
                updateBillingDetails({ [field]: value });
              }}
              values={values}
            />
          )}
          <Button className="mt-3 lift" variant="primary" type="submit" disabled={isSubmitting}>
            Save changes
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Settings;
