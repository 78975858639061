import { useEffect, useState } from "react";

const TerminalController = ({ logs, networkName, username = "", handleRefresh, isRefetching }) => {
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setRefreshCounter(refreshCounter + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetching]);

  const onRefreshClick = async () => {
    setIsFetching(true);
    if (!isFetching) {
      setRefreshCounter(refreshCounter + 1);
      await handleRefresh();
      setIsFetching(false);
    }
  };

  return (
    <div className="terminal-container">
      <div className="d-flex align-items-center position-relative mt-3">
        <div className="terminal-title justify-content-center d-flex w-100">{`${username}@${networkName}`}</div>
      </div>
      <div className="text-white mt-3 mx-4">
        {logs.map((log, index) => (
          <div key={index} className="terminal-log">{`${index + 1} ${log}`}</div>
        ))}
        <div className="d-flex justify-content-end my-2">
          <i
            key={refreshCounter}
            className="fe fe-refresh-cw fs-3 rotate-center cursor-pointer"
            onClick={onRefreshClick}
          />
        </div>
      </div>
    </div>
  );
};

export default TerminalController;
