import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { FormField } from '../../../auth';

const MfaForm = ({ onSubmit }) => {
  return (
    <Formik initialValues={{ mfaCode: '' }} onSubmit={onSubmit}>
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FormField
            value={values.mfaCode}
            handleChange={handleChange}
            touched={touched.mfaCode}
            handleBlur={handleBlur}
            error={errors.mfaCode}
            placeholder="123456"
            name="mfaCode"
            type="text"
            label="MFA Code"
          />
          <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
            Verify MFA Code
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default MfaForm;
