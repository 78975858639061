import { Field, Checkbox as HeadlessCheckbox, Label } from "@headlessui/react";
import { ElementType, useEffect, useRef } from "react";
import { useHover } from "src/lib/utils/hooks"; // Ensure this hook is correctly implemented

type CheckboxProps = {
  checked: boolean;
  onChange: () => void;
  label?: string;
  indeterminate?: boolean;
  disabled?: boolean;
  passiveLabel?: boolean;
  as?: ElementType;
  charsLimit?: number;
  actionProps?: Record<string, unknown>;
  actionIcon?: JSX.Element;
  handleAction?: (e: any) => void;
};

export function Checkbox({
  label,
  checked = false,
  onChange,
  indeterminate = false,
  disabled = false,
  passiveLabel = false,
  as = "span",
  charsLimit = 100,
  actionProps,
  actionIcon,
  handleAction,
}: CheckboxProps) {
  const { ref: hoverRef, isHovered } = useHover();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const renderLabel = () => {
    if (typeof label === "string" && label.length > charsLimit) {
      return label.slice(0, charsLimit) + "..";
    }
    return label;
  };

  return (
    <div ref={hoverRef} className="tw-flex tw-items-center tw-gap-2">
      <Field className="tw-flex tw-items-center tw-gap-2" disabled={disabled}>
        <HeadlessCheckbox
          checked={checked}
          onChange={onChange}
          className="tw-group tw-inline-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 tw-rounded-md tw-bg-checkbox-background tw-text-blue-600 data-[checked]:tw-bg-blue-500 tw-cursor-pointer"
          as={as}
          disabled={disabled}
          ref={inputRef}
        >
          <svg
            className="tw-stroke-white tw-opacity-0 group-data-[checked]:tw-opacity-100"
            viewBox="0 0 14 14"
            fill="none"
            width="11"
            height="11"
          >
            <path d="M4 7L6 9L10 4" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </HeadlessCheckbox>
        {!!label && (
          <Label className={passiveLabel ? "" : "tw-cursor-pointer"} passive={passiveLabel}>
            {renderLabel()}
          </Label>
        )}
      </Field>
      {actionIcon && (
        <span
          className={`tw-float-end ${isHovered ? "tw-visible" : "tw-invisible"} tw-cursor-pointer`}
          onClick={handleAction}
          {...actionProps}
        >
          {actionIcon}
        </span>
      )}
    </div>
  );
}
