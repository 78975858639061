import { HTMLProps, useEffect, useRef } from "react";

export const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="form-check d-flex justify-content-center align-items-center">
      <input type="checkbox" ref={ref} className={className + " cursor-pointer form-check-input d-flex "} {...rest} />
    </div>
  );
};
