import { Icon } from '@components/Atoms/Icon/Icon';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { AnchorProps } from 'node_modules/@headlessui/react/dist/internal/floating';
import { ReactNode, useCallback, useState } from 'react';

type HandleClickFunction<T> = T extends void ? () => void : (arg: T) => void;

type GenericDropdownProps<T = void> = {
  children: (open: boolean) => ReactNode;
  menuItems: { title: string; handleClick: HandleClickFunction<T>; icon?: string }[];
  anchorPosition?: AnchorProps;
  contextData?: T;
};

const FILLED_CLASSES = 'tw-bg-sb-blue-grey-25 !tw-border-sb-blue-grey-300';
const MENU_ITEMS_CLASS = `
  tw-border-sb-blue-grey-300 tw-flex tw-flex-col tw-items-start tw-justify-center 
  tw-gap-[5px] tw-rounded-lg tw-border tw-border-solid tw-bg-white tw-px-[6px] 
  tw-py-[5px] tw-shadow-[0_0_15px_-5px_#46719C5C] [--anchor-gap:10px] focus:tw-outline-none
  tw-z-20
`;
const MENU_ITEM_CLASS = `
  tw-text-sb-blue-grey-600 tw-flex tw-h-[45px] tw-w-[168px] tw-items-center 
  tw-gap-[10px] tw-rounded-md tw-border tw-border-solid tw-border-transparent 
  tw-p-[10px] tw-text-[14px] hover:tw-cursor-pointer
`;

export function GenericDropdown<T = void>({
  children,
  menuItems,
  anchorPosition = 'bottom',
  contextData,
}: GenericDropdownProps<T>) {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [pressedIndex, setPressedIndex] = useState<number | null>(null);

  const handleMouseEnter = useCallback((index: number) => setHoveredIndex(index), []);
  const handleMouseLeave = useCallback(() => setHoveredIndex(null), []);
  const handleMouseDown = useCallback((index: number) => setPressedIndex(index), []);
  const handleMouseUp = useCallback(() => setPressedIndex(null), []);

  return (
    <Menu>
      {({ open }) => (
        <>
          <MenuButton as="div">{children(open)}</MenuButton>
          <MenuItems anchor={anchorPosition} className={MENU_ITEMS_CLASS}>
            {menuItems.map(({ title, icon, handleClick }, index) => (
              <MenuItem
                key={index}
                as="div"
                className={`${MENU_ITEM_CLASS} ${hoveredIndex === index ? FILLED_CLASSES : ''}`}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onMouseDown={() => handleMouseDown(index)}
                onMouseUp={handleMouseUp}
                onClick={() => {
                  if (contextData !== undefined) {
                    (handleClick as (arg: T) => void)(contextData);
                  } else {
                    (handleClick as () => void)();
                  }
                }}
              >
                {icon && <Icon name={pressedIndex === index ? `${icon}_pressed` : icon} />}
                {title}
              </MenuItem>
            ))}
          </MenuItems>
        </>
      )}
    </Menu>
  );
}
