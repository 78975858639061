import { Icon } from '@components/Atoms/Icon/Icon';
import { useCallback, useEffect, useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { Button } from '../Button/Button';

type NavLinkProps = {
  icon: 'home' | 'private_network' | 'integrations' | 'sim_cards' | 'billing' | 'settings';
  url: string;
  sidebarOpen: boolean;
  title?: string;
  state?: { active: boolean };
  className?: string;
};

const BASE_URL = '/dashboard';
const CLASSES = {
  default: 'tw-border-white',
  hover: 'tw-border-[#DBE4F6] tw-bg-[#F9FBFE] !tw-text-[#123466]',
  active: 'tw-border-[#DBE4F6] tw-bg-[#F9FBFE] !tw-text-[#123466] tw-font-semibold',
};

export const NavLink: React.FC<NavLinkProps> = ({
  icon,
  title,
  className = '',
  url,
  state = { active: false },
  sidebarOpen = true,
}) => {
  const [isActive, setIsActive] = useState(state.active);

  const [additionalClasses, setAdditionalClasses] = useState(CLASSES.default);

  useEffect(() => {
    setIsActive(state.active);
    setAdditionalClasses(state.active ? CLASSES.active : CLASSES.default);
  }, [state.active]);

  const handleMouseDown = useCallback(() => {
    if (!isActive) setAdditionalClasses(CLASSES.active);
  }, [isActive]);

  const handleMouseLeave = useCallback(() => {
    if (!isActive) setAdditionalClasses(CLASSES.default);
  }, [isActive]);

  const handleHover = useCallback(() => {
    if (!isActive) setAdditionalClasses(CLASSES.hover);
  }, [isActive]);

  const getAppliedClasses = useCallback(() => {
    const baseClasses =
      'tw-border tw-border-solid tw-flex tw-w-full tw-h-[45px] tw-items-center tw-rounded-md tw-no-underline tw-text-[#46719C] tw-font-normal';
    const sidebarClasses = sidebarOpen
      ? 'tw-pb-[10px] tw-pl-[42px] tw-pr-5 tw-pt-[10px] tw-gap-[10px]'
      : 'tw-justify-center tw-p-2.5';
    return `${baseClasses} ${sidebarClasses} ${additionalClasses}`;
  }, [sidebarOpen, additionalClasses]);

  return (
    <Button
      variant="borderless"
      className={`tw-flex tw-w-full tw-items-center tw-justify-center tw-pb-0 tw-pl-0 tw-pr-0 tw-pt-0 ${className}`}
      size="medium"
    >
      <RouterNavLink
        end
        to={`${BASE_URL}/${url}`}
        state={state}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseLeave}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleHover}
        aria-label={title}
        className={getAppliedClasses()}
        id={url}
      >
        <Icon name={isActive ? `${icon}_pressed` : icon} />
        {title && <span>{title}</span>}
      </RouterNavLink>
    </Button>
  );
};
