import { Icon } from '@components/Atoms/Icon/Icon';
import { FC } from 'react';
import { StatusSummaryStatus } from 'src/types/types';

type SideBarStatusBarProps = {
  status: StatusSummaryStatus;
  sidebarOpen: boolean;
  url: string;
};

const styles = {
  link: 'tw-absolute tw-bottom-[-1px] tw-left-[-1px]',
  openBar:
    'tw-flex tw-h-9 tw-w-60 tw-items-center tw-bg-[#123466] tw-p-[10px] tw-pl-6 tw-text-sm tw-text-white tw-justify-start tw-gap-[11px]',
  closedBar:
    'tw-absolute tw-bottom-0 tw-flex tw-h-9 tw-w-[63px] tw-items-center tw-justify-center tw-bg-[#123466] tw-p-[10px] tw-text-sm tw-text-white',
};

export const SideBarStatusBar: FC<SideBarStatusBarProps> = ({ status, sidebarOpen, url }) => {
  const OpenContent = () => (
    <div className={styles.openBar}>
      <div className="tw-flex tw-h-[26px] tw-w-[26px] tw-items-center tw-justify-center">
        <Icon name={`${status?.indicator}_icon`} height={10} width={10} />
      </div>
      <span>{status.description}</span>
    </div>
  );

  const ClosedContent = () => (
    <div className={styles.closedBar}>
      <Icon name={`${status?.indicator}_icon`} height={10} width={10} />
    </div>
  );

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}>
      {sidebarOpen ? <OpenContent /> : <ClosedContent />}
    </a>
  );
};
