import { useGetCustomerQuery } from "@modules/dashboard/account/account-api-slice";
import { Badge, Button, Card } from "react-bootstrap";
import { Customer } from "src/types/types";
import { useGetCountriesQuery } from "../../billing-api-slice";

const BillingSettingsCard = ({ handleEditBillingDetails }) => {
  const getCustomerResponse = useGetCustomerQuery();
  const details = getCustomerResponse.data ?? ({} as Customer);

  const countriesResponse = useGetCountriesQuery();
  const { data: countries = [] } = countriesResponse;

  return (
    <Card className="w-100">
      <Card.Header>
        <h4 className="card-header-title text-capitalize">Billing settings</h4>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column">
          <div>
            <strong className="text-body">{details?.customerDetails?.company_name}</strong>
            <br />
            {details?.customerDetails?.address?.street1 || details?.customerDetails?.address?.street2 ? (
              <>{`${details?.customerDetails?.address?.street1 || ""} ${details?.customerDetails?.address?.street2 || ""}`}</>
            ) : (
              <span className="text-muted fst-italic">Address</span>
            )}
            <br />
            {details?.customerDetails?.address?.zip && <span>{details?.customerDetails?.address?.zip}, </span>}
            {details?.customerDetails?.address?.city && <span>{details?.customerDetails?.address?.city}, </span>}
            {details?.customerDetails?.address?.state && <span>{details?.customerDetails?.address?.state}</span>}
            {details?.customerDetails?.address?.zip ||
            details?.customerDetails?.address?.city ||
            details?.customerDetails?.address?.state ? null : (
              <span className="text-muted fst-italic">City, Postal/ZIP, State/Province</span>
            )}
            <br />
            {countries?.find(({ iso }) => details?.customerDetails?.address?.country === iso)?.name || (
              <span className="text-muted fst-italic">Country</span>
            )}
            <br />
          </div>
          <div>
            {details?.customerDetails?.tax?.tax_number_valid === "True" && <Badge bg="success">VAT verified</Badge>}
            {details?.customerDetails?.tax?.tax_number_valid === "False" && <Badge bg="danger">VAT not verified</Badge>}
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-end">
          <Button onClick={handleEditBillingDetails} variant="primary">
            Edit
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default BillingSettingsCard;
