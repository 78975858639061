import { Modal } from 'react-bootstrap';
import UserForm from './UserForm';

const EditUser = ({ isOpen, handleClose, handleEdit, user }) => {
  return (
    <Modal
      dialogClassName='w-30'
      centered
      show={isOpen}
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton>
        <h3 className='header-title'>Edit member</h3>
      </Modal.Header>
      <Modal.Body>
        <UserForm onSubmit={handleEdit} isEdit user={user} />
      </Modal.Body>
    </Modal>
  );
};

export default EditUser;
