// https://stackoverflow.com/questions/70098392/react-chartjs-2-with-chartjs-3-error-arc-is-not-a-registered-element
import ipsecImage from '@assets/img/IPsec banner.png';
import ipsecLogo from '@assets/img/IPSec Logo.png';
import openVpnImage from '@assets/img/OpenVPN.png';
import { Button as NewButton } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { Loader } from '@components/Loader';
import { InfoToolTip } from '@components/Molecules/InfoToolTip/InfoToolTip';
import { SUPPORTPAGE_URL } from '@core/constants';
import { ArcElement, Chart } from 'chart.js';
import { useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import { useGetIpsecQuery, useGetNetworkQuery, useGetOpenVpnQuery, useGetPricingQuery } from '../network-api-slice';
import DisplayCard from './DisplayCard';
import HeaderCards from './HeaderCards';

Chart.register(ArcElement);

interface Options {
  responsive: boolean;
  maintainAspectRatio: boolean;
  cutout: string;
  hover: {
    intersect: boolean;
  };
  plugins: {
    filler: {
      propagate: boolean;
    };
    legend: {
      position: 'bottom' | 'center' | 'left' | 'top' | 'right' | 'chartArea';
    };
    tooltip: {
      intersect: boolean;
      callbacks: {
        title: (context: any[]) => string; // You should replace 'any' with the actual type of context
        label: (ctx: any) => string; // You should replace 'any' with the actual type of ctx
      };
    };
  };
}

const options: Options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '83%',
  hover: {
    intersect: true,
  },
  plugins: {
    filler: {
      propagate: false,
    },
    legend: {
      position: 'bottom',
    },
    tooltip: {
      intersect: true,
      callbacks: {
        title: (context) => {
          return context[0].label;
        },
        label: (ctx) => {
          const callbacks = ctx.chart.options.plugins.tooltip.callbacks;
          const before = callbacks.beforeLabel() || '';
          const after = callbacks.afterLabel() || '';

          return before + ctx.formattedValue + after;
        },
      },
    },
  },
};

const SERVER_STATUS = {
  TERMINATED: 'Terminated',
  ONLINE: 'online',
};

const IPSEC_STATUS = {
  NOT_CONFIGURED: 'Not configured',
};

const Overview = ({ id, onSeeDetailsClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // GetPricing
  const getPricingResponse = useGetPricingQuery();

  // GetIpsec
  const getIpsecResponse = useGetIpsecQuery({ id });
  const ipsec = getIpsecResponse.data ?? {};

  // GetOpenVpn
  const getOpenVpnResponse = useGetOpenVpnQuery({ id });
  const { openvpn_server_users_online, openvpn_server_max_clients, openvpn_server_status } =
    getOpenVpnResponse.data ?? {};

  // GetNetwork
  const getNetworkResponse = useGetNetworkQuery({ id });
  const { data: details } = getNetworkResponse;
  const { hosts = 0, freehosts = 0 } = details ?? {};

  const isProcessing = useIsProcessing([
    getNetworkResponse.isFetching,
    getOpenVpnResponse.isFetching,
    getOpenVpnResponse.isLoading,
    getIpsecResponse.isLoading,
    getIpsecResponse.isFetching,
    getPricingResponse.isFetching,
    getPricingResponse.isLoading,
  ]);

  const status = ipsec?.[0]?.fields?.[0]?.placeholder;
  const uptime = ipsec?.[0]?.fields?.[1]?.placeholder;

  const data = useMemo(() => {
    return {
      labels: ['Used', 'Free'],

      datasets: [
        {
          label: '# of Votes',
          data: [hosts - freehosts, freehosts],
          backgroundColor: ['#2C7BE5', '#A6C5F7'],
          borderColor: '#fff',
          borderWidth: 2,
          hoverOffset: 5,
          cutout: '50%',
        },
      ],
    };
    // eslint-disable-next-line
  }, [details]);

  const getIpsecStatusColor = (status) => {
    // eslint-disable-next-line default-case
    switch (status) {
      case IPSEC_STATUS.NOT_CONFIGURED:
        return 'warning';
    }
    return 'danger';
  };

  if (isProcessing) {
    return <Loader />;
  }

  const AssignSimsInfoBox = (
    <div className="tw-mb-8 tw-flex tw-max-w-56 tw-flex-col tw-items-center tw-gap-6">
      <span className="tw-text-sm">{t('infoBox.assignSims.sectionOne')}</span>

      <NewButton
        asLink={true}
        href="https://app.supademo.com/demo/KF-lQrgKKi2Lmbo6EIAl2"
        className="tw-w-40 !tw-font-bold"
      >
        {t('Tutorial')}
      </NewButton>
    </div>
  );

  const OpenVpnInfoBox = (
    <div className="tw-mb-8 tw-flex tw-max-w-56 tw-flex-col tw-items-center tw-gap-6">
      <span className="tw-text-sm">{t('infoBox.openVpn')}</span>

      <NewButton
        asLink={true}
        href={`${SUPPORTPAGE_URL}/the-developer/private-networks/open-vpn`}
        className="tw-w-auto !tw-font-bold"
      >
        {t('infoBox.openVpn.button')}
      </NewButton>
    </div>
  );

  const IPSecInfoBox = (
    <div className="tw-mb-8 tw-flex tw-max-w-56 tw-flex-col tw-items-center tw-gap-6">
      <span className="tw-text-sm">{t('infoBox.IPSec')}</span>

      <NewButton
        asLink={true}
        href={`${SUPPORTPAGE_URL}/the-developer/private-networks/ipsec`}
        className="tw-w-auto !tw-font-bold"
      >
        {t('infoBox.IPSec.button')}
      </NewButton>
    </div>
  );

  return (
    <div className="d-flex flex-column overview">
      <HeaderCards id={id} />
      <div
        style={{
          display: 'flex',
          gap: 24,
          marginTop: 24,
        }}
      >
        <div className="network-cards">
          <Card className="mb-0 h-100">
            <Card.Body className="d-flex flex-column justify-content-between">
              <div className="d-flex flex-grow-1">
                <Doughnut data={data} options={options} />
              </div>
              <hr className="mt-3 mb-0" />
              <div className="d-flex justify-content-center">
                <div className="border-end py-3 d-flex justify-content-center flex-column align-items-center w-100">
                  <h6 className="text-muted mb-2">FREE HOSTS</h6>
                  <div className="fw-700 fs-2">{freehosts}</div>
                </div>
                <div className="py-3 d-flex justify-content-center flex-column align-items-center w-100">
                  <h6 className="text-muted mb-2">USED HOSTS</h6>
                  <div className="fw-700 fs-2">{hosts - freehosts}</div>
                </div>
              </div>
              <hr className="m-0" />
              <div className="justify-content-end mt-4 tw-flex tw-items-center tw-gap-2">
                <InfoToolTip title={AssignSimsInfoBox}>
                  <div className="tw-flex tw-items-center">
                    <Icon name="question_info" width={14} height={14} />
                  </div>
                </InfoToolTip>
                <Button
                  className="mb-1 text-nowrap"
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    navigate('/dashboard/sim-cards', {
                      state: { showNetworkFilter: true },
                    });
                  }}
                >
                  Assign SIMs
                </Button>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="network-cards">
          <DisplayCard
            title="OpenVPN"
            subtitle="Free, secure remote access"
            leftContent={{
              label: 'USERS ONLINE',
              value: openvpn_server_users_online,
            }}
            rightContent={{
              label: 'MAX USERS',
              value: openvpn_server_max_clients,
            }}
            displayImage={openVpnImage}
            image="https://simbase-static-content.s3.eu-north-1.amazonaws.com/vpn/openvpn.png"
            footer={
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div>
                  <span
                    className={`me-1 text-${openvpn_server_status === SERVER_STATUS.ONLINE ? 'success' : 'danger'}`}
                  >
                    ●
                  </span>
                  <span>{openvpn_server_status}</span>
                </div>
                <div className="tw-flex tw-items-center tw-gap-2">
                  <InfoToolTip title={OpenVpnInfoBox}>
                    <div className="tw-flex tw-items-center">
                      <Icon name="question_info" width={14} height={14} />
                    </div>
                  </InfoToolTip>
                  <Button
                    className="mb-1 text-nowrap"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      onSeeDetailsClick();
                    }}
                  >
                    See details
                  </Button>
                </div>
              </div>
            }
          />
        </div>
        <div className="network-cards">
          <DisplayCard
            title="IPSec"
            subtitle="Secure bi-directional communication"
            leftContent={{
              label: 'UPTIME',
              value: uptime,
            }}
            rightContent={{
              label: 'STATE',
              value: status,
            }}
            displayImage={ipsecImage}
            image={ipsecLogo}
            footer={
              <div className="d-flex align-items-center justify-content-between mt-4">
                <div className="d-flex justify-content-start">
                  <span className={`me-1 text-${getIpsecStatusColor(status)}`}>●</span>
                  <span className="text-start">{status}</span>
                </div>
                <div className="tw-flex tw-items-center tw-gap-2">
                  <InfoToolTip title={IPSecInfoBox}>
                    <div className="tw-flex tw-items-center">
                      <Icon name="question_info" width={14} height={14} />
                    </div>
                  </InfoToolTip>
                  <Button
                    className="mb-1 text-nowrap"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      navigate('ipsec-configure');
                    }}
                  >
                    Deploy IPSec
                  </Button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
