import Loader from "@components/Loader";
import { SIM_STATE } from "@core/constants";
import { CustomDropdownToggle } from "@modules/dashboard/components";
import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useMutateSimStatus } from "../../hooks/useMutateSimStatus";

interface DropdownMenuProps {
  row: any;
  smsModalRef: any;
  assignToNetworkModalRef: any;
  deleteSimModalRef: any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  row,
  smsModalRef,
  assignToNetworkModalRef,
  deleteSimModalRef,
}) => {
  const [updatingRowIccid, setUpdatingRowIccid] = useState<string | null>(null);
  const { mutateSimStatus, updateSimStatusResponse } = useMutateSimStatus();

  return (
    <Dropdown drop="end" className="position-absolute ellipsis-menu-height d-flex ">
      <Dropdown.Toggle as={CustomDropdownToggle} data-test-id={`simcards-table:action-dropdown:toggle-${row.id}`}>
        {updateSimStatusResponse.isLoading && row.original.iccid === updatingRowIccid ? (
          <div className="h-100 d-flex my-auto">
            <Loader size={1} animation="grow" className="d-flex my-auto" />
          </div>
        ) : (
          <div
            className="dropdown-ellipses dropdown-toggle"
            role="button"
            data-test-id={`simcards-table:action-dropdown:toggle-${row.id}`}
          >
            <div className="ellipsis">
              <i className="fe fe-more-vertical" />
            </div>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>STATE</Dropdown.Header>
        <Dropdown.Item
          className="py-1"
          eventKey="1"
          onClick={() => {
            setUpdatingRowIccid(row.original.iccid);
            mutateSimStatus({ simcards: [row.original], desiredState: SIM_STATE.ENABLED });
            setUpdatingRowIccid("");
          }}
          data-test-id="simcards-table:action-dropdown:enable-simcard-dropdown-item"
        >
          <i className="fe fe-play me-1" />
          <span>Enable</span>
        </Dropdown.Item>
        <Dropdown.Item
          className="py-1"
          eventKey="2"
          onClick={() => {
            setUpdatingRowIccid(row.original.iccid);
            mutateSimStatus({ simcards: [row.original], desiredState: SIM_STATE.DISABLED });
            setUpdatingRowIccid("");
          }}
          data-test-id="simcards-table:action-dropdown:disable-simcard-dropdown-item"
        >
          <i className="fe fe-pause me-1" />
          <span>Disable</span>
        </Dropdown.Item>
        <Dropdown.Divider className="mx-3" />
        <Dropdown.Header>SMS</Dropdown.Header>
        <Dropdown.Item
          className="py-1"
          eventKey="3"
          onClick={() => smsModalRef.current.open({ iccids: [row.original.iccid] })}
          data-test-id="simcards-table:action-dropdown:open-sms-modal-dropdown-item"
        >
          <i className="fe fe-send me-1" />
          <span>Send</span>
        </Dropdown.Item>
        <Dropdown.Divider className="mx-3" />
        <Dropdown.Header>NETWORK</Dropdown.Header>
        <Dropdown.Item
          className="py-1"
          eventKey="3"
          onClick={() => assignToNetworkModalRef.current.open({ iccids: [row.original.iccid] })}
          data-test-id="simcards-table:action-dropdown:open-assign-to-network-modal-dropdown-item"
        >
          <i className="fe fe-upload-cloud me-1" />
          <span>Assign</span>
        </Dropdown.Item>
        <Dropdown.Divider className="mx-3" />
        <Dropdown.Item
          className="py-1"
          eventKey="3"
          onClick={() => deleteSimModalRef.current.open()}
          data-test-id="simcards-table:action-dropdown:delete-simcard-dropdown-item"
        >
          <span className="text-danger">Delete SIM card</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
