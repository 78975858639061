import { Button, Modal, ModalBody } from "react-bootstrap";

const SetupIntentSuccess = ({ isOpen, handleClose }) => {
  return (
    <Modal centered size="sm" show={isOpen} onHide={handleClose}>
      <ModalBody>
        <div className="d-flex justify-content-center flex-column">
          <h3 className="mb-4 d-flex justify-content-center">Payment method saved!</h3>
          <Button onClick={handleClose}>Close</Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SetupIntentSuccess;
