import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type SubscriptionsIntroProps = {
  setStep: () => void;
};

export const SubscriptionsIntro: FC<SubscriptionsIntroProps> = ({ setStep }) => {
  const { t } = useTranslation();

  return (
    <div className="row" style={{ height: "450px" }}>
      <div className="card w-100">
        <div className="card-body ">
          <div className="row h-100">
            <div className="col-12 col-md-8 d-flex flex-column">
              <p className="display-4 ">{t("subscriptions.businessPlan.intro.title")}</p>
              <p className="fs-2 py-4">{t("subscriptions.businessPlan.intro.body")}</p>
              <div>
                <button type="button" className="btn btn-primary btn-lg fs-3" onClick={setStep}>
                  {t("button.learnMore")}
                </button>
              </div>
              <div className="mt-auto">
                <p>
                  {t("subscriptions.businessPlan.intro.knowMore")}
                  <Link target="_blank" to="https://www.simbase.com/plans/business">
                    {t("subscriptions.link.visitWebsite")}
                  </Link>
                  .
                </p>
                <p>
                  {t("subscriptions.businessPlan.intro.needHelp")}
                  <Link target="_blank" to="https://www.simbase.com/talk-to-sales">
                    {t("subscriptions.link.talkToSales")}
                  </Link>
                  .
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <img
                src="https://simbase-static-content.s3.eu-north-1.amazonaws.com/images/business+plan-illustration-complete-artwork.png"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
