import { ErrorPage } from '@components/ErrorPage';
import { SignIn } from '@modules/auth/signIn';
import { ConfirmAccount, SignUp } from '@modules/auth/signUp';
import { Dashboard } from '@modules/dashboard';
import * as Sentry from '@sentry/react';
import { Amplify } from 'aws-amplify';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import CustomerIOTracker from 'src/lib/utils/CustomerIo/CustomerIoTracker';
import Version from './components/Version';
import { useCustomerIo } from './lib/utils/hooks/useCustomerIo';
import SentryUserIdentifier from './lib/utils/Sentry/SentryUserIdentifier';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_COGNITO_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_AUDIENCE_ID,
    },
  },
});

Sentry.init({
  environment: import.meta.env.VITE_STAGE,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  autoSessionTracking: true,
  ignoreErrors: [
    'The user is not authenticated',
    'User needs to be authenticated to call this API.',
    'UserUnAuthenticatedException',
  ],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [
        window.location.origin,
        /^https:\/\/cognito-idp\.eu-north-1\.amazonaws\.com\/.*/,
        /^https:\/\/api\.dashboard\.simbase\.com\/.*/,
        'http://localhost:5173/*',
      ],
      networkCaptureBodies: true,
    }),
  ],
  tracePropagationTargets: [
    /^https:\/\/api-dev\.simbase\.co\/.*/,
    /^https:\/\/api\.dashboard\.simbase\.com\/.*/,
    /^https:\/\/api\.simbase\.com\/.*/,
    /^https:\/\/dev\.simbase\.com\/.*/,
    /^http:\/\/93uco78518\.execute-api\.eu-north-1\.amazonaws\.com\/prod\/.*/,
    /^http:\/\/localhost:5173\/.*/,
    'https://dev.d1hiq54e6ie404.amplifyapp.com/',
    'https://main.d1hiq54e6ie404.amplifyapp.com/',
  ],
  // Performance Monitoring
  tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  normalizeDepth: 10, // Increasing the depth to make the application state visible in sentry issues
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const Layout = () => {
  useCustomerIo(); // booting and page tracking
  return (
    <>
      <Outlet />
      <CustomerIOTracker />
      <SentryUserIdentifier />
      <Version />
    </>
  );
};

const router = sentryCreateBrowserRouter([
  {
    element: (
      <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
        <Layout />
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: '/auth/*',
        children: [
          {
            path: 'signin',
            element: (
              <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
                <SignIn />
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: 'signup',
            element: (
              <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
                <SignUp />
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: 'signup/confirm',
            element: (
              <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
                <ConfirmAccount />
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: '*',
            element: (
              <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
                <Navigate replace to="/auth/signin" />
              </Sentry.ErrorBoundary>
            ),
          },
        ],
      },
      {
        path: '/dashboard/*',
        element: (
          <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
            <Dashboard />
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};
export default App;
