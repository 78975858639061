import { useTranslation } from 'react-i18next';
import OverviewHeader from '../../../components/OverviewHeader';
import { SimcardsTableV2 } from './table';

type SimcardsViewProps = {
  sidebarOpen: boolean;
};
const SimcardsView = ({ sidebarOpen }: SimcardsViewProps) => {
  const { t } = useTranslation();
  return (
    <div className="tw-h-full tw-flex-col">
      <div className="tw-px-6">
        <OverviewHeader title={t('header.simCards')} sidebarOpen={sidebarOpen} />

        <SimcardsTableV2 />
      </div>
    </div>
  );
};

export default SimcardsView;
