import { Icon } from '@components/Atoms/Icon/Icon';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ApiBaseModal } from '../ApiBaseModal/ApiBaseModal';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  apiKey: string;
}

export const ApiSuccessModal = ({ open, onClose, apiKey }: BaseModalProps) => {
  const { t } = useTranslation();

  const copyToClipboard = useCallback(() => {
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        toast.success(t('integrations.modal.apiKeyCopied'), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        toast.error(t('integrations.modal.apiKeyCopyFailed'));
      });
  }, [apiKey, t]);

  return (
    <ApiBaseModal open={open} onClose={onClose} title={t('integrations.modal.yourApiKey')} clickOutsideToClose={false}>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-p-6">
        <span className="tw-text-base tw-font-semibold">{t('integrations.modal.keepYourKeySafe')}</span>
        <span className="tw-text-base">{t('integrations.modal.keepYourKeySafe.body')}</span>
        <div
          className="tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 tw-p-4 tw-transition-all hover:tw-bg-sb-blue-grey-200"
          onClick={copyToClipboard}
        >
          <span className="font-monospace tw-break-all">{apiKey}</span>
          <Icon name="copy" width={20} height={20} className="" />
        </div>
        <span className="tw-text-sm tw-text-sb-blue-grey-600">{t('integrations.modal.clickToCopy')}</span>
      </div>
    </ApiBaseModal>
  );
};
