import SmallLogo from '@assets/img/icons/logo-notext.svg';
import Logo from '@assets/img/icons/logo.svg';

type SidebarLogo = {
  open: boolean;
};

export const SidebarLogo = ({ open }: SidebarLogo) => {
  return <img src={open ? Logo : SmallLogo} alt="Logo" />;
};
