import batchSimCard from "@assets/img/batchsim.png";
import simCard from "@assets/img/simCard.png";
import { OptionToggle } from "@components/Molecules/OptionToggle/OptionToggle";
import { ACTIVATE_SIMS_MAX_SIMS } from "@core/constants";
import { useState } from "react";
import { Alert, Button, Card, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { TagBadges } from "../../components";
import { useLazyVerifyBatchSnQuery, useLazyVerifySnQuery } from "../activateSims-api-slice";
import { SimInputRow } from "../index";
import ActivateSimsTagDropdown from "./TagDropdown";

const AddSims = ({
  sims,
  setSims,
  tags,
  setTags,
  handleAddSims,
  handleCancel,
  isIndividual,
  imeiLock,
  setImeiLock,
}) => {
  const [iccid, setIccid] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  // VerifySn
  const [verifySn] = useLazyVerifySnQuery();
  const [verifyBatchSn] = useLazyVerifyBatchSnQuery();
  const handleSubmitRow = async (e) => {
    if ((e.key === "Enter" || e.type === "click") && iccid.length && serialNumber.length) {
      setErrorMessage("");
      setProcessing(true);

      let response;
      if (isIndividual) {
        response = await verifySn({ iccid, serialNumber });
      } else {
        response = await verifyBatchSn({ batchId: iccid, serialNumber });
      }

      if (response.error?.data?.errorMessage) {
        setErrorMessage(response.error.data.errorMessage);
      } else {
        setSims([...sims, { iccid, serialNumber }]);
        setIccid("");
        setSerialNumber("");
      }

      setProcessing(false);
    }
  };

  const handleRemoveSim = (index) => {
    setSims(sims.filter((_, i) => i !== index));
  };

  const handleInputChange = (key, value) => {
    if (key === "iccid") {
      if (/^[0-9]*$/.test(value) && value.length <= 19) {
        setIccid(value);
      }
    } else if (key === "batch") {
      if (/^[A-z][0-9]{0,10}$/.test(value) || value.length === 0) {
        setIccid(value.toUpperCase());
      }
    } else if (key === "serialNumber") {
      if (/^[-a-zA-Z]*$/.test(value) && (value.match(/-/g) || []).length <= 1 && value.length <= 12) {
        if (serialNumber.length === 5 && value.length === 6 && !value.includes("-")) {
          setSerialNumber(`${value}-`);
        } else {
          setSerialNumber(value);
        }
      }
    }
  };

  const handleAddClick = () => {
    handleAddSims(sims);
  };
  return (
    <Card>
      <Card.Body className="p-3">
        <div className="d-flex w-100">
          <div className="w-40 d-flex justify-content-center">
            <img alt="card" src={isIndividual ? simCard : batchSimCard} className="w-75" />
          </div>
          <div className="w-60 mx-4 d-flex flex-column">
            <div className="text-muted fw-bold pb-2 pt-2">Add SIMs</div>
            {sims.map(({ iccid, serialNumber }, index) => (
              <SimInputRow
                key={index}
                index={index}
                label={isIndividual ? "ICCID" : "BATCH ID"}
                iccid={iccid}
                serialNumber={serialNumber}
                handleDelete={() => handleRemoveSim(index)}
              />
            ))}
            {sims.length < ACTIVATE_SIMS_MAX_SIMS && (
              <div className="d-flex">
                {processing ? (
                  <div className="d-flex justify-content-center w-100">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
                    <FloatingLabel
                      controlId="iccid"
                      label={isIndividual ? "ICCID" : "BATCH ID"}
                      className="me-3 w-65 text-nowrap"
                    >
                      <Form.Control
                        type="text"
                        placeholder={isIndividual ? "ICCID" : "BATCH ID"}
                        value={iccid}
                        onChange={(e) => handleInputChange(isIndividual ? "iccid" : "batch", e.target.value)}
                        onKeyDown={handleSubmitRow}
                      />
                    </FloatingLabel>
                    <FloatingLabel controlId="serialNumber" label="SERIAL NUMBER" className="text-nowrap">
                      <Form.Control
                        type="text"
                        placeholder="SERIAL NUMBER"
                        value={serialNumber}
                        onChange={(e) => handleInputChange("serialNumber", e.target.value)}
                        onKeyDown={handleSubmitRow}
                      />
                    </FloatingLabel>
                    <div className="d-flex justify-content-center align-items-center px-1 me-n5">
                      <Button className="d-flex text-nowrap mx-2" onClick={handleSubmitRow}>
                        {isIndividual ? "Add SIM" : "Add SIMs"}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            <div>
              {!processing && errorMessage && (
                <div className="my-3">
                  <Alert variant="danger">{errorMessage}</Alert>
                </div>
              )}
            </div>
            <hr className="mt-4 mb-3" />
            <div className="text-muted fw-bold">Assign Tags</div>
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <Card className="mb-0 h-100">
                  <Card.Body>
                    <Row className="align-items-center gx-0">
                      <Col>
                        <h6 className="text-uppercase text-muted mb-2 text-uppercase">tags</h6>
                        <TagBadges tags={tags} disableMore />
                      </Col>
                      <Col className="col-auto">
                        <ActivateSimsTagDropdown tags={tags} setTags={setTags} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
              <hr className="mt-4 mb-4" />
              <OptionToggle
                title="Theft Protection (IMEI)"
                body="Automatically disable the SIM when used in different device."
                value={imeiLock}
                handleOnClick={() => setImeiLock((prevImeiLock) => !prevImeiLock)}
                bootstrapIcon="fe fe-lock"
              />
              <div className="d-flex justify-content-between mt-4 mb-3">
                <Button className="me-2" variant="light" onClick={handleCancel}>
                  Previous step
                </Button>
                <Button variant="light-primary" type="submit" disabled={sims.length === 0} onClick={handleAddClick}>
                  Next step
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AddSims;
