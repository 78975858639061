import { Button } from '@components/Atoms/Button/Button';
import { Modal } from '@components/Atoms/Modal/Modal';
import { ConfirmEmailUpdateModal } from '@components/ConfirmEmailUpdateModal';
import { HeaderNavigation } from '@components/Molecules/HeaderNavigation/HeaderNavigation';
import { Sidebar } from '@components/Organisms/Sidebar/Sidebar';
import Home from '@components/Pages/Home';
import { useSignout } from '@modules/auth/signout';
import { getAuthenticatedUser } from '@modules/auth/utils';
import useIsProcessing from '@utils/hooks/useIsProcessing';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useEmailVerified } from 'src/hooks/useConfirmEmail';
import { VIEW_PERMISSIONS } from '../../core/constants'; //PERMISSIONS,
import { checkPermission } from '../../lib/utils/permissions';
import { Account } from './account';
import { useGetCustomerQuery, useGetSelfQuery } from './account/account-api-slice';
import { RegisterSims } from './activateSims';
import { Billing } from './billing';
import { useGetBillingBalanceQuery, useGetCountriesQuery } from './billing/billing-api-slice';
import { useGetSummaryQuery } from './home/status-api-slice';
import { Integrations } from './integrations';
import { ConfigureIPSec, CreateNetwork, Network, NetworkDetails } from './network';
import { Details } from './simCard';
import SimcardsView from './simCard/components/ListView/view';

function RequirePermissions({ children, permissions }) {
  let isAllowed = false;
  permissions.forEach((permission) => {
    if (checkPermission(permission)) {
      isAllowed = true;
    }
  });
  return isAllowed ? children : <Navigate replace to="/dashboard/home" />;
}

const AUTHENTICATION_INTERVAL = 5 * 60 * 1000; // 5 minutes
const AUTHENTICATION_CACHE_DURATION = 4.5 * 60 * 1000; // 4.5 minutes

const Router = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const [accessToken, setAccessToken] = useState(null);
  const [signoutModalOpen, setSignoutModalOpen] = useState(false);
  const [lastAuthCheck, setLastAuthCheck] = useState(0);
  const [billingDetails, setBillingDetails] = useState({
    companyName: '',
    purchaseOrder: '',
    country: '',
    attentionTo: '',
    primaryAddress: '',
    secondaryAddress: '',
    zipCode: '',
    state: '',
    city: '',
    vat: '',
    email_invoice: '',
  });
  const [billingDetailsAreReady, setBillingDetailsAreReady] = useState(false);

  const updateBillingDetails = useCallback((newDetails) => {
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      ...newDetails,
    }));
  }, []);

  async function handleForceSignout() {
    await forceSignout();
  }

  const checkAuthentication = useCallback(async () => {
    const now = Date.now();
    if (now - lastAuthCheck < AUTHENTICATION_CACHE_DURATION) {
      return; // Use cached result
    }

    try {
      const { accessToken } = await getAuthenticatedUser();
      if (!accessToken) {
        setSignoutModalOpen(true);
      } else {
        setAccessToken(accessToken);
      }
      setLastAuthCheck(now);
    } catch (err) {
      setSignoutModalOpen(true);
    }
  }, [lastAuthCheck]);

  const debouncedCheckAuthentication = useMemo(() => debounce(checkAuthentication, 300), [checkAuthentication]);

  useEffect(() => {
    debouncedCheckAuthentication();

    const intervalId = setInterval(debouncedCheckAuthentication, AUTHENTICATION_INTERVAL);

    return () => {
      clearInterval(intervalId);
      debouncedCheckAuthentication.cancel();
    };
  }, [debouncedCheckAuthentication, pathname]);

  const { forceSignout } = useSignout();
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 1073);
  const [username, setUsername] = useState(sessionStorage.getItem('username') || '');
  const [usernameIsLoading, setUsernameIsLoading] = useState(true);

  // GetBillingBalance
  const getBillingBalanceResponse = useGetBillingBalanceQuery();
  const balance = getBillingBalanceResponse?.data;

  // GetCountries
  const getCountriesResponse = useGetCountriesQuery();
  const { data: countriesData } = getCountriesResponse;

  // GetCustomer
  const customerResponse = useGetCustomerQuery();
  const { data: customerResponseDetails, isFetching: customerResponseIsFetching } = customerResponse;

  const topUpIsProcessing = useIsProcessing([
    getCountriesResponse.isLoading,
    getCountriesResponse.isFetching,
    getBillingBalanceResponse.isLoading,
    getBillingBalanceResponse.isFetching,
    !billingDetailsAreReady,
  ]);

  useEffect(() => {
    if (customerResponseDetails?.customerDetails) {
      const { company_name, address, invoice, tax, email } = customerResponseDetails.customerDetails;
      const { zip, state, city, country, street1, street2 } = address || {};
      const { invoice_ref, invoice_attendee } = invoice || {};
      const { tax_number } = tax || {};
      const { email_invoice } = email || {};

      setBillingDetails({
        companyName: company_name || '',
        purchaseOrder: invoice_ref || '',
        country: country || '',
        attentionTo: invoice_attendee || '',
        primaryAddress: street1 || '',
        secondaryAddress: street2 || '',
        zipCode: zip || '',
        state: state || '',
        city: city || '',
        vat: tax_number || '',
        email_invoice: email_invoice || '',
      });
      setBillingDetailsAreReady(true);
    }
  }, [customerResponseDetails]);

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
    setUsernameIsLoading(false);
  }, []);

  const { data: userData } = useGetSelfQuery(undefined, {
    skip: !accessToken,
  });

  useEffect(() => {
    if (userData?.name) {
      setUsername(userData.name);
      sessionStorage.setItem('username', userData.name);
    }
  }, [userData?.name]);

  const getSummaryResponse = useGetSummaryQuery();
  const statusSummary = getSummaryResponse?.data;

  const { isEmailVerified } = useEmailVerified();

  return (
    <div className="tw-h-screen tw-overflow-hidden">
      <Sidebar
        user={username}
        status={statusSummary?.status}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        url={statusSummary?.page?.url}
        usernameIsLoading={usernameIsLoading}
      />
      <div
        className={`tw-h-screen tw-overflow-y-auto tw-transition-all tw-duration-100 tw-ease-in-out ${
          sidebarOpen ? 'tw-pl-60' : 'tw-pl-[63px]'
        }`}
      >
        <div className="tw-min-h-full">
          {!isEmailVerified && <ConfirmEmailUpdateModal />}
          <Routes>
            <Route
              path="/sim-cards/:iccid"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.SIMCARDS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Details sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/sim-cards"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.SIMCARDS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <SimcardsView sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/profile"
              element={
                <>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Account sidebarOpen={sidebarOpen} />
                </>
              }
            />
            <Route
              path="/billing"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.BILLING]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Billing
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    customerResponseDetails={customerResponseDetails}
                    customerResponseIsFetching={customerResponseIsFetching}
                    billingDetailsAreReady={billingDetailsAreReady}
                    updateBillingDetails={updateBillingDetails}
                    billingDetails={billingDetails}
                  />
                </RequirePermissions>
              }
            />
            <Route path="/activate-sims" element={<RegisterSims />} />
            <Route
              path="/home"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.HOME]}>
                  <Home
                    user={username.split(' ')[0]}
                    balance={balance}
                    billingBalanceResponseIsLoading={getBillingBalanceResponse.isLoading}
                  />
                </RequirePermissions>
              }
            />
            <Route
              path="/integrations"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.INTEGRATIONS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Integrations sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network/create"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <CreateNetwork sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <Network sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network/:id/"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <NetworkDetails sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route
              path="/private-network/:id/ipsec-configure"
              element={
                <RequirePermissions permissions={[VIEW_PERMISSIONS.PRIVATE_NETWORKS]}>
                  <HeaderNavigation
                    balance={balance}
                    sidebarOpen={sidebarOpen}
                    countriesData={countriesData}
                    tax={customerResponseDetails?.customerDetails?.tax?.tax}
                    billingDetails={billingDetails}
                    updateBillingDetails={updateBillingDetails}
                    topUpIsProcessing={topUpIsProcessing}
                  />
                  <ConfigureIPSec sidebarOpen={sidebarOpen} />
                </RequirePermissions>
              }
            />
            <Route path="*" element={<Navigate replace to="/dashboard/home" />} />
          </Routes>
        </div>
      </div>
      <ToastContainer autoClose={4000} />
      {signoutModalOpen && (
        <Modal
          showClose={true}
          title={t('route.signInAgain')}
          modalOpen={signoutModalOpen}
          closeModal={handleForceSignout}
        >
          <div className="tw-flex tw-flex-col">
            <p>{t('route.signInAgain.body')}</p>
            <Button variant="fourth" onClick={handleForceSignout}>
              {t('button.reload')}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Router;
