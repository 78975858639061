// https://stackoverflow.com/questions/70098392/react-chartjs-2-with-chartjs-3-error-arc-is-not-a-registered-element
import { LoadingWrapper } from '@components/LoadingWrapper';
import { convertTimeFields } from '@utils/timezoneConversion';
import { BarElement, CategoryScale, Chart, LinearScale } from 'chart.js';
import moment from 'moment-timezone';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card, Nav, NavItem, NavLink, Spinner } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DataUsageResponse, MappedUsageGraphData, Self, Simcard } from 'src/types/types';
import SimpleTable from '../../../../../../components/Organisms/SimpleTable/SimpleTable';
import { SimpleInfoCard } from '../../../index';

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);

type UsageProps = {
  simcard: Simcard;
  userData: Self;
  usageGraphData: MappedUsageGraphData;
  usageGraphIsLoading: boolean;
  graphType: string;
  setGraphType: Dispatch<SetStateAction<string>>;
  dataUsageData: DataUsageResponse;
  dataUsageIsLoading: boolean;
  dataUsageIsFetching: boolean;
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  hover: {
    intersect: true,
  },
  plugins: {
    filler: {
      propagate: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      intersect: true,
      callbacks: {
        label: (context) => {
          const { raw } = context || {};
          return raw + ' MB';
        },
        labelColor: (context) => {
          const { backgroundColor, borderColor } = context.dataset || {};
          return {
            borderColor,
            backgroundColor,
          };
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      reverse: true,
    },
    y: {
      title: {
        display: true,
        text: 'MB',
      },
      grid: {
        display: false,
      },
    },
  },
};

const Usage = ({
  simcard,
  userData,
  usageGraphData,
  usageGraphIsLoading,
  setGraphType,
  graphType,
  dataUsageData,
  dataUsageIsLoading,
  dataUsageIsFetching,
}: UsageProps) => {
  const { t } = useTranslation();

  const [dataUsage, setDataUsage] = useState([]);

  const defaultTimezone = moment.tz.guess();
  const [preferredTime, setPreferredTime] = useState(defaultTimezone);

  useEffect(() => {
    if (userData?.preferred_timezone) {
      setPreferredTime(userData.preferred_timezone);
    }
  }, [userData]);

  const columns = [
    {
      Header: 'START DATE',
      accessor: 'start',
      extraInfo: t('timezone.extraInfo', { preferredTime: preferredTime }),
    },
    {
      Header: 'STOP DATE',
      accessor: 'stop',
      extraInfo: t('timezone.extraInfo', { preferredTime: preferredTime }),
    },
    {
      Header: 'DATA USAGE',
      accessor: 'usage',
    },
    {
      Header: 'MOBILE NETWORK OPERATOR',
      accessor: 'carrier',
    },
    {
      Header: 'RADIO',
      accessor: 'radio',
    },
    {
      Header: 'LOCATION',
      accessor: 'country',
    },
  ];

  useEffect(() => {
    if (dataUsageData?.dataUsage) {
      setDataUsage(convertTimeFields(dataUsageData.dataUsage, preferredTime, ['start', 'stop']));
    }
  }, [dataUsageData?.dataUsage, preferredTime]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex py-3">
        <div className="w-70">
          <Card className="mb-0">
            <Card.Header>
              <h4 className="card-header-title text-capitalize">Data used</h4>
              <Nav variant="tabs" activeKey={graphType} onSelect={(tab) => setGraphType(tab)}>
                <NavItem>
                  <NavLink eventKey="daily">Daily</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="monthly">Monthly</NavLink>
                </NavItem>
              </Nav>
            </Card.Header>
            <Card.Body>
              <div className="chart">
                {usageGraphIsLoading ? (
                  <div className="d-flex justify-content-center h-100 align-items-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <Bar data={usageGraphData} options={options} />
                )}
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex flex-column justify-content-between ps-3 w-40 vstack gap-3">
          <div className="w-100">
            <SimpleInfoCard label="usage this month" title={simcard?.usage_data} icon="bar-chart-2" />
          </div>
          <div className="w-100">
            <SimpleInfoCard label="data sessions this month" title={simcard?.sessions} icon="activity" />
          </div>
          <div className="w-100">
            <SimpleInfoCard label="zero sessions this month" title={simcard?.zero_sessions} icon="alert-circle" />
          </div>
          <div className="w-100">
            <SimpleInfoCard label="last data sessions" title={simcard?.last_update} icon="clock" />
          </div>
        </div>
      </div>
      <div>
        <LoadingWrapper isLoading={dataUsageIsLoading}>
          <div className="tw-mb-5 tw-flex tw-flex-col tw-gap-10">
            <SimpleTable
              title="Data records (CDRs)"
              data={dataUsage}
              processing={dataUsageIsFetching}
              noDataText="No data usage yet"
              columns={columns}
            />
          </div>
        </LoadingWrapper>
      </div>
    </div>
  );
};
export default Usage;
