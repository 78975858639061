import rocket from "@assets/img/rocket.png";
import sim1 from "@assets/img/sim1.png";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { setItem } from "src/lib/utils/localStorage";

const NoSimsModal = forwardRef((_, ref) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        setIsOpen(true);
        setItem("simModalOpen", "opened");
      },
    };
  });

  return (
    <Modal size="lg" centered show={isOpen} onHide={() => setIsOpen(false)}>
      <Modal.Body>
        <div className="d-flex flex-column p-2">
          <div className="d-flex">
            <div className="d-flex flex-column justify-content-center pe-4">
              <div className="d-flex justify-content-center py-2">
                <img width={120} height={120} className="img-fluid" src={sim1} alt="sim" />
              </div>
              <div className="d-flex justify-content-center py-2">
                <h4 className="fw-700">Got SIMs yet?</h4>
              </div>
              <div className="d-flex justify-content-center pb-2">
                <h5 className="text-center">
                  Order SIMs to get started. SIMs come in all sizes to fit your cellular connected devices.
                </h5>
              </div>
              <div className="d-flex justify-content-center">
                <Button href="https://store.simbase.com" target="_blank" variant="primary">
                  Order SIMs
                </Button>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex justify-content-center py-2">
                <img width={120} height={120} className="img-fluid" src={rocket} alt="sim" />
              </div>
              <div className="d-flex justify-content-center py-2">
                <h4 className="fw-700">Activate SIMs</h4>
              </div>
              <div className="d-flex justify-content-center pb-2">
                <h5 className="text-center">
                  Register your SIM to begin using it. Add the individually or a pack at once. 
                </h5>
              </div>
              <div className="d-flex justify-content-center">
                <Button variant="primary" onClick={() => navigate("../activate-sims")}>
                  Register SIMs
                </Button>
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-center cursor-pointer text-decoration-underline py-3 fs-6"
            onClick={() => setIsOpen(false)}
          >
            Or, just have a look around
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});

export default NoSimsModal;
