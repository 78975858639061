import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

const LOCIZE_API_KEY = import.meta.env.VITE_LOCIZE_API_KEY;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: "en",
    saveMissing: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      projectId: "701f2cfb-ae6d-4b22-b98d-27f940fb373d",
      apiKey: LOCIZE_API_KEY,
    },
  });

export default i18n;
