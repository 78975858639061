import { SIM_STATE } from "@core/constants";
import { useAppSelector } from "@core/rtk-hooks";
import { useAnimate } from "framer-motion";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useMutateSimStatus } from "../../hooks/useMutateSimStatus";
import SimcardsTagDropdown from "../TagDropdown";

const ActionBar = ({
  table,
  deleteSimModalRef,
  smsModalRef,
  assignToNetworkModalRef,
}: {
  table: any;
  deleteSimModalRef: any;
  smsModalRef: any;
  assignToNetworkModalRef: any;
}) => {
  const [scope, animate] = useAnimate();
  const { mutateSimStatus, updateSimStatusResponse } = useMutateSimStatus();

  const iccids = table?.getSelectedRowModel().rows.map((row) => row.original.iccid);

  const { simcards } = useAppSelector((state) => state.simcardsState);
  const selectedSimcards = simcards?.filter((sim) => iccids.includes(sim.iccid));

  useEffect(() => {
    (async () => {
      if (iccids.length === 0) {
        await animate(scope.current, { height: 0, opacity: 0 }, { duration: 0.3 });
        await animate(scope.current, { zIndex: -2 });
      } else {
        await animate(scope.current, { height: "auto", opacity: 1, zIndex: 3 }, { duration: 0.3 });
      }
    })();
  }, [iccids.length]);

  return (
    <div ref={scope} id="action-bar" className="sticky-top" style={{ height: 0, opacity: 0 }}>
      <div className="w-100 action-menu-bar hstack gap-3 align-items-center text-white fs-5 p-3 py-2 ">
        <button
          disabled={updateSimStatusResponse.isLoading}
          className="cursor-pointer d-flex ms-auto text-white border-0 bg-transparent"
          data-test-id="action-bar:enable-sims-button"
          onClick={() =>
            mutateSimStatus({
              simcards: selectedSimcards,
              desiredState: SIM_STATE.ENABLED,
            })
          }
        >
          <i className="fe fe-play pe-2" /> Enable
        </button>
        <button
          disabled={updateSimStatusResponse.isLoading}
          className="cursor-pointer d-flex text-white border-0 bg-transparent"
          data-test-id="action-bar:disable-sims-button"
          onClick={() =>
            mutateSimStatus({
              simcards: selectedSimcards,
              desiredState: SIM_STATE.DISABLED,
            })
          }
        >
          <i className="fe fe-pause pe-2" /> Disable
        </button>
        <button
          disabled={updateSimStatusResponse.isLoading}
          className="d-flex align-items-center text-white  cursor-pointer border-0 bg-transparent"
          data-test-id="action-bar:send-sms-button"
          onClick={() => {
            smsModalRef.current.open({ iccids });
          }}
        >
          <i className="fe fe-send pe-2 icon" /> Send SMS
        </button>
        <button
          disabled={updateSimStatusResponse.isLoading}
          className="d-flex align-items-center text-white  cursor-pointer border-0 bg-transparent "
          data-test-id="action-bar:assign-to-network-button"
          onClick={() => {
            assignToNetworkModalRef.current.open({ iccids });
          }}
        >
          <i className="fe fe-upload-cloud pe-2 icon" /> Assign to Private Network
        </button>
        <SimcardsTagDropdown simcards={selectedSimcards} />
        <Button
          variant="danger"
          className="me-2 p-2 delete-button-color"
          data-test-id="action-bar:delete-sims-button"
          onClick={() => deleteSimModalRef.current.open()}
        >
          <div className="d-flex align-items-center">
            <i className="fe fe-trash-2 pe-2 icon" /> Delete SIM Cards
          </div>
        </Button>
      </div>
    </div>
  );
};

export default ActionBar;
