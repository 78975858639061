import { forwardRef } from "react";

export const CustomDropdownToggle = forwardRef<HTMLDivElement>(
  ({ children, onClick }: any, ref) => (
    <div
      role="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  )
);

export default CustomDropdownToggle;
