import { statusApi } from '@modules/dashboard/home/status-api-slice';
import { simcardsSlice } from '@modules/dashboard/simCard/simcards-slice';
import { autoBatchEnhancer, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import emailVeridiedSlice from './features/emailVerifiedSlice';
import { coreApi } from './rtk-api';
import { updateAllSims } from './rtk-middleware/updateAllSims';
import { updateActivatedSims, updateSims } from './rtk-middleware/updateSims';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  devTools: true,
  reducer: {
    [coreApi.reducerPath]: coreApi.reducer,
    [statusApi.reducerPath]: statusApi.reducer,
    simcardsState: simcardsSlice.reducer,
    emailVerifiedState: emailVeridiedSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(statusApi.middleware)
      .concat(coreApi.middleware)
      .concat(updateAllSims.middleware)
      .concat(updateSims.middleware)
      .concat(updateActivatedSims.middleware);
  },
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers()
      .concat(autoBatchEnhancer({ type: 'tick' }))
      .concat(sentryReduxEnhancer),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
