import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

import ErrorMessage from "@components/ErrorMessage";
import { Loader } from "@components/Loader";
import { ORIGIN } from "@core/constants";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

const CheckoutForm = ({ submitText, clientSecret, paymentMethod }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!paymentMethod?.newMethod) {
      setLoading(false);
    }
  }, [paymentMethod]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setPaymentProcessing(true);
    let response = null;
    if (paymentMethod.newMethod) {
      response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${ORIGIN}/dashboard/billing`,
        },
      });
    } else {
      response = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.value,
      });
      if (!response?.error) {
        const { id, status } = response?.paymentIntent ?? {};
        // Force replace to have consistent flows for new and existing payment method
        window.location.replace(`${ORIGIN}/dashboard/billing?payment_intent=${id}&redirect_status=${status}`);
      }
    }
    if (response?.error) {
      setErrorMessage(response?.error?.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    if (paymentMethod.newMethod || response?.error) {
      setPaymentProcessing(false);
    }
  };

  return (
    <div className="w-100">
      <Form>
        {paymentMethod?.newMethod && <PaymentElement onReady={() => setLoading(false)} />}
        {loading ? (
          <Loader className="mt-4" size={3} />
        ) : (
          <>
            <div className="d-flex justify-content-center mt-4">
              {paymentProcessing ? (
                <Loader className="mt-2" />
              ) : (
                <Button className="w-100" disabled={!stripe} onClick={handleSubmit}>
                  {submitText}
                </Button>
              )}
            </div>
            <ErrorMessage message={errorMessage} dismissible onClose={() => setErrorMessage("")} />
          </>
        )}
      </Form>
    </div>
  );
};

export default CheckoutForm;
