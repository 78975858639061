import { coreApi } from '@core/rtk-api';
import { Network, Pricing, PrivateNetwork, SimcardNetwork } from 'src/types/types';

export const networkApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getPricing: builder.query<Pricing, void>({
        query: () => ({ url: `/pricing` }),
      }),
      getAllNetworks: builder.query<SimcardNetwork[], void>({
        query: () => ({ url: `/network` }),
      }),
      getNetwork: builder.query<PrivateNetwork, { id: string }>({
        query: ({ id }) => ({ url: `/network/${id}` }),
      }),
      createNetwork: builder.mutation<Network, Network>({
        query: (data) => ({
          url: `/network`,
          method: 'POST',
          body: data,
        }),
      }),
      editNetwork: builder.mutation<any, { id: string; name: string }>({
        query: ({ id, name }) => ({
          url: `/network/${id}`,
          method: 'PATCH',
          body: { network_name: name },
        }),
      }),
      terminateNetwork: builder.mutation<any, { id: string }>({
        query: ({ id }) => ({
          url: `/network/${id}`,
          method: 'DELETE',
        }),
      }),
      getOpenVpn: builder.query<any, { id: string }>({
        query: ({ id }) => ({ url: `/network/${id}/server` }),
      }),
      getIpsec: builder.query<any, { id: string }>({
        query: ({ id }) => ({ url: `/network/${id}/ipsec` }),
      }),
      assignSimToNetwork: builder.mutation<any, { id: string; iccids: string[] }>({
        query: ({ id, iccids }) => ({
          url: `/network/${id}/simcards`,
          method: 'POST',
          body: { iccids },
        }),
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: 'Simcard' as const,
              id: iccid,
            })),
          ];
        },
      }),
      removeSimFromNetwork: builder.mutation<any, { iccids: string[] }>({
        query: ({ iccids }) => ({
          url: `/network/simcards`,
          method: 'DELETE',
          body: { iccids },
        }),
        invalidatesTags: (_, __, { iccids }): { type: 'Simcard'; id?: string }[] => {
          return [
            ...iccids.map((iccid) => ({
              type: 'Simcard' as const,
              id: iccid,
            })),
          ];
        },
      }),
      configureIpsec: builder.mutation<any, { id: string; data: any }>({
        query: ({ id, data }) => ({
          url: `/network/${id}/ipsec`,
          method: 'POST',
          body: data,
        }),
      }),
    };
  },
});

export const {
  useConfigureIpsecMutation,
  useRemoveSimFromNetworkMutation,
  useAssignSimToNetworkMutation,
  useGetIpsecQuery,
  useGetOpenVpnQuery,
  useTerminateNetworkMutation,
  useEditNetworkMutation,
  useCreateNetworkMutation,
  useGetPricingQuery,
  useGetAllNetworksQuery,
  useLazyGetAllNetworksQuery,
  useGetNetworkQuery,
  useLazyGetNetworkQuery,
} = networkApi;
