import { SelectBox } from '@components/Atoms/SelectBox';
import { ROLES } from '@core/constants';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { FormField } from '../../../auth';

interface IUser {
  name?: string;
  email?: string;
  role?: string;
}

interface UserFormProps {
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  user?: IUser;
}

const UserForm = ({ onSubmit, isEdit = false, user = {} }: UserFormProps) => {
  return (
    <Formik
      initialValues={
        !isEdit
          ? {
              name: '',
              email: '',
              role: '',
            }
          : {
              name: user?.name,
              email: user?.email,
              role: user?.role,
            }
      }
      validate={(values) => {
        interface Errors {
          name?: string;
          email?: string;
          role?: string;
        }
        const errors: Errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (!values.role) {
          errors.role = 'Required';
        }
        return errors;
      }}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FormField
            value={values.name}
            handleChange={handleChange}
            touched={touched.name}
            handleBlur={handleBlur}
            error={errors.name}
            placeholder="Name"
            name="name"
            label="Name"
          />

          {!isEdit && (
            <FormField
              value={values.email}
              handleChange={handleChange}
              touched={touched.email}
              handleBlur={handleBlur}
              error={errors.email}
              placeholder="name@address.com"
              name="email"
              type="email"
              label="Email Address"
            />
          )}
          <Form.Label>Role</Form.Label>
          <SelectBox
            defaultValue={user?.role ? { value: user?.role, label: user?.role } : undefined}
            onChange={(option) => setFieldValue('role', option.value)}
            options={ROLES.filter(({ role }) => role !== 'owner').map(({ role }) => ({ value: role, label: role }))}
          />
          <span className="text-danger">{errors.role && touched.role ? errors.role : ''}</span>
          <Button type="submit" variant="primary" className="w-100 mb-2 mt-4 lift" disabled={isSubmitting}>
            {isEdit ? 'Save' : 'Invite'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
