import { LoadingButtonContent } from "@components/LoadingButtonContent";
import moment from "moment-timezone";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Subscriptions, UpdateSubscriptionArgs } from "src/types/types";

type SubscriptionsActivateProps = {
  coveragePlan: string;
  currencySymbol: string;
  businessPlanDailyRate: string;
  updateSubscription: (args: UpdateSubscriptionArgs) => void;
  businessPlanDataRate: number;
  updateSubscriptionResponseIsLoading: boolean;
  subscription: Subscriptions | undefined;
  businessPlanDiscountRate: number;
};

export const SubscriptionsActivate: FC<SubscriptionsActivateProps> = ({
  coveragePlan,
  currencySymbol,
  businessPlanDailyRate,
  updateSubscription,
  businessPlanDataRate,
  updateSubscriptionResponseIsLoading,
  subscription,
  businessPlanDiscountRate,
}) => {
  const { t } = useTranslation();
  const daysUntilUnblocked = (dateString) => {
    const targetDate = moment(dateString, "YYYY-MM-DD");
    const today = moment();
    return targetDate.diff(today, "days");
  };
  return (
    <div className="row gy-4 gx-3" style={{ height: "450px" }}>
      <div className="col-12 col-md-8">
        <div className="card h-100">
          <div className="card-body">
            <div className="row">
              <div className="col-8">
                <h3>{t("subscriptions.businessPlan.activate.planType")}</h3>
                <p className="display-4">
                  {t("subscriptions.businessPlan.activate.businessPlan")}
                  {coveragePlan}
                </p>
              </div>
              <div className="col-4 rounded-2 py-2">
                <div className="tw-flex tw-flex-row tw-gap-3">
                  <div className="col display-2 d-flex align-items-center justify-content-end">
                    {currencySymbol}
                    {Number(businessPlanDailyRate).toFixed(2)}
                  </div>
                  <div className="col fs-5 d-flex align-items-center justify-content-start">
                    <div>
                      <div>{t("subscriptions.businessPlan.activate.perAccount")}</div>
                      <div>{t("subscriptions.businessPlan.activate.perDay")}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-muted text-center fs-5">
                    {t("subscriptions.businessPlan.activate.cancelAnytime")}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col fs-2">
                <p>{t("subscriptions.businessPlan.activate.starterPlus")}</p>
                <ul>
                  <li>
                    <strong>
                      <span style={{ color: "#56dba1" }}>{businessPlanDiscountRate}%</span>
                    </strong>{" "}
                    {t("subscriptions.businessPlan.activate.discount", { coveragePlan: coveragePlan })}
                  </li>
                  <li>{t("subscriptions.businessPlan.activate.prioritySupport")}</li>
                  <li>{t("subscriptions.businessPlan.activate.whiteLabel")}</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card-footer mt-auto d-flex align-items-center py-4 text-muted fs-3 justify-content-center">
            {t("subscriptions.businessPlan.activate.differentCoverageZone")}&nbsp;
            <Link target="_blank" to="https://www.simbase.com/talk-to-sales">
              {t("subscriptions.businessPlan.activate.link.talkToSales")}
            </Link>
            .
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateSubscription({ subscription: "business_plan" });
          }}
          className="h-100"
        >
          <div className="card h-100">
            <div className="card-body d-flex flex-column">
              <h3>{t("subscriptions.businessPlan.activate.summary")}</h3>
              <table className="table table-hover rounded-2">
                <tbody>
                  <tr>
                    <th scope="row" className="border-0">
                      {t("subscriptions.businessPlan.activate.businessPlanHeading")}
                    </th>
                    <td className="border-0">{coveragePlan}</td>
                  </tr>
                  <tr>
                    <th scope="row" className="border-0">
                      {t("subscriptions.businessPlan.activate.dataRate")}
                    </th>
                    <td className="border-0">
                      <span style={{ color: "#56dba1" }}>
                        <strong>
                          {currencySymbol}
                          {businessPlanDataRate}/Mb
                        </strong>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" className="border-0">
                      {t("subscriptions.businessPlan.activate.billedDaily")}
                    </th>
                    <td className="border-0">
                      {currencySymbol}
                      {Number(businessPlanDailyRate).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="form-check mt-auto">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="terms_and_conditions"
                  required
                  disabled={!!subscription?.upgrade_blocked}
                />
                <label className="form-check-label" htmlFor="terms_and_conditions">
                  {t("subscriptions.businessPlan.activate.confirmYouAgree")}
                  <Link target="_blank" to="https://www.simbase.com/terms/subscriber-terms">
                    {t("subscriptions.businessPlan.activate.link.subscriberTerms")}
                  </Link>
                  .
                </label>
              </div>
            </div>
            <div className="card-footer border-top-0 tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3">
              <div className="d-grid w-100">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={updateSubscriptionResponseIsLoading || !!subscription?.upgrade_blocked}
                >
                  <LoadingButtonContent
                    isLoading={updateSubscriptionResponseIsLoading}
                    buttonText="Confirm Plan Activation"
                    variant="light"
                  />
                </button>
              </div>
              {!!subscription?.upgrade_blocked && (
                <p className="tw-text-center tw-text-red-500">
                  {t("subscriptions.activate.numberOfBlockedDays", {
                    daysUntilUnblocked: daysUntilUnblocked(subscription?.upgrade_blocked),
                  })}
                </p>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
