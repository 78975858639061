import { Formik } from 'formik';
import { Button, Form, FormControl, Modal } from 'react-bootstrap';
import { IError, Organization } from 'src/types/types';

interface IEditOrganization {
  isOpen: boolean;
  handleClose: () => void;
  handleEdit: (values: any) => void;
  organization?: Organization;
}

const EditOrganization = ({ isOpen, handleClose, handleEdit, organization }: IEditOrganization) => {
  return (
    <Modal dialogClassName="w-30" centered show={isOpen} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <h3 className="header-title">Edit organization name</h3>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            name: organization?.company_name,
          }}
          validate={(values) => {
            const errors: IError = {};
            if (!values.name) {
              errors.name = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => {
            handleEdit(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormControl
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                name="name"
                // label="Name"
                value={values.name}
              />
              <span className="text-danger">{errors.name && touched ? errors.name : ''}</span>
              <Button type="submit" variant="primary" className="w-100 mb-2 mt-4 lift" disabled={isSubmitting}>
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EditOrganization;
