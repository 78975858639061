import { Input } from '@headlessui/react';

type TransparentInputProps = {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: string;
  darkIcon?: boolean;
  className?: string;
};

const TransparentInput = ({
  placeholder,
  value,
  onChange,
  icon,
  darkIcon = false,
  className = '',
}: TransparentInputProps) => {
  return (
    <div className="tw-relative tw-w-full">
      <Input
        type="text"
        className={`tw-w-full tw-rounded tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-3 tw-py-2 tw-text-sm tw-text-gray-600 tw-placeholder-gray-400 tw-shadow-none tw-outline-none ${className}`}
        placeholder={placeholder}
        aria-label={placeholder}
        value={value}
        onChange={onChange}
      />
      <div className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
        <i className={`fe fe-${icon} ${darkIcon ? 'tw-text-gray-800' : 'tw-text-gray-400'}`} />
      </div>
    </div>
  );
};

export default TransparentInput;
