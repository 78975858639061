import { ArrowsButton } from '@components/Atoms/ArrowsButton/ArrowsButton';
import { SidebarDropdown } from '@components/Molecules/SidebarDropdown/SidebarDropdown';
import { coreApi } from '@core/rtk-api';
import { signOut } from 'aws-amplify/auth';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customerIoResetIdentify } from 'src/lib/utils/CustomerIo/customerIoUtils';
import { removeItem } from 'src/lib/utils/localStorage';
import { simcardsSlice } from '../../../modules/dashboard/simCard/simcards-slice';

type SideBarUserBarProps = {
  user: string;
  sidebarOpen: boolean;
  usernameIsLoading: boolean;
};

type MenuItem = { title: string; onClick: () => void; icon?: 'account_settings' | 'log_out' };

const USER_NAME_MAX_LENGTH = 15;
const ACCOUNT_SETTINGS_URL = `/dashboard/profile?tab=profile`;

export const SideBarUserBar: FC<SideBarUserBarProps> = ({ user, sidebarOpen, usernameIsLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (user) {
      setUserName(user);
    }
  }, [user]);

  const handleSignOut = useCallback(async () => {
    try {
      dispatch(coreApi.util.resetApiState());
      dispatch(simcardsSlice.actions.reset());
      navigate('/auth');
      removeItem('simModalOpen');
      await signOut();
      customerIoResetIdentify();
      sessionStorage.removeItem('username');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, [dispatch, navigate]);

  const menuItems: MenuItem[] = useMemo(
    () => [
      { title: 'Account settings', onClick: () => navigate(ACCOUNT_SETTINGS_URL), icon: 'account_settings' },
      { title: 'Log out', onClick: handleSignOut, icon: 'log_out' },
    ],
    [navigate, handleSignOut],
  );

  const UserAvatar: FC<{ userName: string }> = ({ userName }) => (
    <div className="tw-flex tw-h-[26px] tw-w-[26px] tw-items-center tw-justify-center tw-rounded-[5px] tw-bg-sb-blue-grey-600">
      <span className="tw-text-center tw-font-bold tw-leading-none tw-text-white">{userName[0]?.toUpperCase()}</span>
    </div>
  );

  const formatUserName = (name: string): string =>
    name.length <= USER_NAME_MAX_LENGTH ? name : `${name.slice(0, USER_NAME_MAX_LENGTH - 3)}...`;

  return (
    <div
      className={`tw-absolute tw-bottom-[36px] tw-left-[-1px] tw-flex tw-justify-between tw-transition-opacity tw-duration-300 ${usernameIsLoading ? 'tw-opacity-0' : 'tw-opacity-100'}`}
    >
      {sidebarOpen ? (
        <div className="tw-flex tw-w-60 tw-items-center tw-justify-between tw-gap-[11px] tw-px-6 tw-py-[10px]">
          <UserAvatar userName={userName} />
          <div className="tw-flex-grow">
            <span className="tw-text-sm tw-font-bold tw-text-[#123466]">{formatUserName(userName)}</span>
          </div>
          <SidebarDropdown menuItems={menuItems} anchorPosition="top end">
            {(open) => <ArrowsButton popUpOpen={open} setPopUpOpen={() => setPopUpOpen(!popUpOpen)} />}
          </SidebarDropdown>
        </div>
      ) : (
        <div className="tw-flex tw-w-[63px] tw-items-center tw-justify-center tw-gap-[10px] tw-p-[10px]">
          <SidebarDropdown menuItems={menuItems} anchorPosition="top start">
            {(_) => (
              <div data-testid="user-dropdown-button" className="hover:tw-cursor-pointer">
                <UserAvatar userName={userName} />
              </div>
            )}
          </SidebarDropdown>
        </div>
      )}
    </div>
  );
};
