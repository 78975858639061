import { Button } from '@components/Atoms/Button/Button';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  modalOpen: boolean;
  closeModal: () => void;
  title: string;
  children?: ReactNode;
  showClose?: boolean;
};
export const Modal: FC<ModalProps> = ({ title, modalOpen, closeModal, children, showClose = false }) => {
  const modalContent = (
    <div
      className="tw-fixed tw-inset-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-overflow-visible tw-bg-black/50"
      onClick={() => closeModal()}
    >
      <Transition appear show={modalOpen}>
        <Dialog as="div" className="tw-relative tw-z-20 tw-bg-white focus:tw-outline-none" onClose={close}>
          <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-visible">
            <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4">
              <TransitionChild
                enter="tw-ease-out tw-duration-300"
                enterFrom="tw-opacity-0 tw-transform-[tw-scale(95%)]"
                enterTo="tw-opacity-100 tw-transform-[tw-scale(100%)]"
                leave="tw-ease-in tw-duration-200"
                leaveFrom="tw-opacity-100 tw-transform-[tw-scale(100%)]"
                leaveTo="tw-opacity-0 tw-transform-[tw-scale(95%)]"
              >
                <DialogPanel className="tw-w-full tw-max-w-md tw-rounded-xl tw-bg-white tw-p-6 tw-backdrop-blur-2xl">
                  <div className="tw-flex tw-justify-between tw-align-middle">
                    <DialogTitle as="h3" className="tw-font-lg tw-my-4 tw-text-center">
                      {title}
                    </DialogTitle>
                    {showClose && (
                      <div className="tw-flex tw-items-center">
                        <Button className="tw-text-lg" variant="borderless" onClick={closeModal}>
                          X
                        </Button>
                      </div>
                    )}
                  </div>
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
  return createPortal(modalContent, document.body);
};
