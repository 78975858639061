import { RadioGroup } from '@headlessui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ReadWriteRadioProps = {
  tabs: string[];
  dropshadow?: boolean;
  value?: React.SetStateAction<string>;
  onChange?(value: React.SetStateAction<string>): void;
};

const BASE_CLASS = 'tw-cursor-pointer tw-px-2.5 tw-py-1 tw-rounded-sm';
const SELECTED_CLASS = 'tw-bg-sb-blue-grey-100 tw-font-semibold';
const UNSELECTED_CLASS = 'tw-bg-white tw-font-normal tw-text-sb-blue-grey-600 hover:tw-bg-gray-100';

export const ReadWriteRadio: React.FC<ReadWriteRadioProps> = ({ tabs, dropshadow = false, value, onChange }) => {
  const { t } = useTranslation();
  const createClassName = (checked: boolean, tab: string) => {
    switch (tab) {
      case 'Write':
        return checked ? `${BASE_CLASS} ${SELECTED_CLASS} tw-text-sb-blue-300` : `${BASE_CLASS} ${UNSELECTED_CLASS}`;
      case 'Read':
        return value === 'Write' || checked
          ? `${BASE_CLASS} ${SELECTED_CLASS} tw-text-sb-green-500`
          : `${BASE_CLASS} ${UNSELECTED_CLASS}`;
      default:
        return checked ? `${BASE_CLASS} ${SELECTED_CLASS} tw-text-sb-orange-300` : `${BASE_CLASS} ${UNSELECTED_CLASS}`;
    }
  };

  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className={`tw-flex tw-gap-1.5 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-2 tw-py-1.5 ${dropshadow ? 'tw-shadow-read-write-radio-shadow' : ''}`}
    >
      {tabs.map((tab) => (
        <RadioGroup.Option key={tab} value={tab} className={({ checked }) => createClassName(checked, tab)}>
          {t(tab)}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};
