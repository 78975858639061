import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { customerIoIdentify, customerIoTrackPage } from "src/lib/utils/CustomerIo/customerIoUtils";

export const useCustomerIo = () => {
  const location = useLocation();
  const [customerIoLaunched, setCustomerIoLaunched] = useState(false);

  useEffect(() => {
    if (customerIoLaunched) {
      customerIoTrackPage(location.pathname);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (!customerIoLaunched) {
      if (!location.pathname.includes("auth")) {
        (async () => await customerIoIdentify())();
        setCustomerIoLaunched(true);
      }
    }
    if (location.pathname.includes("auth")) {
      setCustomerIoLaunched(false);
    }
  }, [location.pathname]);
};
 