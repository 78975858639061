import countriesJson from "./countries.json";

type Country = {
  ISO: string;
  name: string;
  latitude: string;
  longitude: string;
};

export const getCountryCoordsFromName = (name: string) => {
  const countries: Country[] = countriesJson;
  const selectedCountry = countries.find((country) => country.name === name);
  return {
    latitude: selectedCountry?.latitude,
    longitude: selectedCountry?.longitude,
  };
};
