import { Button } from "@components/Atoms/Button/Button";
import { Modal } from "@components/Atoms/Modal/Modal";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { BusinessSubscription, UpdateSubscriptionArgs } from "src/types/types";

type SubscriptionsBusinessPlanProps = {
  subscription: BusinessSubscription;
  updateSubscription: (args: UpdateSubscriptionArgs) => void;
};
export const SubscriptionsBusinessPlan: FC<SubscriptionsBusinessPlanProps> = ({ subscription, updateSubscription }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleCancelClick = () => {
    if (inputValue === "cancel") {
      setModalOpen(false);
      setInputValue("");
      updateSubscription({ subscription: "selfservice" });
    }
  };

  const handleModalClose = () => {
    setInputValue("");

    setModalOpen(false);
  };

  return (
    <>
      {subscription && (
        <div className="tw-h-[450px] tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-w-full">
            <div className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-w-full">
              <div className="tw-p-4 tw-flex tw-justify-between tw-items-center">
                <div className="tw-flex tw-flex-col">
                  <h4>{t("subscriptions.businessPlan.yourSubscription")}</h4>
                  <p className="tw-text-2xl">{subscription.product_name}</p>
                </div>

                <Button onClick={() => setModalOpen(true)} variant="third">
                  {t("subscriptions.businessPlan.cancelSubscription")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalOpen && (
        <Modal
          title={t("common.areYouSure")}
          modalOpen={modalOpen}
          closeModal={() => handleModalClose()}
          showClose={true}
        >
          <p className="tw-mt-2 tw-text-sm/">{t("subscriptions.businessPlan.modalText")}</p>

          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={t("subscriptions.businessPlan.typeCancel")}
            className="tw-w-full tw-rounded-md tw-p-2 tw-bg-white/1"
          />
          <div className="tw-mt-4 tw-flex tw-align-center tw-gap-4">
            <Button className="tw-w-full " onClick={() => handleModalClose()}>
              {t("subscriptions.businessPlan.illKeepIt")}
            </Button>

            <Button variant="fourth" onClick={handleCancelClick} disabled={inputValue !== "cancel"}>
              {t("button.cancel")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
