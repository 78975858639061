import { useCallback } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SimpleTable from '../../../../components/Organisms/SimpleTable/SimpleTable';
import { useGetAllNetworksQuery } from '../network-api-slice';

const NetworksTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRowClick = useCallback(
    (row) => {
      if (row.network_state !== 'Terminated') {
        navigate(`/dashboard/private-network/${row.network_id}/`);
      }
    },
    [navigate],
  );

  const { data: networks } = useGetAllNetworksQuery();

  const columns = [
    {
      Header: 'ID',
      accessor: 'network_id',
    },
    {
      Header: 'NAME',
      accessor: 'network_name',
    },
    {
      Header: 'Subnet',
      accessor: 'subnet',
    },
    {
      Header: 'Size',
      accessor: 'hosts',
    },
    {
      Header: 'Internet',
      accessor: 'internet_access',
    },
    {
      Header: 'State',
      accessor: 'network_state',
      Cell: ({ value }) => {
        let color = 'warning';
        if (value === 'Terminated') {
          color = 'danger';
        } else if (value === 'Deployed') {
          color = 'success';
        }
        return (
          <Badge className="d-flex align-items-center justify-content-center" bg={color}>
            {value}
          </Badge>
        );
      },
    },
  ];

  const handleNewPrivateNetworkClick = () => {
    navigate('/dashboard/private-network/create?step=configure');
  };

  return (
    <div>
      <SimpleTable
        title="Private Networks"
        hasPagination={false}
        data={networks}
        columns={columns}
        hasPerPage={false}
        hasExport={false}
        customActionButton={
          <Button className="text-nowrap" variant="primary" size="sm" onClick={handleNewPrivateNetworkClick}>
            {t('privateNetwork.createNew')}
          </Button>
        }
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default NetworksTable;
