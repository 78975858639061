import Loader from '@components/Loader';
import { GenericDropdown } from '@components/Molecules/GenericDropdown/GenericDropdown';
import React from 'react';
import { Cell, Row } from 'react-table';
import { IActionItem } from 'src/types/types';

type TableBodyProps = {
  getTableBodyProps: () => any;
  page: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  cellProps: Record<string, unknown>;
  hasActions: boolean;
  actionItems?: IActionItem[];
  executingActionRow: number | null;
  onRowClick?: (row: any) => void;
};

export const MemoizedTableBody: React.FC<TableBodyProps> = React.memo(
  ({ getTableBodyProps, page, prepareRow, cellProps, hasActions, actionItems, executingActionRow, onRowClick }) => (
    <tbody {...getTableBodyProps()}>
      {page.map((row, rowIndex) => {
        prepareRow(row);
        const isLastRow = rowIndex === page.length - 1;
        return (
          <tr
            {...row.getRowProps()}
            key={row.id}
            className={`tw-h-full ${!isLastRow && 'tw-border-b tw-border-gray-200'} hover:tw-bg-gray-100`}
            onClick={
              onRowClick
                ? (e: React.MouseEvent<HTMLTableRowElement>) => {
                    const target = e.target as Element;
                    // Prevent row click if clicking on the actions column
                    if (!hasActions) {
                      onRowClick(row.original);
                    } else if (!target.closest('td:last-child')) {
                      onRowClick(row.original);
                    }
                  }
                : undefined
            }
          >
            {row.cells.map((cell: Cell<object>) => {
              const cellPropsWithoutKey = { ...cell.getCellProps() };
              delete cellPropsWithoutKey.key;
              return (
                <td
                  {...cellPropsWithoutKey}
                  key={cell.column.id}
                  className={`${cell.column.id !== 'select' && 'tw-cursor-pointer'} tw-p-4`}
                >
                  {cell.render('Cell', cellProps)}
                </td>
              );
            })}
            {hasActions && (
              <td className="tw-text-right" key="actions-column">
                {row.index === executingActionRow ? (
                  <Loader size={1} animation="grow" className="tw-justify-end" />
                ) : (
                  <GenericDropdown menuItems={actionItems} contextData={row} anchorPosition="bottom end">
                    {(_) => (
                      <div className="tw-whitespace-nowrap tw-text-lg tw-text-sb-blue-grey-400" role="button">
                        <i className="fe fe-more-vertical" />
                      </div>
                    )}
                  </GenericDropdown>
                )}
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  ),
);
