import { Button as NewButton } from '@components/Atoms/Button/Button';
import { SelectBox } from '@components/Atoms/SelectBox';
import { ConfirmationModal } from '@components/ConfirmationModal';
import { Loader } from '@components/Loader';
import { signOut, updateUserAttribute } from 'aws-amplify/auth';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useDeleteAccountMutation,
  useGetSelfQuery,
  useUpdateSelfMutation,
  useUpdateUserMutation,
} from '../account-api-slice';
import { MfaSection } from './MultiFactorAuthentication/MfaSection';

export type IUpdateUser = {
  name?: string;
  email?: string;
  password?: string;
};

type ProfileProps = {
  name: string;
  email: string;
  isFetchingEmail: boolean;
  updateEmailAndSendCode: ({ email }: { email: string }) => Promise<void>;
};

const Profile: FC<ProfileProps> = ({ name, email, isFetchingEmail, updateEmailAndSendCode }) => {
  const { t } = useTranslation();
  const { data: userData } = useGetSelfQuery();
  const [updateSelf, { isLoading: isUpdating }] = useUpdateSelfMutation();
  const [updateUser, updateUserResponse] = useUpdateUserMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const timezoneOptions = moment.tz.names().map((tz) => ({ label: tz, value: tz }));
  const defaultTimezone = timezoneOptions.find((tz) => tz.value === moment.tz.guess()) || timezoneOptions[0];
  const [preferredTime, setPreferredTime] = useState(defaultTimezone);

  useEffect(() => {
    if (userData && userData.preferred_timezone) {
      setPreferredTime({ label: userData.preferred_timezone, value: userData.preferred_timezone });
    }
  }, [userData]);

  const handleTimezoneChange = (value) => {
    setPreferredTime(value);
  };

  const updatePreferredTimezone = () => {
    updateSelf({ preferred_timezone: preferredTime.value });
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    setIsConfirmModalOpen(false);
    try {
      await deleteAccount().unwrap();
      await signOut();
      navigate('/auth');
    } catch (error) {
      console.error('Error deleting account:', error);
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  if (isFetchingEmail) {
    return <Loader />;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore it works
  const updateUserErrorMessage = updateUserResponse.error?.data.errorMessage ?? '';
  return (
    <>
      <Formik
        initialValues={{ name, email }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await updateEmailAndSendCode({ email: values.email });

            if (name !== values.name) {
              await updateUserAttribute({
                userAttribute: {
                  attributeKey: 'name',
                  value: values.name,
                },
              });
              toast.success(t('settings.profile.nameUpdatedSuccessfully'));
            }
          } catch (error) {
            if (error instanceof Error) {
              toast.error(error.message);
            } else {
              toast.error(t('errorMessage.somethingWentWrongGetInTouch'));
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>{t('generic.name')}</Form.Label>
              <InputGroup className="p-0">
                <FormControl
                  value={values.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="string"
                  name="name"
                />
              </InputGroup>
              <span className="text-danger">{errors.name && touched.name && (errors.name as React.ReactNode)}</span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="mb-0">Email</Form.Label>
              <InputGroup className="p-0">
                <FormControl
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="string"
                  name="email"
                />
              </InputGroup>
              <span className="text-danger">{errors.email && touched.email && (errors.email as React.ReactNode)}</span>
            </Form.Group>
            <Button className="mt-3 lift" variant="primary" type="submit" disabled={isSubmitting}>
              {t('button.saveChanges')}
            </Button>
          </form>
        )}
      </Formik>
      <hr className="my-5" />
      <h2 className="text-capitalize mb-1">{t('settings.profile.setTimezone')}</h2>
      <div className="tw-mt-5">
        <div data-cy="timezone-selector">
          <SelectBox
            value={preferredTime}
            defaultInputValue={preferredTime.value}
            options={timezoneOptions}
            onChange={handleTimezoneChange}
          />
        </div>
        <div className="tw-mt-3">
          <NewButton data-testid="updateButton" onClick={updatePreferredTimezone} disabled={isUpdating}>
            {isUpdating ? t('saving') : t('settings.profile.updateTimezone')}
          </NewButton>
        </div>
      </div>
      <hr className="my-5" />
      <MfaSection />
      <Formik
        initialValues={{ confirmPassword: '', password: '' }}
        validate={(values) => {
          interface IErrors {
            password?: string;
            confirmPassword?: string;
          }
          const errors: IErrors = {};
          if (!values.password) {
            errors.password = t('settings.profile.passwordRequired');
          } else if (!/[0-9]+/.test(values.password)) {
            errors.password = t('settings.profile.password.number');
          } else if (!/[A-Z]+/.test(values.password)) {
            errors.password = t('settings.profile.password.upperCase');
          } else if (!/[a-z]+/.test(values.password)) {
            errors.password = t('settings.profile.password.lowerCase');
          } else if (
            // eslint-disable-next-line
            !/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(values.password)
          ) {
            errors.password = t('settings.profile.password.specialCharacter');
          } else if (values.password.length < 8) {
            errors.password = t('settings.profile.password.8Characters');
          }
          if (!values.confirmPassword) {
            errors.confirmPassword = t('settings.profile.passwordRequired');
          }
          if (values.password !== values.confirmPassword) {
            errors.confirmPassword = t('settings.profile.password.shouldMatch');
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await updateUser({ password: values.password }).unwrap();
            toast.success(t('settings.profile.password.passwordChanged'));
            resetForm();
          } catch (error) {
            console.error('Error updating password:', error);
            toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
          } finally {
            setSubmitting(false);
            resetForm();
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <hr className="my-5" />
            <Row className="justify-content-between align-items-center mb-5">
              <Col sm={12} md={9} className="col-xl-7">
                <h2 className="text-capitalize mb-1">{t('settings.profile.password.changeYourPassword')}</h2>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} className="order-md-2">
                <Card className="bg-light border ms-md-4">
                  <Card.Body>
                    <p className="mb-2">{t('settings.profile.password.passwordRequirements')}</p>
                    <p className="small text-muted mb-2">{t('settings.profile.password.mustMeetRequirements')}</p>
                    <ul className="small text-muted ps-4 mb-0">
                      <li>{t('settings.profile.password.minimum8Character')}</li>
                      <li>{t('settings.profile.password.oneUpperOneLower')}</li>
                      <li>{t('settings.profile.password.oneNumber')}</li>
                      <li>{t('settings.profile.password.oneSpecialCharacter')}</li>
                      <li>{t('settings.profile.password.previousPassword')}</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('settings.profile.password.newPassword')}</Form.Label>
                  <InputGroup>
                    <FormControl
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      name="password"
                    />
                  </InputGroup>
                  <span className="text-danger">{errors.password && touched.password && errors.password}</span>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t('settings.profile.password.confirmNewPassword')}</Form.Label>
                  <InputGroup>
                    <FormControl
                      value={values.confirmPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      name="confirmPassword"
                    />
                  </InputGroup>
                  <span className="text-danger">
                    {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                  </span>
                </Form.Group>
                <Button className="w-100 btn-primary lift mt-3" variant="primary" type="submit" disabled={isSubmitting}>
                  {t('settings.profile.password.updatePassword')}{' '}
                </Button>
              </Col>
            </Row>
          </form>
        )}
      </Formik>
      {updateUserResponse.isError && (
        <div className="d-flex justify-content-center mt-3">
          <Alert className="w-100 mb-2" variant="danger">
            {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              updateUserErrorMessage
            }
          </Alert>
        </div>
      )}
      <hr className="my-5" />
      <Row className="justify-content-between">
        <Col sm={12} md={6}>
          <h4>{t('settings.profile.deleteYourAccout')}</h4>
          <p className="small text-muted mb-md-0">{t('settings.profile.pleaseNoteDeletingAccount')}</p>
        </Col>
        <Col sm="auto">
          <Button variant="danger" onClick={() => setIsConfirmModalOpen(true)}>
            {t('button.delete')}
          </Button>
        </Col>
      </Row>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        title={t('settings.profile.deleteAccount')}
        subtitle={t('settings.profile.delete.areYouSure')}
        handleCancel={() => setIsConfirmModalOpen(false)}
        handleConfirm={handleDeleteAccount}
      />
    </>
  );
};

export default Profile;
