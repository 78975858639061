// Plan.tsx
import Loader from '@components/Loader';
import { WorldMapWrapper } from '@components/Organisms/WorldMapWrapper/WorldMapWrapper';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { PlanWithDynamicMap } from 'src/types/types';

type PlanProps = {
  plan: PlanWithDynamicMap;
  handleComplete: (params: { plan_id: string }) => Promise<void>;
  isSelected: boolean;
};

const Plan: React.FC<PlanProps> = React.memo(({ plan, handleComplete, isSelected = false }) => {
  const { name, mb, dynamicMap, countries, sms, sms_to_device, day, currency_symbol, plan_id } = plan;
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSelectPlan = React.useCallback(async () => {
    setIsLoading(true);
    await handleComplete({ plan_id });
    setIsLoading(false);
  }, [handleComplete, plan_id]);

  return (
    <Card className="tw-flex tw-h-full tw-flex-col tw-p-0">
      <Card.Body className="tw-flex tw-h-full tw-flex-col !tw-p-4 md:!tw-p-2 lg:!tw-p-4">
        <div className="tw-w-full tw-flex-grow">
          <WorldMapWrapper dynamicMap={dynamicMap} />
        </div>
        <div className="d-flex justify-content-center">
          <h3 className="display-4 mt-4 mb-5">{name}</h3>
        </div>
        <hr className="m-0" />
        <PlanDetails
          currency_symbol={currency_symbol}
          day={day}
          mb={mb}
          sms={sms}
          sms_to_device={sms_to_device}
          countries={countries}
        />
        <Button
          disabled={isSelected}
          className="w-100"
          variant={isSelected ? 'light' : 'primary'}
          onClick={handleSelectPlan}
        >
          {isLoading ? <Loader size={1} className="text-white" /> : isSelected ? 'Selected' : 'Select plan'}
        </Button>
      </Card.Body>
    </Card>
  );
});

const PlanDetails: React.FC<{
  currency_symbol: string;
  day: string;
  mb: string;
  sms: string;
  sms_to_device: string;
  countries: string;
}> = React.memo(({ currency_symbol, day, mb, sms, sms_to_device, countries }) => (
  <>
    <div className="d-flex justify-content-center">
      <div className="border-end py-4 d-flex justify-content-center flex-column align-items-center w-100">
        <h6 className="text-muted mb-2">SIM SUBSCRIPTION</h6>
        <div className="fw-700 fs-2">{`${currency_symbol}${day} / day`}</div>
      </div>
      <div className="py-4 d-flex justify-content-center flex-column align-items-center w-100">
        <h6 className="text-muted mb-2">DATA RATE</h6>
        <div className="fw-700 fs-2">{`${currency_symbol}${mb} / MB`}</div>
      </div>
    </div>
    <hr className="m-0" />
    <div className="mb-3">
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
          <small>SMS From Device</small>
          <small>{`${currency_symbol}${sms} / SMS`}</small>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
          <small>SMS To Device</small>
          <small>{`${currency_symbol}${sms_to_device} / SMS`}</small>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
          <small>Countries</small>
          <small>{countries}</small>
        </li>
      </ul>
    </div>
  </>
));

export default Plan;
