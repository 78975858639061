import Loader from '@components/Loader';
import { useSendSmsMutation } from '@modules/dashboard/simCard/simcards-api-slice';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const SendSmsModal = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sendSms, sendSmsResponse] = useSendSmsMutation();
  const [iccids, setIccids] = useState<string[]>([]);

  const messageInputRef = useRef<HTMLTextAreaElement>(null);
  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      await sendSms({ iccids, message: e.target.message.value }).unwrap();
      toast.success(t('simcard.messageSentSuccessfully'));
      setIsOpen(false);
      messageInputRef.current.value = '';
    } catch (error) {
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  useImperativeHandle(ref, () => ({
    open: ({ iccids }: { iccids: string[] }) => {
      setIsOpen(true);
      setIccids(iccids);
      if (messageInputRef.current !== null) {
        messageInputRef.current.focus();
      }
    },
  }));

  const [message, setMessage] = useState<string>('');

  return (
    <Modal
      dialogClassName="w-30"
      centered
      show={isOpen}
      onHide={() => {
        setIsOpen(false);
      }}
    >
      <Modal.Body>
        <form onSubmit={sendMessage}>
          <h3 className="header-title pb-3">SMS</h3>
          <label className="form-label">
            Send sms message to <span data-test-id="sms-modal:recipients-count">{iccids.length}</span> recipient
            {iccids.length > 1 && 's'}
          </label>
          <textarea
            id="sms-modal:text-area"
            ref={messageInputRef}
            className="form-control"
            onInput={(e) => setMessage(e.currentTarget.value)}
            name="message"
            data-test-id="sms-modal:message-input"
          ></textarea>
          <div className="d-flex justify-content-end mt-4 hstack gap-3">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setIsOpen(false)}
              data-test-id="sms-modal:cancel-button"
            >
              Cancel
            </button>
            <button
              disabled={sendSmsResponse.isLoading || !message}
              data-test-id="sms-modal:submit-button"
              type="submit"
              className="btn btn-primary d-flex align-items-center"
            >
              <span>Send</span>&nbsp;{sendSmsResponse.isLoading && <Loader size={1} variant="light" />}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
});
