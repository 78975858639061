import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

type Selected = {
  value: number;
  label: string;
};
export type SelectProps = {
  options: Selected[];
  selected: Selected;
  onClick: (option: Selected) => void;
  position?: 'left' | 'right';
};

export function Select({ options, selected, onClick, position = 'right' }: SelectProps) {
  const positionClasses = position === 'right' ? 'tw-right-0' : 'tw-left-0';
  const { t } = useTranslation();
  return (
    <div className="tw-relative">
      <Menu as="div" className="tw-inline-block tw-text-left">
        <MenuButton className="tw-text-md tw-relative tw-inline-flex tw-items-center tw-gap-2 tw-rounded-md tw-border-none tw-bg-white tw-px-4 tw-py-2">
          {selected ? selected.label : t('selectOption')} <i className="fe fe-chevron-down" />
        </MenuButton>
        <Transition
          enter="tw-transition tw-ease-out tw-duration-100"
          enterFrom="tw-opacity-0 tw-scale-95"
          enterTo="tw-opacity-100 tw-scale-100"
          leave="tw-transition tw-ease-in tw-duration-75"
          leaveFrom="tw-opacity-100 tw-scale-100"
          leaveTo="tw-opacity-0 tw-scale-95"
        >
          <MenuItems
            className={`tw-absolute ${positionClasses} tw-z-40 tw-mt-1 tw-w-52 tw-rounded-md tw-border-none tw-bg-custom-blue tw-shadow-lg focus:tw-outline-none`}
          >
            {options.map((option) => (
              <MenuItem key={option.value} as="div" onClick={() => onClick(option)}>
                {({ active }) => (
                  <button
                    className={`tw-group tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded-md tw-border-none tw-px-4 tw-py-2 tw-text-sm tw-text-custom-gray hover:tw-text-bs-header ${
                      active ? 'tw-bg-blue-100' : 'tw-bg-custom-blue'
                    } tw-focus:tw-bg-gray-100 hover:tw-bg-gray-100`}
                  >
                    {option.label}
                  </button>
                )}
              </MenuItem>
            ))}
          </MenuItems>
        </Transition>
      </Menu>
    </div>
  );
}
