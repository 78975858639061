import { Checkbox } from '@components/Atoms/Checkbox/Checkbox';
import Loader from '@components/Loader';
import { LoadingWrapper } from '@components/LoadingWrapper';
import { SIM_STATE } from '@core/constants';
import { useAppSelector } from '@core/rtk-hooks';
import { Radio } from '@modules/dashboard/components';
import { useGetAllNetworksQuery } from '@modules/dashboard/network/network-api-slice';
import { SimcardsListViewItem } from '@modules/dashboard/simCard/simcards-slice';
import { Table } from '@tanstack/react-table';
import {
  getColumnFilters,
  handleFilterClick,
  isChecked,
  ParamType,
  resetFilters,
} from '@utils/simCardUtils/simCardUtils';
import { animate } from 'framer-motion';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import toTitleCase from 'src/lib/utils/toTitleCase';
import { useGetDashboardParametersQuery, useGetTagsQuery } from '../../../simcards-api-slice';
import { ExportButton } from './ExportButton';

type ISimcardsSidebar = {
  table: Table<SimcardsListViewItem>;
};

export const Sidebar = ({ table }: ISimcardsSidebar) => {
  // dashboard params
  const getDashboardParamsResponse = useGetDashboardParametersQuery();

  // Networks
  const getAllNetworkResponse = useGetAllNetworksQuery();
  const networks = useMemo(() => getAllNetworkResponse.data || [], [getAllNetworkResponse.data]);

  // Tags
  const tagsResponse = useGetTagsQuery();
  const { tags } = tagsResponse.data ?? [];

  const getSimsCounter = () => {
    if (table.getSelectedRowModel().rows.length > 0) {
      return {
        counter: table.getSelectedRowModel().rows.length,
        prefix: '',
        suffix: 'selected SIMs',
      };
    }

    if (table.getState().columnFilters.length > 0 || table.getState().globalFilter) {
      return {
        counter: table.getFilteredRowModel().flatRows.length,
        prefix: '',
        suffix: 'filtered SIMs',
      };
    }

    return {
      counter: table.getCoreRowModel().rows.length,
      prefix: 'All',
      suffix: 'SIMs',
    };
  };

  const { counter, prefix, suffix } = getSimsCounter();

  const simcardsState = useAppSelector((state) => state.simcardsState);

  const [searchParams, setSearchParams] = useSearchParams({});
  //The seperate url query param arrays
  const sim_state = searchParams.get('sim_state') || null;
  const coverage = useMemo(
    () => (searchParams.get('coverage') ? searchParams.get('coverage').split(',') : []),
    [searchParams],
  );
  const network_ids = useMemo(
    () => (searchParams.get('network_ids') ? searchParams.get('network_ids').split(',') : []),
    [searchParams],
  );
  const selectedTags = useMemo(
    () => (searchParams.get('tags') ? searchParams.get('tags').split(',') : []),
    [searchParams],
  );

  const updateColumnFilters = useCallback(() => {
    const newColumnFilters = getColumnFilters(coverage, network_ids, selectedTags, sim_state);
    table.setColumnFilters(newColumnFilters);
  }, [coverage, network_ids, selectedTags, sim_state, table]);

  useEffect(() => {
    updateColumnFilters();
  }, [updateColumnFilters]);

  const handleIsChecked = useCallback(
    (paramType: ParamType, filterValue: string) => {
      return isChecked(paramType, filterValue, network_ids, coverage, selectedTags, sim_state);
    },
    [network_ids, coverage, selectedTags, sim_state],
  );

  return (
    <Card className="table-filter-card" style={{ width: '350px' }}>
      <Card.Body className="d-flex flex-column filter-bar-sticky">
        <div className="filter-bar-sticky d-flex flex-column">
          <div>
            <h1 className="header-title pb-16px d-flex align-items-center">
              {prefix}
              {prefix && <>&nbsp;</>}
              {simcardsState.isLoading ? <AnimatedCounter to={counter} /> : counter}
              {suffix && <>&nbsp;</>}
              {suffix}
              <span className="ms-2">
                {simcardsState.isLoading && <Loader size={0.8} variant="primary" animation="grow" />}
              </span>
            </h1>
            <div
              className="text-decoration-underline cursor-pointer mb-24px"
              onClick={() => {
                resetFilters(setSearchParams);
              }}
            >
              Reset filters
            </div>
            <div className="mb-16px">State</div>
            <div className="mb-24px">
              <div className="vstack gap-2">
                <Radio
                  label={toTitleCase(SIM_STATE.ENABLED)}
                  checked={handleIsChecked('sim_state', SIM_STATE.ENABLED)}
                  onClick={() => handleFilterClick('sim_state', SIM_STATE.ENABLED, setSearchParams)}
                />
                <Radio
                  label={toTitleCase(SIM_STATE.DISABLED)}
                  checked={handleIsChecked('sim_state', SIM_STATE.DISABLED)}
                  onClick={() => handleFilterClick('sim_state', SIM_STATE.DISABLED, setSearchParams)}
                />
              </div>
            </div>
            <div className="mb-16px">Coverage</div>
            <div className="mb-32px">
              {getDashboardParamsResponse.isSuccess &&
                getDashboardParamsResponse.data.filters.coverage.map((name) => (
                  <div key={name} className="mb-8px">
                    <Checkbox
                      charsLimit={14}
                      label={name}
                      checked={handleIsChecked('coverage', name)}
                      onChange={() => handleFilterClick('coverage', name, setSearchParams)}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div>
            {networks.length > 0 && (
              <div className="border-top">
                <div className="mb-16px mt-4">Private Network</div>
                <div className="mb-32px">
                  {networks
                    .filter(({ network_state }) => network_state !== 'Terminated')
                    .map(({ network_name: label, network_id }) => (
                      <div key={`network-name-${label}`} className="mb-8px">
                        <Checkbox
                          charsLimit={14}
                          label={label}
                          checked={handleIsChecked('network_ids', network_id)}
                          onChange={() => handleFilterClick('network_ids', network_id, setSearchParams)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
            {tags && tags.length > 0 && <div className="mt-16px mb-16px">Tags</div>}
            <div className="mb-32px">
              <LoadingWrapper isLoading={tagsResponse.isFetching}>
                {tags &&
                  tags.map(({ tag }, i) => (
                    <div key={i} className="mb-8px">
                      <Checkbox
                        charsLimit={14}
                        label={tag}
                        checked={handleIsChecked('tags', tag)}
                        onChange={() => handleFilterClick('tags', tag, setSearchParams)}
                      />
                    </div>
                  ))}
              </LoadingWrapper>
            </div>
          </div>
          <div className="border-top pt-4">
            <ExportButton table={table} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const AnimatedCounter = ({ to }) => {
  const ref = useRef<HTMLSpanElement>(null);

  const [from, setFrom] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setFrom(to);
    }, 1000);
  }, [to]);

  useEffect(() => {
    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (ref.current) {
          (ref.current as HTMLSpanElement).textContent = value.toFixed(0);
        }
      },
    });
    return () => controls.stop();
  }, [from, to]);

  return <span ref={ref} />;
};
