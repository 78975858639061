import { Col, Container, Row } from 'react-bootstrap';

type OverviewHeaderProps = {
  sidebarOpen: boolean;
};
const OverviewHeader = ({ sidebarOpen }: OverviewHeaderProps) => {
  return (
    <div className={`header tw-mt-28 ${sidebarOpen ? `sm:tw-mt-[68px]` : `headerNavigation:tw-mt-[68px]`}`}>
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-end justify-content-between">
            <Col>
              <h6 className="header-pretitle">Overview</h6>
              <h1 className="header-title" data-test-id="simcard-detail:overview-header:title">
                SIM Cards
              </h1>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default OverviewHeader;
