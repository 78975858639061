import icon from '@assets/img/ipSimCardIcon.png';
import { useEffect, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import { Loader } from '../../../../components';
import { PUBLIC_IP_SOURCE_REGEX } from '../../../../core/constants';
import {
  useDeletePublicIpMutation,
  useDeployPublicIpMutation,
  useGetDashboardParametersQuery,
  useLazyGetIpPricingQuery,
} from '../simcards-api-slice';
import IPRule from './IPRule';

interface Rule {
  source: string;
  port_range?: string;
  type?: string;
}

// imsiProfile refers to the actual providers like telna and atlas, while simProfile refers to the internal names assigne to them, for example voyager and atlas
// We are migrating towards simProfile only, but during the transition period both will have to be accepted.

const PublicIPModal = ({ isOpen, handleClose, isManage, iccid, ruleFirewall, imsiProfile, simProfile }) => {
  const { t } = useTranslation();
  // This is a temporary patch to manage card profiles in a unified way.
  let profile: string;
  if (simProfile) {
    profile = simProfile;
  } else {
    if (imsiProfile?.startsWith('telna')) {
      profile = 'voyager';
    } else {
      if (imsiProfile?.startsWith('worldov')) {
        profile = 'atlas';
      }
    }
  }

  const { data: dashboardParameterData, status: dashboardParametersStatus } = useGetDashboardParametersQuery();

  // DeployPublicIp
  const [deployPublicIp, deployPublicIpResponse] = useDeployPublicIpMutation();
  const [deletePublicIp, deletePublicIpResponse] = useDeletePublicIpMutation();

  // GetIpPricing
  const [getIpPricing, getIpPricingResponse] = useLazyGetIpPricingQuery();
  const ipPricing = getIpPricingResponse.data?.ipPricing ?? {};

  const ipPricingIsProcessing = useIsProcessing([getIpPricingResponse.isFetching, getIpPricingResponse.isLoading]);

  const [rules, setRules] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  useEffect(() => {
    if (dashboardParametersStatus === 'fulfilled') {
      if (ruleFirewall && !profile?.startsWith('atlas')) {
        // TODO: When backend returns default rule remove this
        if (!ruleFirewall.length) {
          setRules([dashboardParameterData.publicIp[0]]);
        } else {
          setRules(ruleFirewall);
        }
      }
      if (profile?.startsWith('atlas')) {
        setRules([dashboardParameterData.publicIp[0]]);
      }
    }
    // eslint-disable-next-line
  }, [ruleFirewall, dashboardParametersStatus]);

  useEffect(() => {
    getIpPricing();
    // eslint-disable-next-line
  }, []);

  const { currency_symbol, public_ip = {} } = ipPricing;
  const { rate, unit } = public_ip;

  const handleChangeRule = (newRule, index) => {
    setErrorMessage('');
    setRules(
      rules.map((rule, i) => {
        if (i === index) {
          return newRule;
        }
        return rule;
      }),
    );
  };

  const handleAddRule = () => {
    setErrorMessage('');
    setRules(rules.concat({}));
  };

  const handlePortChange = (port, index) => {
    setErrorMessage('');
    setRules(
      rules.map((rule, i) => {
        if (i === index) {
          return {
            ...rule,
            port_range: port,
          };
        }
        return rule;
      }),
    );
  };

  const handleSourceChange = (source, index) => {
    setErrorMessage('');
    setRules(
      rules.map((rule, i) => {
        if (i === index) {
          return {
            ...rule,
            source,
          };
        }
        return rule;
      }),
    );
  };

  const handleDeleteRule = (index) => {
    setErrorMessage('');
    setRules(rules.filter((_, i) => i !== index));
  };

  const validateRules = (rules: Rule[]): string | null => {
    const invalidRules = rules.filter((rule) => !PUBLIC_IP_SOURCE_REGEX.test(rule.source));
    if (invalidRules.length > 0) {
      return t('publicIp.invalidCIDRAnnotation');
    }
    return null;
  };

  const handleAssignPublicIP = async () => {
    setIsSubmitButtonLoading(true);
    setErrorMessage('');

    const validationError = validateRules(rules);
    if (validationError) {
      setErrorMessage(validationError);
      setIsSubmitButtonLoading(false);
      return;
    }

    try {
      await deployPublicIp({ iccid, data: { rules } }).unwrap();
      handleClose();
      toast.success(t('requestSuccessful'));
    } catch (error) {
      setErrorMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    } finally {
      setIsSubmitButtonLoading(false);
    }
  };

  const doesEmptyRuleRowExists = () => {
    let result = false;
    rules.forEach((rule) => {
      if (!rule?.type) {
        result = true;
      }
    });
    return result;
  };

  const handleDeletePublicIp = async () => {
    try {
      await deletePublicIp({ iccid }).unwrap();

      toast.success(t('deleted'));
    } catch (error) {
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    } finally {
      setIsDeleteConfirmModalOpen(false);
      handleClose();
    }
  };

  return (
    <Modal size="lg" dialogClassName="w-50" centered show={isOpen} onHide={handleClose}>
      {isDeleteConfirmModalOpen ? (
        <>
          <Modal.Header>
            <h3 className="header-title fw-600">Delete Public IP</h3>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="fs-4">
                Are you sure you would like to delete the Public IP address for this SIM? The IP will no longer be
                available for the SIM and cannot be recovered.
              </div>
              <div className="d-flex justify-content-between mt-4">
                {deletePublicIpResponse.isLoading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="w-45">
                      <Button className="w-100" variant="secondary" onClick={() => setIsDeleteConfirmModalOpen(false)}>
                        Cancel
                      </Button>
                    </div>
                    <div className="w-45">
                      <Button className="w-100" variant="danger" type="submit" onClick={handleDeletePublicIp}>
                        Confirm
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
        </>
      ) : (
        <Modal.Body>
          {ipPricingIsProcessing ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-between border-bottom pb-3">
                <div className="d-flex">
                  <div>
                    <img src={icon} alt="icon" height={40} />
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="mb-1">Assign a Public Fixed IP</h4>
                    <small className="text-muted">{`${currency_symbol}${Number(rate).toFixed(2)} per ${unit}, Cancel Anytime`}</small>
                  </div>
                </div>
                <div>
                  <button className="btn-close" onClick={handleClose}></button>
                </div>
              </div>
              <div>
                <div className="d-flex justify-content-between mt-3">
                  <h4 className="mb-1">Configure firewall rules</h4>
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={handleAddRule}
                    disabled={doesEmptyRuleRowExists() || profile?.startsWith('atlas')}
                  >
                    Add Inbound rule
                  </Button>
                </div>
                <div className="d-flex mt-3 mb-2 d-flex flex-column">
                  {rules?.map((rule, index) => (
                    <IPRule
                      key={index}
                      rule={rule}
                      handleChangeRule={handleChangeRule}
                      publicIp={dashboardParameterData?.publicIp}
                      index={index}
                      handlePortChange={handlePortChange}
                      handleSourceChange={handleSourceChange}
                      handleDeleteRule={handleDeleteRule}
                      disabled={profile?.startsWith('atlas')}
                    />
                  ))}
                </div>
                <div className="d-flex justify-content-between">
                  <Button className="btn-light me-3" onClick={handleClose}>
                    Cancel
                  </Button>
                  <div className="d-flex align-items-center">
                    {isManage && (
                      <Button className="btn-danger me-3" onClick={() => setIsDeleteConfirmModalOpen(true)}>
                        Delete
                      </Button>
                    )}
                    <Button
                      disabled={
                        isSubmitButtonLoading ||
                        deployPublicIpResponse.isLoading ||
                        (isManage && profile?.startsWith('atlas'))
                      }
                      className="btn-primary d-flex align-items-center"
                      onClick={handleAssignPublicIP}
                    >
                      <span>{isManage ? t('button.saveChanges') : t('simcard.assignPublicIp')}</span>&nbsp;
                      <span>{isSubmitButtonLoading && <Loader size={1} variant={'light'} />}</span>
                    </Button>
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="mt-3">
                  <Alert dismissible onClose={() => setErrorMessage('')} variant="danger my-0">
                    {errorMessage}
                  </Alert>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      )}
    </Modal>
  );
};

export default PublicIPModal;
