import TagDropdown, { Checkmark } from "@components/TagDropdown/element";
import { useEffect, useState } from "react";
import { useTags } from "src/hooks/useTags";
import { Simcard } from "src/types/types";
import { SimcardsListViewItem } from "../simcards-slice";

const SimcardsTagDropdown = ({
  simcards,
  chevron = false,
}: {
  simcards: Simcard[] | SimcardsListViewItem[];
  chevron?: boolean;
}) => {
  const [checkmarks, setCheckmarks] = useState<Checkmark[]>([]);
  const { tags: allTags, getTagsResponse, addTagToSims, removeTagToSims } = useTags();

  const applyTags = async (tagsToAdd, tagsToRemove) => {
    if (tagsToAdd.length) {
      await addTagToSims({ iccids: simcards.map((simcard) => simcard.iccid), tags: tagsToAdd });
    }
    if (tagsToRemove.length) {
      await removeTagToSims({
        iccids: simcards.map((simcard) => simcard.iccid),
        tags: tagsToRemove,
      });
    }
  };

  useEffect(() => {
    if (simcards) {
      const checkmarks: Checkmark[] = allTags?.map((allTag) => {
        let checked = false;
        let indeterminate = false;
        if (simcards.every((simcard) => simcard.tags?.includes(allTag))) {
          checked = true;
        } else if (simcards?.some((simcard) => simcard.tags?.includes(allTag))) {
          indeterminate = true;
        }
        return {
          label: allTag,
          checked: checked,
          indeterminate: indeterminate,
          action: "none",
          loading: false,
        };
      });
      setCheckmarks(checkmarks);
    }
  }, [simcards, getTagsResponse.status]);

  const customDropdownToggle = (onClick) => (
    <div
      className="dropdown-ellipses dropdown-toggle"
      role="button"
      onClick={onClick}
      data-test-id="simcard-detail:tag-dropdown:toggle"
    >
      <div className="ellipsis">
        <i className="fe fe-more-vertical" />
      </div>
    </div>
  );

  return (
    <TagDropdown
      applyTags={applyTags}
      checkmarks={checkmarks}
      setCheckmarks={setCheckmarks}
      customDropdownToggle={chevron && customDropdownToggle}
    />
  );
};

export default SimcardsTagDropdown;
