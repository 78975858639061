import { usePlansData } from '@utils/usePlansData';
import { PlanWithDynamicMap } from 'src/types/types';
import { OtherPlan, Plan } from '../index';

type PlansProps = {
  initialPlans: PlanWithDynamicMap[];
  handleComplete: (params: { plan_id: string }) => Promise<void>;
  currentPlan?: string;
};

const Plans = ({ initialPlans, handleComplete, currentPlan = '' }: PlansProps) => {
  const plans = usePlansData(initialPlans);

  return (
    <div className="tw-flex tw-w-full tw-flex-col lg:tw-grid lg:tw-grid-cols-3">
      {plans?.length > 1 && (
        <div className="tw-col-span-1 tw-px-1 lg:tw-px-3">
          <Plan plan={plans[0]} handleComplete={handleComplete} isSelected={plans[0].plan_id === currentPlan} />
        </div>
      )}
      {plans?.length > 2 && (
        <div className="tw-col-span-1 tw-px-1 lg:tw-px-3">
          <Plan plan={plans[1]} handleComplete={handleComplete} isSelected={plans[1].plan_id === currentPlan} />
        </div>
      )}
      {plans?.length > 3 && (
        <div className="tw-col-span-1 tw-px-1 lg:tw-px-3">
          <OtherPlan plans={plans.slice(2)} handleComplete={handleComplete} />
        </div>
      )}
    </div>
  );
};

export default Plans;
