import { TFunction } from 'i18next';
import { Activity } from 'src/types/types';

const CUSTOMER_TYPE = {
  buseu: 'business plan EU',
  busus: 'business plan US',
  busoc: 'business plan Oceania',
  busglobal: 'business plan Global',
  selfservice: 'self service',
};
export const activityMapper = (activities: Activity[], t: TFunction<'translation', undefined>) => {
  const activityList = [];
  if (activities) {
    activities.map((activity) => {
      if (activity.parameter === 'Payment method deleted') {
        activityList.push({
          title: t('activity.activityItem.paymentDeleted.title'),
          body: t('activity.activityItem.paymentDeleted.body', {
            user: activity.user,
            previous: activity.previous,
          }),
          icon: 'credit-card',
          time: activity.timestamp,
        });
      } else if (activity.parameter === 'SIM registration') {
        activityList.push({
          title: t('activity.activityItem.simRegistration.title'),
          body: t('activity.activityItem.simRegistration.body', {
            user: activity.user,
            current: activity.current,
          }),
          icon: 'plus',
          time: activity.timestamp,
        });
      } else if (activity.parameter === 'Batch registration') {
        activityList.push({
          title: t('activity.activityItem.batchRegistration.title'),
          body: t('activity.activityItem.batchRegistration.body', {
            user: activity.user,
            current: activity.current,
          }),
          icon: 'box',
          time: activity.timestamp,
        });
      } else if (activity.parameter === 'Address') {
        activityList.push({
          title: t('activity.activityItem.address.title'),
          body: t('activity.activityItem.address.body', {
            user: activity.user,
          }),
          icon: 'map',
          time: activity.timestamp,
        });
      } else if (activity.parameter === 'Network') {
        activityList.push({
          title: t('activity.activityItem.network.title'),
          body: t('activity.activityItem.network.body', {
            user: activity.user,
            previous: activity.previous,
            current: activity.current,
          }),
          icon: 'share-2',
          time: activity.timestamp,
        });
      } else if (activity.parameter === 'IPSec') {
        activityList.push({
          title: t('activity.activityItem.ipsec.title'),
          body: t('activity.activityItem.ipsec.body', {
            user: activity.user,
            previous: activity.previous,
            current: activity.current,
          }),
          icon: 'sliders',
          time: activity.timestamp,
        });
      } else if (activity.parameter === 'Customer Type') {
        activityList.push({
          title: t('activity.activityItem.customerType.title'),
          body: t('activity.activityItem.customerType.body', {
            user: activity.user,
            previous: CUSTOMER_TYPE[activity.previous] || t('activity.activityItem.customerType.selfService'),
            current: CUSTOMER_TYPE[activity.current] || t('activity.activityItem.customerType.selfService'),
          }),
          icon: 'sliders',
          time: activity.timestamp,
        });
      } else {
        activityList.push({
          title: `${activity.parameter} change`,
          body: t('activity.activityItem.default.body', {
            user: activity.user,
            parameter: activity.parameter,
            previous: activity.previous,
            current: activity.current,
          }),
          icon: 'refresh-cw',
          time: activity.timestamp,
        });
      }
    });
  }

  return activityList;
};
