import moment from 'moment-timezone';

type DateFieldKeys<T> = {
  [P in keyof T]: T[P] extends string ? P : never;
}[keyof T];

function isValidDate(dateString: string): boolean {
  return moment(dateString, 'YYYY-MM-DD HH:mm:ss', true).isValid();
}

export function convertTimeFields<T, K extends DateFieldKeys<T>>(data: T[], timeZone: string, dateFields: K[]): T[] {
  return data.map((item) => {
    const newItem = { ...item };
    dateFields.forEach((field) => {
      if (typeof newItem[field] === 'string' && isValidDate(newItem[field] as string)) {
        newItem[field] = moment.utc(newItem[field]).tz(timeZone).format('MMM DD YYYY HH:mm') as T[K];
      }
    });
    return newItem;
  });
}
