// A function to add a dynamic map property to the rate plans in order to use the dynamic WorldMap instead of the static map
import * as Sentry from '@sentry/react';
import { replaceWithDefaultJson } from '@utils/simCardUtils/simCardUtils';
import { useEffect, useState } from 'react';
import { CountryData, PlanWithDynamicMap } from 'src/types/types';

const formatCoverageRows = (data: CountryData[]) => {
  const result: { [key: string]: { country: string; carriers: any } } = {};

  for (const { country_iso, country, carriers } of data) {
    result[country_iso] = {
      country,
      carriers,
    };
  }

  return result;
};

export const usePlansData = (initialPlans: PlanWithDynamicMap[]) => {
  const [plans, setPlans] = useState<PlanWithDynamicMap[]>(initialPlans);

  useEffect(() => {
    const fetchPlansData = async () => {
      if (plans?.length) {
        const updatedPlans = await Promise.all(
          plans.map(async (plan) => {
            try {
              const response = await fetch(plan.coverage);
              let data: CountryData[];
              if (response.ok) {
                data = await response.json();
              } else {
                Sentry.captureException(new Error('Coverage map missing'), {
                  level: 'error',
                });
                const defaultResponse = await fetch(replaceWithDefaultJson(plan.coverage));
                data = await defaultResponse.json();
              }
              return { ...plan, dynamicMap: formatCoverageRows(data) };
            } catch (error) {
              Sentry.captureException(error, {
                level: 'error',
              });
              return plan;
            }
          }),
        );
        setPlans(updatedPlans);
      }
    };

    fetchPlansData();
  }, []);

  return plans;
};
