import { SIM_STATE } from '@core/constants';
import { createColumnHelper } from '@tanstack/react-table';
import { SimcardsListViewItem } from '../../simcards-slice';
import { IndeterminateCheckbox } from './utils';

const columnHelper = createColumnHelper<SimcardsListViewItem>();

export const columns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        data-test-id="simcards-table:select-all-simcards-checkbox"
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          data-test-id={`simcards-table:select-simcard-checkbox-${row.id}`}
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('iccid', {
    id: 'iccid',
    header: 'ICCID',
    cell: (info) => info.getValue(),
    sortingFn: 'text',
  }),
  columnHelper.accessor('device_name', {
    id: 'device_name',
    header: 'Device Name',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('sim_state', {
    id: 'sim_state',
    header: 'State',
    cell: ({ row }) => (
      <span className="text-capitalize">
        {row.original.sim_state === SIM_STATE.ENABLING && <span className="me-1 text-warning">●</span>}
        {row.original.sim_state === SIM_STATE.DISABLED && <span className="me-1 text-danger">●</span>}
        {row.original.sim_state === SIM_STATE.DISABLING && <span className="me-1 text-warning">●</span>}
        {row.original.sim_state === SIM_STATE.INSUFFICIENT_FUNDS && <span className="me-1 text-warning">●</span>}
        {row.original.sim_state === SIM_STATE.ENABLED && <span className="me-1 text-success">●</span>}
        {row.original.sim_state}
      </span>
    ),
  }),
  columnHelper.accessor((simcard) => simcard.coverage, {
    id: 'coverage',
    header: 'Coverage',
    filterFn: 'coverageFilter',
    cell: ({ row }) => <span>{row.original.coverage ?? 'Custom'}</span>,
  }),
  columnHelper.accessor((simcard) => simcard.ip_address, {
    id: 'ip_address',
    header: 'IP Address',
  }),
  columnHelper.accessor('usage_data', {
    id: 'usage_data',
    header: 'Data Usage',
    cell: (info) => <span className="text-end">{info.getValue()}</span>,
  }),
  columnHelper.accessor('network_id', {
    id: 'network_id',
    header: 'Network ID',
    filterFn: 'includesString',
  }),
  columnHelper.accessor((simcard) => simcard.tags, {
    id: 'tags',
    header: 'Tags',
    filterFn: 'tagsFilter',
    cell: ({ row }) => (
      <div className="d-flex flex-wrap h-100" style={{ maxWidth: '120px' }}>
        {row.original.tags?.map((tag) => (
          <span key={tag} className="px-1 m-1 border rounded-1 bg-light">
            {tag}
          </span>
        ))}
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.sms_sent + row.sms_received, {
    id: 'sms',
    header: 'SMS Usage',
    cell: ({ row }) => {
      const { sms_sent, sms_received } = row.original;
      return (
        <span className="text-capitalize">
          <i className="fe fe-arrow-up" />
          <span>{sms_sent || 0}</span>
          &nbsp;
          <i className="fe fe-arrow-down" />
          <span>{sms_received || 0}</span>
        </span>
      );
    },
    sortingFn: (rowA, rowB) => {
      const valueA = rowA.original.sms_sent + rowA.original.sms_received;
      const valueB = rowB.original.sms_sent + rowB.original.sms_received;

      // Handle NaN values
      if (isNaN(valueA) && isNaN(valueB)) return 0;
      if (isNaN(valueA)) return -1;
      if (isNaN(valueB)) return 1;

      return valueA - valueB;
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: () => <span className="w-0"></span>,
  }),
];
