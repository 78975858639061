import emailVerifiedSlice, { checkCognitoEmailVerified } from "@core/features/emailVerifiedSlice";
import { coreApi } from "@core/rtk-api";
import { useAppDispatch, useAppSelector } from "@core/rtk-hooks";
import { getAuthenticatedUser } from "@modules/auth/utils";
import { useVerifyUserEmailMutation } from "@modules/dashboard/account/account-api-slice";
import { sendUserAttributeVerificationCode, updateUserAttribute } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export const useEmailVerified = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isEmailVerified = useAppSelector((state) => state.emailVerifiedState.isEmailVerified);
  const cognitoEmail = useAppSelector((state) => state.emailVerifiedState.email);
  const name = useAppSelector((state) => state.emailVerifiedState.name);

  const [isFetching, setIsFetching] = useState(false);

  const [verifyUserEmail] = useVerifyUserEmailMutation();

  useEffect(() => {
    setIsFetching(true);
    (async () => {
      await dispatch(checkCognitoEmailVerified());
      setIsFetching(false);
    })();
  }, [cognitoEmail, location.pathname]);

  const checkRemoteEmailVerifiedStatus = async (): Promise<void> => {
    /**
     *  Checks the setate of the email verification on Cognito and updates local state
     */
    const { emailVerified } = await getAuthenticatedUser(true);
    if (emailVerified) dispatch(emailVerifiedSlice.actions.verifymEmail());
    if (!emailVerified) dispatch(emailVerifiedSlice.actions.unverifymEmail());
  };

  const updateEmailAndSendCode = async ({ email }: { email: string }): Promise<void> => {
    const lowerCaseEmail = email.toLowerCase();
    if (lowerCaseEmail === cognitoEmail) {
      toast.error("You have entered the same email address.");
      return;
    }
    try {
      await toast.promise(
        updateUserAttribute({
          userAttribute: {
            attributeKey: "email",
            value: lowerCaseEmail,
          },
        }),
        {
          pending: "Sending verification code...",
          success: "Verification code sent successfully!",
        },
      );
      dispatch(emailVerifiedSlice.actions.updateEmail({ email: lowerCaseEmail }));
      dispatch(emailVerifiedSlice.actions.unverifymEmail());
      dispatch(coreApi.util.invalidateTags(["Users"]));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const resendVerificationCode = async () => {
    /**
     * Resends the verification code to the email
     */
    try {
      await toast.promise(sendUserAttributeVerificationCode({ userAttributeKey: "email" }), {
        pending: "Sending verification code...",
        success: "Verification code sent successfully!",
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const submitConfirmation = async ({ mfaCode }: { mfaCode: string }): Promise<boolean> => {
    const { accessToken } = await getAuthenticatedUser();

    try {
      const response = await toast.promise(verifyUserEmail({ code: mfaCode, accessToken }), {
        pending: "Verifying email...",
        success: "Email verified successfully!",
      });
      if ("error" in response) {
        if (response?.error?.data?.errorMessage) {
          toast.error(response.error.data.errorMessage);
          return false;
        }
      }
      dispatch(emailVerifiedSlice.actions.verifymEmail());
      return true;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  return {
    name,
    email: cognitoEmail,
    isEmailVerified,
    isFetching,
    resendVerificationCode,
    submitConfirmation,
    checkRemoteEmailVerifiedStatus,
    updateEmailAndSendCode,
  };
};
