// import { customerIoTrack } from "src/lib/utils/CustomerIo/customerIoUtils";
import { getAuthenticatedUser } from '@modules/auth/utils';
import { AuthError, confirmResetPassword, confirmSignIn, resetPassword, signIn, signOut } from 'aws-amplify/auth';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { setItem } from 'src/lib/utils/localStorage';
import { EnterCodeForm, ForgotPasswordForm, NewPasswordForm, SignInForm } from '../index';
import MfaForm from './MfaForm';

const FORM_TYPES = {
  SIGN_IN: 'SIGN_IN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CODE: 'CODE',
  NEW_PASSWORD: 'NEW_PASSWORD',
  MULTIFACTOR_AUTHENTICATION: 'MULTIFACTOR_AUTHENTICATION',
};

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const params = new URLSearchParams(location.search);
  const code = params.get('code');

  const [email, setEmail] = useState(decodeURIComponent(params.get('email') || '').replace(/\s+/g, '+'));

  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [formType, setFormType] = useState(code ? FORM_TYPES.NEW_PASSWORD : FORM_TYPES.SIGN_IN);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (code) {
      setFormType(FORM_TYPES.NEW_PASSWORD);
    }
    if (formType === FORM_TYPES.MULTIFACTOR_AUTHENTICATION) {
      setFormType(FORM_TYPES.MULTIFACTOR_AUTHENTICATION);
    }
  }, []);

  const onSubmitMfaCode = async ({ mfaCode }, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await confirmSignIn({ challengeResponse: mfaCode });
      setItem('role', (await getAuthenticatedUser()).session.accessToken.payload['cognito:groups'][0]);
      setSubmitting(false);

      navigate('/dashboard');
    } catch (error) {
      console.error('error submitting Mfa code', error);
      setSubmitting(false);
      setErrorMessage(error?.message);
    }
  };

  const handleSignInSuccess = async (email: string) => {
    const user = await getAuthenticatedUser();
    setItem('role', user.session.accessToken.payload['cognito:groups'][0]);
    posthog?.identify(email, {
      email: email,
    });
    navigate('/dashboard');
  };

  const handleSubmit = async ({ email, password, newPassword }, { setSubmitting }) => {
    setSubmitting(true);
    setIsLoading(true);
    setErrorMessage('');
    const lowercaseEmail = email.toLowerCase();

    try {
      const authResponse = await signIn({ username: lowercaseEmail, password });

      switch (authResponse.nextStep.signInStep) {
        case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
          setFormType(FORM_TYPES.MULTIFACTOR_AUTHENTICATION);
          break;
        case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
          const confirmResponse = await confirmSignIn({ challengeResponse: newPassword });
          if (confirmResponse.isSignedIn) {
            await handleSignInSuccess(lowercaseEmail);
          } else {
            setErrorMessage('Failed to confirm sign in with new password. Please try again.');
          }
          break;
        case 'RESET_PASSWORD':
          setFormType(FORM_TYPES.RESET_PASSWORD);
          setEmail(email);
          break;
        default:
          if (authResponse.isSignedIn) {
            await handleSignInSuccess(lowercaseEmail);
          } else {
            setErrorMessage('Unknown sign-in state. Please try again.');
          }
      }
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.name === 'UserAlreadyAuthenticatedException') {
          await signOut();
          setErrorMessage('You were already signed in. Please try again.');
        } else {
          setErrorMessage(error.message || 'An error occurred during sign-in. Please try again.');
        }
      } else {
        console.error('Unexpected error during sign-in:', error);
        setErrorMessage('An unexpected error occurred. Please try again.');
      }

      console.error('error signing in:', error);
      setErrorMessage(error?.message);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
      setIsLoading(false);
    }
  };

  const handleResetPassword = async ({ email }, { setSubmitting }) => {
    setErrorMessage('');
    try {
      const lowercaseEmail = email.toLowerCase();
      const resetPasswordResponse = await resetPassword({ username: lowercaseEmail });
      setUser({
        codeDeliveryDetails: resetPasswordResponse.nextStep.codeDeliveryDetails,
        user: {
          username: lowercaseEmail,
        },
      });
      setFormType(FORM_TYPES.CODE);
    } catch (error) {
      setErrorMessage(error?.message);
    }
    setSubmitting(false);
  };

  const handleEnterCode = async ({ code, password }, { setSubmitting }) => {
    setErrorMessage('');
    try {
      await confirmResetPassword({ username: user?.user?.username, confirmationCode: code, newPassword: password });
      setFormType(FORM_TYPES.SIGN_IN);
    } catch (error) {
      setErrorMessage(error?.message);
    }
    setSubmitting(false);
  };

  const handleForgetPasswordClick = (e) => {
    e.preventDefault();
    setFormType(FORM_TYPES.RESET_PASSWORD);
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm={12} md={5} lg={6} xl={4} className="px-lg-6 my-5 align-self-center">
          <h1 className="display-4 text-center mb-3">{code ? 'Complete registration' : 'Welcome back'}</h1>
          <p className="text-muted text-center mb-5">
            {code ? 'Please enter your new password below.' : 'sign in to our dashboard.'}
          </p>
          {formType === FORM_TYPES.SIGN_IN && (
            <SignInForm onSubmit={handleSubmit} onForgetPassword={handleForgetPasswordClick} />
          )}
          {formType === FORM_TYPES.RESET_PASSWORD && (
            <ForgotPasswordForm
              onSubmit={handleResetPassword}
              goBack={() => setFormType(FORM_TYPES.SIGN_IN)}
              email={email}
            />
          )}
          {formType === FORM_TYPES.CODE && <EnterCodeForm user={user} onSubmit={handleEnterCode} />}
          {formType === FORM_TYPES.NEW_PASSWORD && (
            <NewPasswordForm code={code} email={email} onSubmit={handleSubmit} />
          )}
          {formType === FORM_TYPES.MULTIFACTOR_AUTHENTICATION && <MfaForm onSubmit={onSubmitMfaCode} />}
          {isLoading && <div className="text-center">{t('button.isLoading')}</div>}
          <span className="text-danger">{errorMessage}</span>
        </Col>
        <Col sm={12} md={7} lg={6} xl={8} className="d-none d-lg-block px-0">
          <div className="bg-cover h-100 min-vh-100 bg-sign-in" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
