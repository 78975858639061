import { useGetBillingBalanceQuery } from '@modules/dashboard/billing/billing-api-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import logo from '../../../../assets/img/logo.png';
import { ConfigureStep, WelcomeStep } from '../index';
import {
  useCreateNetworkMutation,
  useGetPricingQuery,
  useLazyGetAllNetworksQuery,
  useLazyGetNetworkQuery,
} from '../network-api-slice';
import CancelAndExitModal from './CancelAndExitModal';
import DeployStep from './DeployStep';

const STEPS = {
  WELCOME: 'welcome',
  CONFIGURE: 'configure',
  DEPLOY: 'deploy',
};

// The `Step` type represents the possible values of the STEPS object.
// This ensures that the `step` variable can only be one of "welcome", "configure", or "deploy".
// This type is dynamically derived from the keys of the STEPS object, providing type safety and preventing typos.
type Step = (typeof STEPS)[keyof typeof STEPS];

type CreateNetworkProps = {
  sidebarOpen: boolean;
};
const CreateNetwork = ({ sidebarOpen }: CreateNetworkProps) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams({ step: 'welcome' });
  const step = searchParams.get('step') || null;

  useGetPricingQuery();
  useGetBillingBalanceQuery();

  // GetNetwork
  const [getNetwork] = useLazyGetNetworkQuery();

  // CreateNetwork
  const [createNetwork] = useCreateNetworkMutation();
  const [networkId, setNetworkId] = useState('');

  const [deployProcessing, setDeployProcessing] = useState(false);
  const [isCancelAndExitModalOpen, setIsCancelAndExitModalOpen] = useState(false);
  const navigate = useNavigate();

  const [getAllNetworks] = useLazyGetAllNetworksQuery();

  const handleDeploy = async (data: { network_name: string; subnet_size: string; internet_access: 'yes' | 'no' }) => {
    setDeployProcessing(true);
    const networkData = await createNetwork(data);
    if ('error' in networkData) {
      setDeployProcessing(false);
      toast('Please add balance before deploying a private network', { type: 'error' });
      return;
    } else if ('data' in networkData) {
      const { network_id } = networkData?.data || {};
      setNetworkId(() => network_id);
      await getNetwork({ id: network_id });
      await getAllNetworks();
    }
    setDeployProcessing(false);
    setStep(STEPS.DEPLOY);
  };

  const handleCancelAndExit = () => {
    navigate('/dashboard/sim-cards');
  };

  const setStep = (step: Step) => {
    setSearchParams((prev) => {
      prev.set('step', step);
      return prev;
    });
  };
  const handleContactSales = () => {
    window.location.href = 'https://www.simbase.com/talk-to-sales';
  };

  const handleBackClick = () => {
    navigate('/dashboard/private-network');
  };

  return (
    <div className="d-flex flex-column h-100 position-relative">
      <div className={`tw-mt-28 ${sidebarOpen ? `sm:tw-mt-[68px]` : `headerNavigation:tw-mt-[68px]`} `}>
        <div className="d-flex justify-content-center align-items-center position-relative">
          <img src={logo} height="auto" width={160} alt="logo" />
          <div className="display-5 mt-2 ms-3">{t('privateNetwork.title')}</div>
          {step === STEPS.CONFIGURE && (
            <>
              <div
                className="position-absolute top-0 start-0 d-flex cursor-pointer title-actions-left"
                onClick={() => handleBackClick()}
              >
                <div>
                  <i className="fe fe-arrow-left fs-2 fw-400" />
                </div>
                <div className="mt-1">{t('back')}</div>
              </div>
              <div
                className="position-absolute top-0 end-0 d-flex cursor-pointer title-actions-right"
                onClick={() => setIsCancelAndExitModalOpen(true)}
              >
                <div className="mt-1">{t('cancelAndExit')}</div>
              </div>
            </>
          )}
        </div>
        <div className="d-flex justify-content-center display-4 my-5">
          {step === STEPS.WELCOME && <span>{t('privateNetwork.welcomeToSimbase')}</span>}
          {step === STEPS.CONFIGURE && <span>{t('privateNetwork.configureYourNetwork')}</span>}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="d-flex flex-column justify-content-center align-items-center w-75 mt-5">
          {step === STEPS.WELCOME && <WelcomeStep onNextClick={() => handleContactSales()} />}
          {step === STEPS.CONFIGURE && <ConfigureStep onNextClick={handleDeploy} deployProcessing={deployProcessing} />}
          {step === STEPS.DEPLOY && <DeployStep networkId={networkId} />}
        </div>
      </div>
      <CancelAndExitModal
        isOpen={isCancelAndExitModalOpen}
        onConfirmClick={handleCancelAndExit}
        onBackClick={() => setIsCancelAndExitModalOpen(false)}
      />
    </div>
  );
};

export default CreateNetwork;
