import { MappedUsageGraphData, Usage } from 'src/types/types';

export type ParamType = 'network_ids' | 'coverage' | 'tags' | 'sim_state';

const FILTER_TYPES: ParamType[] = ['network_ids', 'coverage', 'tags', 'sim_state'];

export const removeEmptyParams = (params: URLSearchParams): URLSearchParams => {
  const keysToDelete: string[] = [];

  for (const key of params.keys()) {
    if (!params.get(key)) {
      keysToDelete.push(key);
    }
  }

  for (const key of keysToDelete) {
    params.delete(key);
  }

  return params;
};

export const resetFilters = (
  setSearchParams: (setParams: (prev: URLSearchParams) => URLSearchParams) => void,
): void => {
  setSearchParams((prev) => {
    FILTER_TYPES.forEach((filter) => {
      prev.delete(filter);
    });
    return prev;
  });
};

export function handleFilterClick(
  paramType: ParamType,
  filterValue: string,
  setSearchParams: (setParams: (prev: URLSearchParams) => URLSearchParams) => void,
): void {
  // Format filterValue for URLs
  const encodedFilterValue = encodeURIComponent(filterValue);
  setSearchParams((prev) => {
    FILTER_TYPES.forEach((filter) => {
      if (paramType === filter && paramType !== 'sim_state') {
        const currentFilters = prev.get(filter) ? prev.get(filter).split(',') : [];
        if (currentFilters.includes(encodedFilterValue)) {
          // Remove the filter if it's already in the list
          const updatedNetworks = currentFilters.filter((id) => id !== encodedFilterValue);
          prev.set(filter, updatedNetworks.join(','));
        } else {
          // Add the filter if it's not in the list
          currentFilters.push(encodedFilterValue);
          prev.set(filter, currentFilters.join(','));
        }
      } else if (paramType === filter) {
        const currentSimState = prev.get(filter) ? prev.get(filter).toString() : [];
        if (currentSimState !== encodedFilterValue) {
          prev.set(filter, encodedFilterValue);
        } else {
          prev.delete(filter);
        }
      }
    });

    return removeEmptyParams(prev);
  });
}

export function isChecked(
  paramType: ParamType,
  filterValue: string,
  network_ids: string[],
  coverage: string[],
  tags: string[],
  sim_state: string | null,
): boolean {
  const encodedFilterValue = encodeURIComponent(filterValue);

  const filterMap: { [key in ParamType]: string[] | string | null } = {
    network_ids,
    coverage,
    tags,
    sim_state,
  };

  const filter = filterMap[paramType];

  if (Array.isArray(filter)) {
    return filter.includes(encodedFilterValue);
  } else if (typeof filter === 'string') {
    return filter === encodedFilterValue;
  }

  return false;
}

export function getColumnFilters(
  coverage: string[],
  network_ids: string[],
  selectedTags: string[],
  sim_state: string | null,
): { id: string; value: string }[] {
  return [
    ...coverage.map((item) => ({ id: 'coverage', value: decodeURIComponent(item) })),
    ...network_ids.map((id) => ({ id: 'network_id', value: decodeURIComponent(id) })),
    ...selectedTags.map((tag) => ({ id: 'tags', value: decodeURIComponent(tag) })),
    ...(sim_state ? [{ id: 'sim_state', value: decodeURIComponent(sim_state) }] : []),
  ];
}

export const convertToMB = (usageData) => {
  const [value, unit] = usageData.split(' ');
  const numericValue = parseFloat(value);
  if (unit === 'GB') {
    return numericValue * 1024;
  }
  return numericValue;
};

export const usageDataSortingFn: any = (rowA, rowB, columnId) => {
  const usageA = convertToMB(rowA.original[columnId]);
  const usageB = convertToMB(rowB.original[columnId]);

  if (usageA < usageB) return -1;
  if (usageA > usageB) return 1;
  return 0;
};

export function updateColumnsWithCustomDataSorting(columns) {
  return columns.map((column) => {
    if (column.id === 'usage_data') {
      return {
        ...column,
        sortingFn: usageDataSortingFn,
        sortDescFirst: true,
      };
    }
    if (column.id === 'ip_address') {
      return {
        ...column,
        sortDescFirst: false,
      };
    }
    if (column.id === 'sim_state') {
      return {
        ...column,
        sortDescFirst: true,
      };
    }
    if (column.id === 'device_name') {
      return {
        ...column,
        sortDescFirst: true,
      };
    }
    if (column.id === 'sms') {
      return {
        ...column,
        sortDescFirst: true,
      };
    }
    return column;
  });
}

export const mapUsageGraphData = (data: Usage[]): MappedUsageGraphData => {
  const labels = [];
  const values = [];

  data.forEach(({ date, volume, month }) => {
    labels.push(date?.substring(5) || month);
    values.push(volume);
  });

  return {
    labels,
    datasets: [
      {
        data: values,
        borderWidth: 1,
        borderColor: '#2C7BE5',
        backgroundColor: '#2C7BE5',
        barPercentage: 0.2,
        borderRadius: 100,
        skipNull: true,
      },
    ],
  };
};

export function replaceWithDefaultJson(url) {
  const lastSlashIndex = url.lastIndexOf('/');
  const newUrl = url.substring(0, lastSlashIndex + 1) + 'default.json';
  return newUrl;
}
