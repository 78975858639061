import { Icon } from '@components/Atoms/Icon/Icon';
import { ReactNode } from 'react';

type IconContentCardProps = {
  iconName: string;
  children: ReactNode;
  iconWidth?: number;
  iconHeight?: number;
};
export const IconContentCard = ({ iconName, children, iconWidth, iconHeight }: IconContentCardProps) => {
  return (
    <div
      data-testid="icon-content-card"
      className="tw-border-sb-blue-grey-300 tw-flex tw-flex-col tw-items-center tw-gap-12 tw-rounded-xl tw-border tw-border-solid tw-bg-white tw-py-5 tw-pl-5 tw-pr-5 md:tw-flex-row md:tw-pl-12 lg:tw-flex-col lg:tw-pl-5 xl:tw-flex-row xl:tw-pl-12"
    >
      <div>
        <Icon width={iconWidth} height={iconHeight} name={iconName} />
      </div>
      {children}
    </div>
  );
};

export default IconContentCard;
