import { PRIVATE_NETWORKS_SUPPORT_LINK } from '@core/constants/links';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import createPrivateNetwork from "@/assets/img/create-private-network.png";
import createPrivateNetwork from '../../../../assets/img/create-private-network.png';

const WelcomeStep = ({ onNextClick }) => {
  const { t } = useTranslation();
  return (
    <Card className="welcome-card">
      <Card.Body>
        <div className="d-flex justify-content-center mb-5">
          <img height={250} src={createPrivateNetwork} alt="..." />
        </div>
        <div className="mb-1 text-gray-600 fw-normal fs-2 text-center mb-2">{t('privateNetwork.welcomeMessage')}</div>
        <div className="fs-4 text-center text-nowrap fw-500 mt-5">
          {t('privateNetwork.ifYouNeedHelp')}&nbsp;
          <a rel="noreferrer" href={PRIVATE_NETWORKS_SUPPORT_LINK} target={'_blank'} className="fs-3 cursor-pointer">
            {t('documentation')}
          </a>
          .
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Button onClick={onNextClick} className="welcome-next-button">
            {t('contactsales')}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default WelcomeStep;
