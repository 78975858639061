export const BUY_SIMS_LINK = 'https://store.simbase.com?utm_source=simbase_platform';
export const APN_LINK = 'https://support.simbase.com/the-new-user/getting-started/how-do-i-set-up-the-apn';
export const STORE_LINK = 'https://store.simbase.com';
export const LEARN_ABOUT_THE_DASHBOARD_LINK = 'https://support.simbase.com/the-new-user/platform';
export const MANAGE_YOUR_BILLING = 'https://support.simbase.com/the-manager/billing';
export const BLOG_LINK = 'https://www.simbase.com/blog';
export const SUPPORT_LINK = 'https://support.simbase.com';
export const SUPPORT_REQUEST_LINK = 'https://www.simbase.com/support-request';
export const API_DOCS_LINK = 'https://docs.simbase.com';
export const PRIVATE_NETWORKS_SUPPORT_LINK = 'https://support.simbase.com/the-developer/private-networks';
export const DOCUMENTATION_LINK = 'https://developer.simbase.com';
