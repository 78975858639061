export const getCurrentYearMonth = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();

  // pad month with a leading zero if necessary
  month = month.length === 1 ? "0" + month : month;

  return `${year}-${month}`;
};
