import { Badge } from "react-bootstrap";

const TagBadges = ({ tags = [], countBeforeMore = 4, disableMore = false }): JSX.Element => {
  let pills = [];
  let moreTagsCounter = 0;

  if (disableMore) {
    pills = tags;
  } else {
    tags?.forEach((tag, i) => {
      if (i < countBeforeMore) {
        pills.push(tag);
      } else {
        moreTagsCounter++;
      }
    });
  }

  if (moreTagsCounter) {
    pills.push(`+ ${moreTagsCounter} more`);
  }

  return (
    <>
      {pills.length > 0 && pills.map((tag) => (
        <Badge key={tag} className="me-2 px-3 mb-1" bg="indigo">
          {tag}
        </Badge>
      ))}
    </>
  );
};

export default TagBadges;
