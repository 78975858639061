import { SMS_INBOX_POLL_TIME } from '@core/constants';
import { useLazyGetPlansQuery } from '@modules/dashboard/activateSims/activateSims-api-slice';
import {
  useGetDashboardParametersQuery,
  useGetDataUsageQuery,
  useGetSimQuery,
  useGetSmsQuery,
  useGetSmsUsageQuery,
  useGetUsageGraphQuery,
  useLazyGetActivityTimelineQuery,
  useLazyGetNetworkRegistrationQuery,
  useLazyGetSmsQuery,
} from '@modules/dashboard/simCard/simcards-api-slice';
import { useEffect, useMemo, useState } from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import { mapUsageGraphData } from 'src/lib/utils/simCardUtils/simCardUtils';
import { OverviewHeader, Sms, Usage } from '../../index';
import { HeaderCards } from '../HeaderCards';
import { useGetSelfQuery } from './../../../account/account-api-slice';
import { Overview } from './tabs/Overview';
import { PlanAndCoverage } from './tabs/PlanAndCoverage';

const tabs = [
  { key: 'overview', label: 'Overview' },
  { key: 'usage', label: 'Usage' },
  { key: 'sms', label: 'SMS' },
  { key: 'plan&coverage', label: 'Plan & Coverage' },
];

type DetailsProps = {
  sidebarOpen: boolean;
};

export const Details = ({ sidebarOpen }: DetailsProps) => {
  const [graphType, setGraphType] = useState('daily');
  const [smsData, setSmsData] = useState(null);

  // GetDashboardParams
  const { t } = useTranslation();
  const { data: dashboardParameterData } = useGetDashboardParametersQuery();

  const [searchParams, setSearchParams] = useSearchParams({ tab: 'overview' });
  const tab = searchParams.get('tab') || null;

  const { iccid } = useParams();
  const getSimResponse = useGetSimQuery({ iccid }, { pollingInterval: 10000, refetchOnMountOrArgChange: true });

  const { data: simcard, isLoading: simCardIsLoading } = useMemo(() => getSimResponse, [getSimResponse]);

  const memoizedSimcard = useMemo(() => simcard, [simcard]);

  const setTab = (tab) => {
    setSearchParams((prev) => {
      prev.set('tab', tab);
      return prev;
    });
  };

  // GetNetworkRegistration
  const [getNetworkRegistration, getNetworkRegistrationResponse] = useLazyGetNetworkRegistrationQuery();
  const {
    data: networkRegistrationData,
    isLoading: networkRegistrationIsLoading,
    isFetching: networkRegistrationIsFetching,
  } = getNetworkRegistrationResponse;
  const networkRegistrationIsProcessing = useIsProcessing([
    networkRegistrationIsLoading,
    networkRegistrationIsFetching,
  ]);

  // Activity Timeline
  const [getActivityTimeline, getActivityTimelineResponse] = useLazyGetActivityTimelineQuery();
  const { data: activityTimelineData } = getActivityTimelineResponse;

  // Self
  const { data: userData, isFetching: userDataIsFetching, isLoading: userDataisLoading } = useGetSelfQuery();

  //UsageGraph
  const { data: usageGraphData, isLoading: usageGraphIsLoading } = useGetUsageGraphQuery({
    iccid: iccid,
    type: graphType === 'daily' ? 'day' : 'month',
  });
  const memoizedUsageGraphData = useMemo(
    () => mapUsageGraphData(usageGraphData?.usageGraph || []),
    [usageGraphData?.usageGraph],
  );

  // getSim
  const {
    data: dataUsageData,
    isLoading: dataUsageIsLoading,
    isFetching: dataUsageIsFetching,
  } = useGetDataUsageQuery({ iccid: iccid });

  // GetSms
  const { data: latestSmsData } = useGetSmsQuery(
    { iccid: iccid },
    { pollingInterval: SMS_INBOX_POLL_TIME, skip: tab !== 'sms', refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (latestSmsData) {
      setSmsData(latestSmsData);
    }
  }, [latestSmsData]);

  // GetSmsUsage
  const {
    data: smsUsageData,
    isFetching: smsUsageIsFetching,
    isLoading: smsUsageIsLoading,
  } = useGetSmsUsageQuery({ iccid: iccid });
  const [getSms] = useLazyGetSmsQuery();

  // Prefetch Plans for ChangePlanModal
  const [triggerGetPlans, _] = useLazyGetPlansQuery();
  useEffect(() => {
    if (iccid) {
      // Prefetch plans when the component mounts or iccid changes
      triggerGetPlans({ iccid });
    }
  }, [iccid, triggerGetPlans]);

  const planAndCoverageProps = useMemo(
    () => ({
      simcard: memoizedSimcard,
      dataIsLoading: simCardIsLoading,
      radio: dashboardParameterData?.radio,
      regions: dashboardParameterData?.regions,
    }),
    [memoizedSimcard, dashboardParameterData?.radio, dashboardParameterData?.regions],
  );

  return (
    <div className="d-flex flex-column h-100">
      <OverviewHeader sidebarOpen={sidebarOpen} />
      <div className="px-3">
        <Nav className="my-0" variant="tabs" activeKey={tab} onSelect={(tab) => setTab(tab)}>
          {tabs.map(({ key, label }, index) => (
            <NavItem key={key}>
              <NavLink
                disabled={getSimResponse.isLoading}
                eventKey={key}
                data-test-id={`simcard-detail:tabs:${label.toLowerCase()}`}
              >
                <span className="d-flex align-items-center text-nowrap">
                  {index === 0 ? label : simCardIsLoading ? t('button.isLoading') : label}
                </span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="pt-4 px-2 h-100">
        <>
          <HeaderCards simcard={simcard} />
          {(tab === 'overview' || tab === null) && (
            <Overview
              trafficPolicies={dashboardParameterData?.trafficPolicies}
              simcard={memoizedSimcard}
              networkRegistrationData={networkRegistrationData}
              networkRegistrationIsProcessing={networkRegistrationIsProcessing}
              getNetworkRegistration={getNetworkRegistration}
              activityTimelineData={activityTimelineData}
              getActivityTimeline={getActivityTimeline}
            />
          )}
          {tab === 'sms' && (
            <Sms
              simcard={memoizedSimcard}
              userData={userData}
              userDataisLoading={userDataisLoading}
              userDataIsFetching={userDataIsFetching}
              sms={smsData?.sms}
              smsUsageData={smsUsageData?.smsUsage}
              smsUsageIsLoading={smsUsageIsLoading}
              smsUsageIsFetching={smsUsageIsFetching}
              getSms={getSms}
            />
          )}
          {tab === 'usage' && (
            <Usage
              simcard={memoizedSimcard}
              userData={userData}
              usageGraphData={memoizedUsageGraphData}
              usageGraphIsLoading={usageGraphIsLoading}
              graphType={graphType}
              setGraphType={setGraphType}
              dataUsageData={dataUsageData}
              dataUsageIsLoading={dataUsageIsLoading}
              dataUsageIsFetching={dataUsageIsFetching}
            />
          )}
          {tab === 'plan&coverage' && <PlanAndCoverage {...planAndCoverageProps} />}
        </>
      </div>
    </div>
  );
};

export default Details;
