import { Loader } from '@components/Loader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import icon from '../../../../assets/img/ipSimCardIcon.png';
import { useConfigureIpsecMutation, useGetIpsecQuery, useGetPricingQuery } from '../network-api-slice';
import ConfigForm from './ConfigForm';

type ConfigureIPSecProps = {
  sidebarOpen: boolean;
};
const ConfigureIPSec = ({ sidebarOpen }: ConfigureIPSecProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [ipsec, setIpsec] = useState(null);
  const [currencySymbol, setCurrencySybmol] = useState('');

  // ConfigureIpsec
  const [configureIpsec] = useConfigureIpsecMutation();

  const { isSuccess: getPricingSuccess, data: getPricingData } = useGetPricingQuery();

  useEffect(() => {
    if (getPricingData) {
      setCurrencySybmol(getPricingData.currency_symbol);
      setIpsec(getPricingData.products.find((item) => item.product_type === 'ipsec'));
    }
  }, [getPricingSuccess, getPricingData]);

  // GetIpsecOptions
  const getIpsecOptionsResponse = useGetIpsecQuery({ id });
  const { data: ipsecOptions } = getIpsecOptionsResponse;

  const isProcessing = useIsProcessing([getIpsecOptionsResponse.isLoading, !getPricingSuccess]);

  if (isProcessing) {
    return <Loader />;
  }

  return (
    <div
      className={`d-flex flex-column tw-mt-28 ${sidebarOpen ? `sm:tw-mt-[68px]` : `headerNavigation:tw-mt-[68px]`} align-items-center`}
    >
      <small className="text-gray-700">{t('configureIPSec.title')}</small>
      <h1>{t('configureIPSec.heading')}</h1>
      <small className="text-gray-700 w-30 text-center">{t('configureIPSec.body')}</small>
      <div className="d-flex flex-column w-50 mt-4">
        <hr />
        <div className="d-flex">
          <div>
            <img src={icon} alt="icon" height={40} />
          </div>
          <div className="d-flex flex-column">
            <h4 className="mb-1">{t('configureIPSec.addTunnel')}</h4>
            <small className="text-muted">{`${currencySymbol}${Number(ipsec.price).toFixed(2)} per ${ipsec.unit}. Cancel Anytime`}</small>
          </div>
        </div>
        <hr />
        <ConfigForm ipsecOptions={ipsecOptions} loading={isProcessing} configureIpsec={configureIpsec} id={id} />
      </div>
    </div>
  );
};

export default ConfigureIPSec;
