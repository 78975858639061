import { BASE_URL } from "@core/constants";
import { prepareHeaders } from "@core/rtk-api";
import { AppDispatch, RootState } from "@core/rtk-store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Simcard } from "src/types/types";
import { convertBytesToMbOrGb } from "./utils";

export type SimcardsListViewItem = Pick<
  Simcard,
  | "iccid"
  | "device_name"
  | "last_lu"
  | "coverage"
  | "sms_sent"
  | "sms_received"
  | "usage_data"
  | "sim_state"
  | "ip_address"
  | "network_id"
  | "tags"
>;

export type SimcardsState = {
  simcards: SimcardsListViewItem[];
  isLoading: boolean;
  isFetching: boolean;
  isFulfilled: boolean;
  error: string | null;
  status: "uninitialized" | "loading" | "fetching" | "fulfilled" | "error";
};

const initialState: SimcardsState = {
  simcards: [],
  isLoading: false,
  isFetching: false,
  isFulfilled: false,
  error: null,
  status: "uninitialized",
};

// Utility Function
const transformSim = (sim: Simcard): SimcardsListViewItem => {
  const output = {
    ...sim,
    device_name: sim.device_name || "",
    last_lu: sim.last_lu || "Never",
    coverage: sim.coverage || "Custom",
    sms_sent: sim.usage_sms_mo,
    sms_received: sim.usage_sms_mt,
    usage_data: convertBytesToMbOrGb(parseInt(sim.usage_data)),
  };
  delete output.usage_sms_mo;
  delete output.usage_sms_mt;
  return output;
};

// Utility Function
const getSim = async (iccid: string, dispatch: AppDispatch): Promise<void> => {
  const response = await fetch(`${BASE_URL}/simcards/${iccid}`, {
    headers: await prepareHeaders(),
  });
  const sim: Simcard = await response.json();
  dispatch(simcardsSlice.actions.upsertSimcard(sim));
};

export const simcardsSlice = createSlice({
  name: "simcardsState",
  initialState,
  reducers: {
    reset: () => initialState,
    addSimcards: (state, action: PayloadAction<Simcard[]>) => {
      state.simcards.push(...action.payload.map((sim) => transformSim(sim)));
    },
    upsertSimcard: (state, action: PayloadAction<Simcard>) => {
      const index = state.simcards.findIndex((s) => s.iccid === action.payload.iccid);
      if (index > -1) {
        const transformedSim = transformSim(action.payload);
        state.simcards[index] = transformedSim;
      } else {
        state.simcards.push(transformSim(action.payload));
      }
    },
    updateSimcards: (state, action: PayloadAction<Simcard[]>) => {
      action.payload.forEach((sim) => {
        const index = state.simcards.findIndex((s) => s.iccid === sim.iccid);
        const transformedSim = transformSim(sim);
        if (index > -1) {
          state.simcards[index] = transformedSim;
        }
      });
    },
    updateSimcardsSimState: (
      state: SimcardsState,
      action: PayloadAction<{
        iccids: string[];
        sim_state: "enabled" | "disabled" | "enabling" | "disabling" | "deleted" | "insufficient funds";
      }>,
    ) => {
      state.simcards.forEach((sim) => {
        if (action.payload.iccids.includes(sim.iccid)) {
          sim.sim_state = action.payload.sim_state;
        }
      });
    },
    startFetching: (state) => {
      state.isFulfilled = false;
      state.isFetching = true;
      state.error = null;
      state.status = "fetching";
    },
    stopFetching: (state) => {
      state.isFetching = false;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      state.status = "error";
    },
    startLoading: (state) => {
      state.isFulfilled = false;
      state.isLoading = true;
      state.error = null;
      state.status = "loading";
    },
    stopLoading: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    setIsFulfilled: (state) => {
      state.isFulfilled = true;
      state.status = "fulfilled";
    },
  },
});

export const fetchSimcardsThunk = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { simcardsState } = getState();
  let cursor: string | null = null;
  if (simcardsState.simcards.length === 0) {
    dispatch(simcardsSlice.actions.startLoading());
  }

  do {
    try {
      const response = await fetch(`${BASE_URL}/simcards/v6?count=3000${cursor != null ? `&cursor=${cursor}` : ""}`, {
        headers: await prepareHeaders(),
      });
      const data = await response.json();
      cursor = data.cursor ?? null;
      const { sims } = data;
      dispatch(simcardsSlice.actions.addSimcards(sims));
    } catch (error) {
      dispatch(simcardsSlice.actions.setError(error.message || "Failed to fetch simcards"));
      return;
    }
  } while (cursor != null);

  // Loading states
  dispatch(simcardsSlice.actions.stopLoading());
  dispatch(simcardsSlice.actions.setIsFulfilled());
};

export const upsertSimcardThunk = (iccid: string) => async (dispatch: AppDispatch) => {
  dispatch(simcardsSlice.actions.startFetching());
  try {
    setTimeout(async () => {
      getSim(iccid, dispatch);
    }, 2000);
    setTimeout(async () => {
      getSim(iccid, dispatch);
    }, 30000);
  } catch (error) {
    dispatch(simcardsSlice.actions.setError(error.message || "Failed to fetch simcards"));
    return;
  }
  dispatch(simcardsSlice.actions.stopFetching());
  dispatch(simcardsSlice.actions.setIsFulfilled());
};

export const updateSimcardsThunk = () => async (dispatch: AppDispatch) => {
  let cursor: string | null = null;
  dispatch(simcardsSlice.actions.startFetching());
  do {
    try {
      const response = await fetch(`${BASE_URL}/simcards/v6?count=3000${cursor != null ? `&cursor=${cursor}` : ""}`, {
        headers: await prepareHeaders(),
      });
      const data = await response.json();
      cursor = data.cursor ?? null;
      const { sims } = data;
      dispatch(simcardsSlice.actions.updateSimcards(sims));
    } catch (error) {
      dispatch(simcardsSlice.actions.setError(error.message || "Failed to fetch simcards"));
      return;
    }
  } while (cursor != null);

  // Loading states
  dispatch(simcardsSlice.actions.stopFetching());
  dispatch(simcardsSlice.actions.setIsFulfilled());
};

// TODO: add custom thunk to update enabling/disabling state to subset of sims
