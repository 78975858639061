import { FC, useMemo } from 'react';
import Select, { GroupBase, OptionsOrGroups, Props as SelectProps, SingleValue } from 'react-select';

type OptionType = {
  label: string;
  value: string | null;
  newMethod?: boolean;
  flag?: string;
};

interface SelectBoxProps extends SelectProps<OptionType> {
  onChange: (option: SingleValue<OptionType>) => void;
  options: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  defaultValue?: SingleValue<OptionType>;
  required?: boolean;
  name?: string;
  isDisabled?: boolean;
  formatOptionLabel?: (data: OptionType) => JSX.Element | null;
  placeholder?: string;
}

export const SelectBox: FC<SelectBoxProps> = ({
  className,
  onChange,
  options,
  defaultValue,
  value,
  name,
  isDisabled,
  formatOptionLabel,
  placeholder,
}) => {
  const emptyAriaLiveMessages = useMemo(
    () => ({
      guidance: () => '',
      onChange: () => '',
      onFilter: () => '',
      onFocus: () => '',
    }),
    [],
  );

  return (
    <Select
      ariaLiveMessages={emptyAriaLiveMessages}
      styles={{
        control: (base, state) => ({
          ...base,
          boxShadow: state.isFocused ? '0 0 0 1px #cbd5e1' : '',
          borderColor: state.isFocused ? '#cbd5e1' : '#d2ddec',
          '&:hover': {
            borderColor: '#cbd5e1',
          },
        }),
        input: (provided) => ({
          ...provided,
          padding: '6px 0px',
          margin: '0px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#B1C2D9',
        }),
      }}
      className={`tw-w-full tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-0 tw-py-0 tw-text-gray-700 ${className || ''}`}
      classNamePrefix="tw-select"
      onChange={onChange}
      options={options}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      formatOptionLabel={formatOptionLabel}
      name={name}
      value={value}
      placeholder={placeholder}
    />
  );
};
