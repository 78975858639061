import { Card, Col, Row } from 'react-bootstrap';

const SimpleInfoCard = ({ title, label, icon }) => {
  return (
    <Card className='mb-0 h-100'>
      <Card.Body>
        <div className='d-flex align-items-center h-100'>
          <Row className='align-items-center w-100 gx-0'>
            <Col>
              <h6 className='text-uppercase text-muted mb-2 text-uppercase'>
                {label}
              </h6>
              <h2 className='header-title'>{title}</h2>
            </Col>
            <Col className='col-auto'>
              <span className={`h2 fe fe-${icon} text-muted mb-0`} />
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SimpleInfoCard;
