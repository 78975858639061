import { useEffect, useState } from "react";

const useIsProcessing = (loadingStates: boolean[]): boolean => {
  const [isProcessing, setIsProcessing] = useState<boolean>(true);

  useEffect(() => {
    const processing = loadingStates.some((loadingState) => loadingState == true);
    setIsProcessing(() => processing);
  }, [loadingStates]);

  return isProcessing;
};

export default useIsProcessing;
