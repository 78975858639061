import { activateSimsApi } from "@modules/dashboard/activateSims/activateSims-api-slice";
import { updateSimcardsThunk } from "@modules/dashboard/simCard/simcards-slice";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";

export const updateAllSims = createListenerMiddleware();
updateAllSims.startListening({
  matcher: isAnyOf(
    activateSimsApi.endpoints.activateBatchSims.matchFulfilled, // { plan_id: string; batches: { batch_id: string; code: string }[]; tags: string[] }
  ),
  effect: async (_, listenerApi) => {
    await listenerApi.dispatch(updateSimcardsThunk());
  },
});