import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { ToggleButton } from '@components/Atoms/ToggleButton/ToggleButton';
import { Loader } from '@components/Loader';
import { InfoToolTip } from '@components/Molecules/InfoToolTip/InfoToolTip';
import { SIM_STATE, SUPPORTPAGE_URL } from '@core/constants';
import TagBadges from '@modules/dashboard/components/TagBadges';
import copyToClipboard from 'copy-to-clipboard';
import { memo, useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, FormControl, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TagDropdown } from '../index';
import { useChangeSimNameMutation, useUpdateSimStatusMutation } from '../simcards-api-slice';

const HeaderCardsComponent = ({ simcard }) => {
  const { t } = useTranslation();
  const [deviceName, setDeviceName] = useState(simcard?.device_name);
  useEffect(() => {
    setDeviceName(() => simcard?.device_name);
  }, [simcard?.device_name]);

  const [simEdit, setSimEdit] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [simNameProcessing, setSimNameProcessing] = useState(false);
  const [simState, setSimState] = useState<SIM_STATE>();
  const nameRef = useRef<HTMLInputElement | null>();

  const [updateSimStatus, updateSimStatusResponse] = useUpdateSimStatusMutation();

  // UpdateSimName
  const [changeSimName] = useChangeSimNameMutation();

  useEffect(() => {
    setSimState(() => simcard?.sim_state as SIM_STATE);
  }, [simcard?.sim_state]);

  useEffect(() => {
    if (showOverlay) {
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    }
  }, [showOverlay]);

  const handleSimStateChange = async () => {
    const newState = simState === SIM_STATE.ENABLED ? SIM_STATE.DISABLED : SIM_STATE.ENABLED;
    try {
      const result = await updateSimStatus({
        iccids: [simcard.iccid],
        sim_state: newState,
      }).unwrap();
      setSimState(() => result.sim_state);
      toast.success(t('simcard.simStateChangeSubmittedSuccessfully'));
    } catch (error) {
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  const handleSimNameChange = async () => {
    setSimNameProcessing(true);
    try {
      await changeSimName({ deviceName, iccid: simcard.iccid }).unwrap();
      toast.success(t('simcard.simNameSuccessfullyUpdated'));
    } catch (error) {
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    } finally {
      setSimNameProcessing(false);
      setSimEdit(false);
    }
  };

  const StateInfoBox = (
    <div className="tw-mb-8 tw-flex tw-max-w-56 tw-flex-col tw-items-center tw-gap-6">
      <span className="tw-text-sm">{t('stateInfoBox.sectionOne')}</span>
      <span className="tw-font-bold">{t('stateInfoBox.sectionTwo')}</span>
      <span>{t('stateInfoBox.sectionThree')}</span>

      <Button
        asLink={true}
        href={`${SUPPORTPAGE_URL}/the-new-user/platform/sim-state`}
        className="tw-w-40 !tw-font-bold"
      >
        {t('Help-Center')}
      </Button>
    </div>
  );

  https: if (!simcard) {
    return <Loader />;
  }

  return (
    <div className="w-100 hstack gap-3 d-flex align-items-stretch">
      <Card className="my-0 w-100">
        <Card.Body>
          <Row className="align-items-center gx-0 h-100">
            <Col>
              <h6 className="text-uppercase text-muted mb-2 text-uppercase">name</h6>
              <div className="d-flex align-items-center justify-content-between">
                <FormControl
                  className={`fw-bold py-0 header-card-title ${!simEdit ? 'border-0 ps-0' : 'ps-2'}`}
                  onClick={() => {
                    if (!simEdit) {
                      setSimEdit(true);
                    }
                  }}
                  onBlur={async () => {
                    await handleSimNameChange();
                    setSimEdit(false);
                  }}
                  ref={nameRef}
                  value={deviceName || ''}
                  onChange={(e) => setDeviceName(e.target.value)}
                  type="text"
                />
                <div className="ms-2 d-flex align-items-end">
                  {simNameProcessing ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <>
                      {!simEdit && (
                        <span
                          onClick={() => {
                            setSimEdit(true);
                            nameRef.current.focus();
                          }}
                          className="h2 fe fe-edit text-muted mb-0 cursor-pointer"
                        />
                      )}
                      {simEdit && (
                        <span
                          onClick={() => handleSimNameChange()}
                          className={`h2 fe fe-save text-muted mb-0 cursor-pointer`}
                          tabIndex={0}
                          id="save-name-change"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-0 w-100">
        <Card.Body>
          <Row className="align-items-center gx-0 h-100">
            <Col>
              <div className="tw-mb-2 tw-flex tw-items-center tw-gap-1">
                <h6 className="text-uppercase text-muted text-uppercase tw-mb-0">state</h6>
                <InfoToolTip title={StateInfoBox}>
                  <div className="tw-flex tw-items-center">
                    <Icon width={14} height={14} name="question_info" />
                  </div>
                </InfoToolTip>
              </div>
              <div className="d-flex justify-content-between">
                {simState === SIM_STATE.ENABLED && (
                  <Badge className="d-flex align-items-center" bg="success">
                    Enabled
                  </Badge>
                )}
                {simState === SIM_STATE.ENABLING && (
                  <Badge className="d-flex align-items-center" bg="warning">
                    Enabling
                  </Badge>
                )}
                {simState === SIM_STATE.DISABLED && (
                  <Badge className="d-flex align-items-center" bg="danger">
                    Disabled
                  </Badge>
                )}
                {simState === SIM_STATE.DISABLING && (
                  <Badge className="d-flex align-items-center" bg="warning">
                    Disabling
                  </Badge>
                )}
                {simState === SIM_STATE.DELETING && (
                  <Badge className="d-flex align-items-center" bg="warning">
                    Deleting
                  </Badge>
                )}
                {simState === SIM_STATE.BLOCKED && (
                  <Badge className="d-flex align-items-center" bg="warning">
                    Blocked
                  </Badge>
                )}
                {updateSimStatusResponse.isLoading ? (
                  <div className="me-3">
                    <Loader size={1.5} className="pe-2" />
                  </div>
                ) : (
                  <ToggleButton
                    handleChange={handleSimStateChange}
                    value={simState === SIM_STATE.ENABLED}
                    disabled={
                      simState === SIM_STATE.INSUFFICIENT_FUNDS ||
                      simState === SIM_STATE.ENABLING ||
                      simState === SIM_STATE.DISABLING ||
                      simState === SIM_STATE.DELETING
                    }
                  />
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-0 w-100">
        <Card.Body>
          <Row className="align-items-center gx-0 h-100">
            <Col>
              <h6 className="text-uppercase text-muted mb-2 text-uppercase">iccid</h6>
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="header-title">{simcard.iccid}</h2>
                <div className="tw-flex tw-items-center tw-gap-1">
                  <OverlayTrigger
                    placement="top"
                    show={showOverlay}
                    overlay={(props) => <Tooltip {...props}>Copied to clipboard!</Tooltip>}
                  >
                    <span
                      onClick={() => {
                        copyToClipboard(simcard.iccid);
                        if (!showOverlay) {
                          setShowOverlay(true);
                        }
                      }}
                      className={`h2 fe fe-clipboard text-muted mb-1 cursor-pointer`}
                    />
                  </OverlayTrigger>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-0 w-100">
        <Card.Body>
          <Row className="align-items-start gx-0 h-100">
            <Col>
              <h6 className="text-uppercase text-muted mb-2 text-uppercase">tags</h6>
              <TagBadges tags={simcard.tags} />
            </Col>
            <Col className="col-auto d-flex align-items-center h-100">
              <TagDropdown simcards={[simcard]} chevron={true} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
export const HeaderCards = memo(HeaderCardsComponent);
