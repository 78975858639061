import { Icon } from '@components/Atoms/Icon/Icon';
import React from 'react';
import { ColumnInstance, HeaderGroup } from 'react-table';

type TableHeaderProps = {
  headerGroups: HeaderGroup<object>[];
  tooltipVisibility: Record<string, boolean>;
  showTooltip: (columnId: string) => void;
  hideTooltip: (columnId: string) => void;
};

export const MemoizedTableHeader: React.FC<TableHeaderProps> = React.memo(
  ({ headerGroups, tooltipVisibility, showTooltip, hideTooltip }) => {
    return (
      <thead className="tw-border-b tw-border-gray-200">
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={headerGroupKey} {...headerGroupProps}>
              {headerGroup.headers.map((column: ColumnInstance<object>) => {
                const { key: columnKey, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
                return (
                  <th
                    key={columnKey}
                    {...columnProps}
                    className="tw-whitespace-nowrap tw-p-4 tw-text-xs tw-font-semibold tw-text-sb-blue-grey-600"
                  >
                    <div className="tw-flex tw-items-center">
                      {column.render('Header')}
                      {column.extraInfo && (
                        <div
                          className="tw-relative tw-flex tw-items-center"
                          onMouseEnter={() => showTooltip(column.id)}
                          onMouseLeave={() => hideTooltip(column.id)}
                        >
                          <span className="tw-ml-2 tw-cursor-help tw-text-gray-500">
                            <Icon name="question_info" width={20} height={20} />
                          </span>
                          {tooltipVisibility[column.id] && (
                            <div className="tw-relative tw-z-[100000]" key={`tooltip-${column.id}`}>
                              <span className="tw-absolute tw-right-0 tw-top-4 tw-mb-2 tw-w-48 tw-overflow-auto tw-whitespace-normal tw-break-words tw-rounded-lg tw-border tw-border-solid tw-border-sb-blue-grey-50 tw-bg-white tw-p-3 tw-text-sm tw-text-gray-700 tw-shadow-lg">
                                {column.extraInfo}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <span className="tw-text-sb-blue-grey-500">
                      {column.isSorted && (
                        <i
                          key={`sort-icon-${column.id}`}
                          className={`fe fe-chevron-${column.isSortedDesc ? 'down' : 'up'}`}
                        />
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
    );
  },
);
