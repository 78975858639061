import { STATUSPAGE_URL } from '@core/constants';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { StatusComponents, StatusSummary } from 'src/types/types';

export const statusApi = createApi({
  reducerPath: 'statuspage-rtk',
  baseQuery: fetchBaseQuery({
    baseUrl: STATUSPAGE_URL,
  }),
  endpoints: (builder) => ({
    getStatus: builder.query<StatusComponents, void>({
      query: () => '/components.json',
    }),

    getSummary: builder.query<StatusSummary, void>({
      query: () => '/summary.json',
    }),
  }),
});

export const { useGetStatusQuery, useGetSummaryQuery } = statusApi;
