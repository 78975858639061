import { Switch } from '@headlessui/react';

const STYLE_DEFAULT = 'default';
const STYLE_GREEN = 'green';
const SR_LABEL = 'Toggle setting';

type ToggleButtonStyle = typeof STYLE_DEFAULT | typeof STYLE_GREEN;

type ToggleButtonProps = {
  value: boolean;
  handleChange: () => void;
  label?: string;
  disabled?: boolean;
  style?: ToggleButtonStyle;
  wrapperClassName?: string;
};

const BASE_CLASS = 'tw-relative tw-inline-flex tw-h-6 tw-items-center tw-rounded-full transition';
const CIRCLE_BASE = 'tw-inline-block tw-h-4 tw-w-4 tw-transform tw-rounded-full tw-bg-white tw-transition';

const STYLE_CLASSES = {
  [STYLE_DEFAULT]: 'tw-border-0 tw-bg-gray-200 data-[checked]:tw-bg-blue-500 tw-w-12',
  [STYLE_GREEN]:
    'tw-rounded-full tw-border-sb-blue-grey-300 tw-border tw-border-solid tw-bg-white tw-px-0 tw-py-0 tw-w-[41px]',
};

const CIRCLE_CLASSES = {
  [STYLE_DEFAULT]: {
    on: 'tw-translate-x-5',
    off: 'tw-translate-x-0',
  },
  [STYLE_GREEN]: {
    on: 'tw-translate-x-5 tw-border tw-border-solid tw-border-sb-green-400 !tw-bg-sb-green-200',
    off: 'tw-translate-x-[3px] tw-border tw-border-solid tw-border-sb-blue-500 !tw-bg-sb-blue-grey-600',
  },
};

export function ToggleButton({
  label,
  value,
  handleChange,
  disabled,
  style = STYLE_DEFAULT,
  wrapperClassName = '',
}: ToggleButtonProps) {
  const switchClassName = `${BASE_CLASS} ${STYLE_CLASSES[style]}`;
  const circleClassName = `${CIRCLE_BASE} ${CIRCLE_CLASSES[style][value ? 'on' : 'off']}`;

  return (
    <div className={`tw-flex tw-items-center tw-gap-2 ${wrapperClassName}`}>
      {!!label && (
        <label htmlFor="switchTwo" className="tw-font-medium tw-text-gray-700">
          {label}
        </label>
      )}
      <Switch id="switchTwo" checked={value} onChange={handleChange} className={switchClassName} disabled={disabled}>
        <span className="tw-sr-only">{SR_LABEL}</span>
        <span className={circleClassName} />
      </Switch>
    </div>
  );
}
