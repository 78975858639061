import { coreApi } from '@core/rtk-api';
import { ListViewApiKey } from 'src/types/types';

type createApiKey = {
  key_name: string;
  expiration?: string;
  allowed_ips?: string;
  scopes: string[];
};

export const integrationsApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getApiKeys: builder.query<{ keys: ListViewApiKey[] }, void>({
        query: () => ({ url: `/api-keys` }),
        providesTags: ['ApiKeys'],
      }),
      getApiScopes: builder.query<any, void>({
        query: () => ({ url: `/api-keys/scopes` }),
        // providesTags: ['ApiKeys'],
      }),
      createApiKey: builder.mutation<any, createApiKey>({
        query: (body) => ({
          url: `/api-keys`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: ['ApiKeys'],
      }),
      deleteApiKey: builder.mutation<any, { keyId: string }>({
        query: ({ keyId }) => ({
          url: `/api-keys`,
          method: 'DELETE',
          body: {
            key_id: keyId,
          },
        }),
        invalidatesTags: ['ApiKeys'],
      }),
      getWebhooks: builder.query<any, void>({
        query: () => ({ url: `/webhooks` }),
      }),
      createWebhook: builder.mutation<any, { url: string; type: string }>({
        query: ({ url, type }) => ({
          url: `/webhooks/${type}`,
          method: 'POST',
          body: { url },
        }),
      }),
    };
  },
});

export const {
  useCreateWebhookMutation,
  useGetWebhooksQuery,
  useDeleteApiKeyMutation,
  useCreateApiKeyMutation,
  useGetApiKeysQuery,
  useGetApiScopesQuery,
} = integrationsApi;
