import { Formik, yupToFormErrors } from "formik";
import * as Yup from "yup";

import { useState } from "react";
import React from "react";
import { Alert, Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Dropdown, Loader } from "../../../../components";
import { PRE_SHARED_KEY_REGEX, REMOTE_IP_REGEX, REMOTE_NETWORK_REGEX } from "../../../../core/constants";
import { IndeterminateCheckbox } from "../../components";

const FIELD_TYPE = {
  LIST: "list",
  TEXT: "text",
  DISABLED: "disabled",
  INTEGER: "integer",
  CHECKBOX: "checkbox",
};

const FormSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  ike: Yup.mixed().required("Required"),
  remote_ip: Yup.string()
    .matches(REMOTE_IP_REGEX, {
      excludeEmptyString: true,
      message: "Invalid format",
    })
    .required("Required"),
  psk: Yup.string()
    .min(20, "Min 20 characters")
    .max(55, "Max 55 characters")
    .matches(PRE_SHARED_KEY_REGEX, {
      excludeEmptyString: true,
      message: "Invalid format",
    })
    .required("Required"),
  ph1_algorithm: Yup.mixed().required("Required"),
  ph1_hash: Yup.mixed().required("Required"),
  ph1_dh: Yup.mixed().required("Required"),
  ph2_customer_ip: Yup.string()
    .matches(REMOTE_NETWORK_REGEX, {
      excludeEmptyString: true,
      message: "Invalid format",
    })
    .required("Required"),
  ph2_algorithm: Yup.mixed().required("Required"),
  ph2_hash: Yup.mixed().required("Required"),
  ph2_dh: Yup.mixed().required("Required"),
});

const Checkbox = ({ field, setFieldValue, values }) => {
  const { field_name, field_id, description } = field;
  return (
    <Form.Group className="">
      <div className="d-flex align-items-center">
        <IndeterminateCheckbox
          onChange={() => setFieldValue(field_id, !values?.[field_id])}
          checked={values?.[field_id] || false}
        />
        <Form.Label className="mb-0">{field_name}</Form.Label>
      </div>
      <small className="text-muted">{description}</small>
    </Form.Group>
  );
};

const Field = ({ field, handleBlur, handleChange, values, isInteger, disabled = false, errors, touched }) => {
  const { field_name, field_id, description, placeholder } = field;
  const error = errors[field_id];

  const onChange = (e) => {
    handleChange(e);
  };

  return (
    <Form.Group className="">
      <Form.Label>{field_name}</Form.Label>
      <InputGroup className="p-0">
        <FormControl
          className={disabled ? "bg-light" : ""}
          disabled={disabled}
          value={values[field_id]}
          onBlur={handleBlur}
          placeholder={placeholder}
          onChange={onChange}
          type={isInteger ? "number" : "string"}
          name={field_id}
        />
      </InputGroup>

      <div>
        <small className="text-danger">{error && touched[field_id] ? error : ""}</small>
      </div>
      <small className="text-muted">{description}</small>
    </Form.Group>
  );
};

const SelectField = ({ errors, field, setFieldValue, touched, values }) => {
  const { field_name, field_id, description, placeholder, options } = field;
  const error = errors[field_id];

  return (
    <div>
      <Dropdown
        options={options.map((value) => ({ key: value, value }))}
        selected={values?.[field_id] || {}}
        onClick={(option) => setFieldValue(field_id, option)}
        formLabel={field_name}
        defaultLabel={placeholder}
        className="text-nowrap"
        noMargins
      />
      <div>
        <small className="text-danger">{error && touched[field_id] ? error : ""}</small>
      </div>
      <small className="text-muted">{description}</small>
    </div>
  );
};

const ConfigForm = ({ ipsecOptions, loading, id, configureIpsec }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (values) => {
    const data = {};
    for (const [key, value] of Object.entries(values)) {
      data[key] = value.key ? value.key : value;
    }
    const response = await configureIpsec({ id, data });
    if (response?.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else if (response?.data?.Message) {
      toast.success(response?.data?.Message);
      navigate(`/dashboard/private-network/${id}/`);
    }
  };

  if (loading) {
    return <Loader />;
  }

  const getInitialValues = () => {
    const initialValues = {};
    ipsecOptions.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === FIELD_TYPE.LIST) {
          initialValues[field.field_id] = {
            key: field.placeholder,
            value: field.placeholder,
          };
        } else if (field.type === FIELD_TYPE.DISABLED) {
          initialValues[field.field_id] = field.placeholder;
        } else if (field.type === FIELD_TYPE.CHECKBOX) {
          initialValues[field.field_id] = false;
        } else {
          initialValues[field.field_id] = field.placeholder;
        }
      });
    });

    return initialValues;
  };

  const validate = async (values) => {
    setErrorMessage("");
    const errors = {};
    let yupErrors = {};
    const { ph1_lifetime, ph2_lifetime } = values;

    try {
      if (typeof ph1_lifetime !== "number") {
        errors.ph1_lifetime = "Required";
      } else if (ph1_lifetime < 20000 || ph1_lifetime > 86400) {
        errors.ph1_lifetime = "Should be between 20000 and 86400 seconds.";
      }
      if (typeof ph2_lifetime !== "number") {
        errors.ph2_lifetime = "Required";
      } else if (ph2_lifetime < 1800 || ph2_lifetime > 7200) {
        errors.ph2_lifetime = "Should be between 1800 and 7200 seconds.";
      }
      await FormSchema.validate(values, { abortEarly: false });
    } catch (err) {
      yupErrors = yupToFormErrors(err);
    }

    return {
      ...errors,
      ...yupErrors,
    };
  };

  const handleCancel = () => {
    navigate(`/dashboard/private-network/${id}/`);
  };

  return (
    <div>
      <Formik initialValues={getInitialValues()} validate={validate} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, ...rest }) => (
          <form onSubmit={handleSubmit}>
            {ipsecOptions.map(({ fields, title }, index) => (
              <div key={`main-${index}`}>
                <h2 className="group-item-title">{title}</h2>
                <div className="d-flex flex-column gap-3">
                  {fields.map((field) => (
                    <React.Fragment key={field.field_id}>
                      {field.type === FIELD_TYPE.TEXT && <Field field={field} {...rest} />}
                      {field.type === FIELD_TYPE.INTEGER && <Field field={field} isInteger {...rest} />}
                      {field.type === FIELD_TYPE.LIST && <SelectField field={field} {...rest} />}
                      {field.type === FIELD_TYPE.DISABLED && <Field field={field} disabled {...rest} />}
                      {field.type === FIELD_TYPE.CHECKBOX && <Checkbox field={field} {...rest} />}
                    </React.Fragment>
                  ))}
                  <hr className="my-4" />
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Button variant="white" onClick={handleCancel}>
                Cancel
              </Button>
              <small className="text-gray-700">CONFIGURE IPSEC</small>
              <Button onClick={handleSubmit}>
                <div className="d-flex align-items-center gap-2">
                  <div>Deploy IPSec Configuration</div>
                  {isSubmitting && (
                    <div>
                      <Loader variant="light" size={1.2} />
                    </div>
                  )}
                </div>
              </Button>
            </div>
            {errorMessage && (
              <div className="mt-3">
                <Alert dismissible onClose={() => setErrorMessage("")} variant="danger my-0">
                  {errorMessage}
                </Alert>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ConfigForm;
