import { coreApi } from "@core/rtk-api";
import { Customer, ReduxResponse, Self, UpdateSubscriptionArgs, Users } from "src/types/types";
import { IUpdateUser } from "./components/Profile";

export const accountApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCustomer: builder.query<Customer, void>({
        query: () => ({ url: `/customer` }),
        transformResponse: (data: any) => ({
          customerDetails: data,
        }),
        providesTags: ["Customer"],
        keepUnusedDataFor: 900,
      }),
      editCustomer: builder.mutation<any, any>({
        query: (data) => ({
          url: `/customer`,
          method: "PATCH",
          body: data,
        }),
        transformResponse: async (data: any) => ({
          customerDetails: data,
        }),
        // invalidatesTags: ["Customer"], // Do not enable this as it breaks the add balance form in billing
      }),
      verifyUserEmail: builder.mutation<ReduxResponse<null>, { code: string; accessToken: string }>({
        query: ({ code, accessToken }) => ({
          url: `/users/verify-email`,
          method: "POST",
          body: { code, access_token: accessToken },
        }),
      }),
      getUsers: builder.query<Users, void>({
        query: () => ({ url: `/users` }),
        providesTags: ["Users"],
      }),
      getSubscriptions: builder.query<any, void>({
        query: () => ({ url: `/subscriptions` }),
        keepUnusedDataFor: 900,
      }),
      deleteUser: builder.mutation<any, string>({
        query: (id) => ({
          url: `/users/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Users"],
      }),
      editUser: builder.mutation<any, { name: string; email: string; role: string; id: string }>({
        query: ({ name, email, role, id }) => ({
          url: `/users/${id}`,
          method: "PATCH",
          body: { name, email, role },
        }),
        invalidatesTags: ["Users"],
      }),
      getSelf: builder.query<Self, void>({
        query: () => ({ url: `/users/self` }),
        providesTags: ["Self"],
        keepUnusedDataFor: 900,
      }),
      createUser: builder.mutation<any, { name: string; email: string; role: string }>({
        query: ({ name, email, role }) => ({
          url: `/users`,
          method: "POST",
          body: { name, email, role },
        }),
        invalidatesTags: ["Users"],
      }),
      createCustomRequest: builder.mutation<any, any>({
        query: (data) => ({
          url: `/customrequest`,
          method: "POST",
          body: data,
        }),
      }),
      updateUser: builder.mutation<any, IUpdateUser>({
        query: ({ name, email, password }) => ({
          url: `/users`,
          method: "PATCH",
          body: { name, email, password },
        }),
        invalidatesTags: ["Users"],
      }),
      updateSelf: builder.mutation<any, any>({
        query: (data) => ({
          url: `/users`,
          method: "PATCH",
          body: data,
        }),
        invalidatesTags: ["Self"],
      }),
      deleteAccount: builder.mutation<any, void>({
        query: () => ({
          url: `/users/self`,
          method: "DELETE",
        }),
      }),
      forgetMfaDevice: builder.mutation<void, { device_id: string }>({
        query: ({ device_id }) => ({
          url: `/mfa-device/${device_id}`,
          method: "DELETE",
        }),
      }),
      updateSubscription: builder.mutation<void, UpdateSubscriptionArgs>({
        query: ({ subscription }) => ({
          url: `/subscriptions/update`,
          method: "POST",
          body: { subscription },
        }),
        invalidatesTags: ["Customer", { type: "Simcard", id: "LIST" }],
      }),
    };
  },
});

export const {
  useDeleteAccountMutation,
  useUpdateSelfMutation,
  useUpdateUserMutation,
  useCreateCustomRequestMutation,
  useCreateUserMutation,
  useGetSelfQuery,
  useLazyGetSelfQuery,
  useEditUserMutation,
  useDeleteUserMutation,
  useGetSubscriptionsQuery,
  useGetCustomerQuery,
  useEditCustomerMutation,
  useGetUsersQuery,
  useForgetMfaDeviceMutation,
  useVerifyUserEmailMutation,
  useUpdateSubscriptionMutation,
} = accountApi;
