import { VectorMap } from '@react-jvectormap/core';
import $ from 'jquery';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedCountryDataForMaps } from 'src/types/types';
import { paths } from './WorldMapPaths';

type WorldMapProps = {
  countryData: FormattedCountryDataForMaps;
  showBorder?: boolean;
};
export const WorldMap: FC<WorldMapProps> = ({ countryData = {}, showBorder = false }) => {
  const usedDataRef = useRef(countryData);
  const [regionStyles, setRegionStyles] = useState({});

  useEffect(() => {
    if (Object.keys(countryData).length > 0) {
      usedDataRef.current = countryData;
    }
  }, [countryData]);

  // Sets the correct background color for countries based on the business plan in use
  useEffect(() => {
    if (countryData && typeof countryData === 'object' && Object.keys(countryData).length > 0) {
      const regions = Object.keys(paths).reduce((acc, countryKey) => {
        acc[countryKey] = countryData[countryKey] ? 1 : 2;
        return acc;
      }, {});

      setRegionStyles(regions);
    } else {
      const defaultRegions = Object.fromEntries(Object.keys(paths).map((countryKey) => [countryKey, 2]));
      setRegionStyles(defaultRegions);
    }
  }, [countryData]);

  // Sets the content and styling for the contents displayed when hovering on a country
  const tipShow = useCallback((_, el, code) => {
    const data = usedDataRef.current[code];
    if (data) {
      el.html(`
            <div style="
                background-color: white;
                color: rgb(20, 37, 62);
                border: none;
                padding: 5px;
                line-height: 1.7;
            ">
                <strong>${data.country}</strong><br>
                ${data.carriers.map((item) => `${item.carrier}: ${item.network.join(', ')}<br>`).join('')}
            </div>
        `);
    } else {
      const country = paths[code];
      el.html(`
            <div style="
                background-color: white;
                color: rgb(20, 37, 62);
                border: none;
                padding: 3px;
                line-height: 1.5;
            ">
                <strong>${country.name}</strong>
            </div>
        `);
    }

    $('.jvectormap-tip').css({
      backgroundColor: 'white',
      zIndex: 10000,
    });
  }, []);

  return (
    <div className="tw-relative tw-h-full tw-w-full">
      <VectorMap
        map={{
          content: {
            insets: [
              {
                bbox: [
                  {
                    x: -20004297.151525836,
                    y: -18449355.69035302,
                  },
                  {
                    x: 20026572.394749384,
                    y: 7485321.539093307,
                  },
                ],
                height: 583.0802520919394,
                left: 0,
                top: 0,
                width: 900,
              },
            ],
            paths: paths,
            projection: {
              centralMeridian: 11.5,
              type: 'merc',
            },
            width: 900,
            height: 600,
          },
          name: 'world_merc',
        }}
        backgroundColor="white"
        onRegionTipShow={(_, el, code) => tipShow(_, el, code)}
        series={{
          regions: [
            {
              attribute: 'fill',
              normalizeFunction: undefined,
              scale: {
                '1': 'rgb(22, 50, 100)',
                '2': '#DBE4F6',
              },
              values: regionStyles,
            },
          ],
        }}
        style={{
          border: showBorder ? '1px solid rgb(237, 242, 249)' : '',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <style>
        {`
          .jvectormap-zoomin {
            line-height: 8px !important;
            color: hsla(210, 38%, 44%, 1) !important;
            background-color: hsla(216, 71%, 99%, 1) !important;
            border: 1px solid hsla(220, 60%, 91%, 1) !important;
            border-radius: 5px !important;
            padding: 7px !important;
          }
          .jvectormap-zoomout {
            line-height: 8px !important;
            color: hsla(210, 38%, 44%, 1) !important;
            background-color: hsla(216, 71%, 99%, 1) !important;
            border: 1px solid hsla(220, 60%, 91%, 1) !important;
            border-radius: 5px !important;
            padding: 7px !important;
            top: 40px !important;
          }
        `}
      </style>
    </div>
  );
};
