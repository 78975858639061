import { Spinner } from "react-bootstrap";

interface ILoader {
  animation?: "border" | "grow";
  role?: string;
  variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
  size?: number;
  className?: string;
  flexPosition?: string;
}

export const Loader = ({
  animation = "border",
  role = "status",
  variant = "primary",
  size = 2,
  className = "",
  flexPosition = "justify-content-center",
}: ILoader) => {
  return (
    <div className={`d-flex ${flexPosition} align-items-center h-100 w-100 ${className}`}>
      <Spinner
        style={{
          height: size + "rem",
          width: size + "rem",
        }}
        variant={variant}
        animation={animation}
        role={role}
        className={className}
      />
    </div>
  );
};

export default Loader;
