import { ToggleButton } from '@components/Atoms/ToggleButton/ToggleButton';
import Loader from '@components/Loader';

export type OptionToggleProps = {
  title: string;
  body: string;
  handleOnClick: () => void;
  value: boolean;
  //Using Feather Icons
  bootstrapIcon?: string;
  disabled?: boolean;
  isLoading?: boolean;
};

export const OptionToggle = ({
  title,
  body,
  handleOnClick,
  value,
  bootstrapIcon,
  disabled,
  isLoading,
}: OptionToggleProps) => {
  return (
    <div className="tw-my-0 tw-flex tw-w-full tw-flex-col tw-rounded-lg tw-bg-white tw-shadow">
      <div className="tw-p-6">
        <div className="tw-flex tw-h-full tw-items-center tw-justify-between">
          <div className="tw-flex">
            {bootstrapIcon && (
              <div className="tw-flex tw-items-center tw-pr-6">
                <i className={`${bootstrapIcon} tw-text-l`} />
              </div>
            )}
            <div className="tw-flex tw-flex-col">
              <h4 className="tw-mb-1 tw-font-medium tw-text-bs-header">{title}</h4>
              <p className="tw-mb-1 tw-text-card-body tw-text-bs-muted">{body}</p>
            </div>
          </div>
          <div className="tw-flex tw-items-center">
            {isLoading ? <Loader /> : <ToggleButton disabled={disabled} value={value} handleChange={handleOnClick} />}
          </div>
        </div>
      </div>
    </div>
  );
};
