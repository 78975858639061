import { WorldMap } from '@components/Organisms/WorldMap/WorldMap';
import React, { useEffect, useState } from 'react';
import { FormattedCountryDataForMaps } from 'src/types/types';

type WorldMapWrapperProps = {
  dynamicMap: FormattedCountryDataForMaps;
};

export const WorldMapWrapper: React.FC<WorldMapWrapperProps> = React.memo(({ dynamicMap }) => {
  const [mapKey, setMapKey] = useState(0);

  useEffect(() => {
    // Force a rerender of the WorldMap after initial mount, because the initial render is always too small
    const timer = setTimeout(() => {
      setMapKey((prev) => prev + 1);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="tw-h-64 xl:tw-h-80 2xl:tw-h-96">
      <WorldMap key={mapKey} countryData={dynamicMap} />
    </div>
  );
});
