import { VIEW_PERMISSIONS } from '@core/constants';
import { SidebarMenuItem } from 'src/types/types';

export const MENU: SidebarMenuItem[] = [
  {
    icon: 'home',
    name: 'home',
    title: 'Home',
    url: 'home',
    permission: VIEW_PERMISSIONS.HOME,
  },
  {
    icon: 'sim_cards',
    name: 'sim-cards',
    title: 'SIM cards',
    url: 'sim-cards',
    permission: VIEW_PERMISSIONS.SIMCARDS,
  },
  {
    icon: 'private_network',
    name: 'private-network',
    title: 'Private Network',
    url: 'private-network',
    permission: VIEW_PERMISSIONS.PRIVATE_NETWORKS,
  },
  {
    icon: 'billing',
    name: 'billing',
    title: 'Billing',
    url: 'billing',
    permission: VIEW_PERMISSIONS.BILLING,
  },
  {
    icon: 'integrations',
    name: 'integrations',
    title: 'Integrations',
    url: 'integrations',
    permission: VIEW_PERMISSIONS.INTEGRATIONS,
  },
  {
    icon: 'settings',
    name: 'settings',
    title: 'Settings',
    url: 'profile',
  },
];
