import React, { Dispatch, FC, SetStateAction } from 'react';

type OpenCollapseButtonProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

const OpenSVG: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="44" viewBox="0 0 36 44" fill="none">
    <path
      d="M0.5 5.00001C0.5 2.51473 2.51472 0.5 5 0.5H35.4623V42.783H5C2.51472 42.783 0.5 40.7683 0.5 38.283V5.00001Z"
      fill="#F9FBFE"
      stroke="#DBE4F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3811 29.483C14.0439 30.3667 15.2975 30.5458 16.1811 29.883C17.0648 29.2203 17.2439 27.9667 16.5811 27.083L12.5 21.6415L16.5811 16.2C17.2439 15.3163 17.0648 14.0627 16.1811 13.4C15.2975 12.7373 14.0439 12.9163 13.3811 13.8L8.4 20.4415C7.86667 21.1526 7.86667 22.1304 8.4 22.8415L13.3811 29.483ZM24.3623 29.483C25.0251 30.3667 26.2787 30.5458 27.1623 29.883C28.046 29.2203 28.2251 27.9667 27.5623 27.083L23.4812 21.6415L27.5623 16.2C28.2251 15.3163 28.046 14.0627 27.1623 13.4C26.2787 12.7373 25.0251 12.9163 24.3623 13.8L19.3812 20.4415C18.8479 21.1526 18.8479 22.1304 19.3812 22.8415L24.3623 29.483Z"
      fill="#DBE4F6"
    />
  </svg>
);

const CloseSVG: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="44" viewBox="0 0 36 44" fill="none">
    <path
      d="M0.5 5.00001C0.5 2.51473 2.51472 0.5 5 0.5H35.4623V42.783H5C2.51472 42.783 0.5 40.7683 0.5 38.283V5.00001Z"
      fill="#F9FBFE"
      stroke="#DBE4F6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5813 13.7999C21.9185 12.9163 20.6649 12.7372 19.7813 13.3999C18.8976 14.0627 18.7185 15.3163 19.3813 16.1999L23.4624 21.6415L19.3813 27.083C18.7185 27.9666 18.8976 29.2202 19.7813 29.883C20.6649 30.5457 21.9185 30.3666 22.5813 29.483L27.5624 22.8415C28.0957 22.1303 28.0957 21.1526 27.5624 20.4415L22.5813 13.7999ZM11.6001 13.7999C10.9373 12.9163 9.68372 12.7372 8.80007 13.3999C7.91641 14.0627 7.73733 15.3163 8.40007 16.1999L12.4812 21.6415L8.40006 27.083C7.73732 27.9666 7.91641 29.2202 8.80006 29.883C9.68372 30.5457 10.9373 30.3666 11.6001 29.483L16.5812 22.8415C17.1145 22.1303 17.1145 21.1526 16.5812 20.4415L11.6001 13.7999Z"
      fill="#DBE4F6"
    />
  </svg>
);

export const OpenCollapseButton: FC<OpenCollapseButtonProps> = React.memo(({ open, setOpen, className = '' }) => {
  const handleClick = React.useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, [setOpen]);

  return (
    <div className={`hover:tw-cursor-pointer ${className}`} onClick={handleClick} data-testid="collapse-button">
      {open ? <OpenSVG /> : <CloseSVG />}
    </div>
  );
});
