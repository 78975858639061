import { Button, Modal } from 'react-bootstrap';

const CancelAndExitModal = ({ isOpen, onBackClick, onConfirmClick }) => {
  return (
    <Modal dialogClassName='w-35' centered show={isOpen}>
      <Modal.Header>
        <h3 className='header-title fw-600'>
          Exit the Simbase Private Network Setup?
        </h3>
        <div className='d-flex h-100 align-items-center'>
          <button className='btn-close' onClick={onBackClick}></button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className='fs-4'>
            Your current settings will be lost, and you’ll be redirected to the
            Simbase Dashboard
          </div>
          <div className='d-flex justify-content-end mt-4'>
            <>
              <div className='w-30 me-3'>
                <Button
                  className='w-100 text-nowrap'
                  variant='white'
                  onClick={onBackClick}
                >
                  Back to Setup
                </Button>
              </div>
              <div className='w-30'>
                <Button
                  className='w-100 text-nowrap'
                  variant='primary'
                  type='submit'
                  onClick={onConfirmClick}
                >
                  Confirm and Exit
                </Button>
              </div>
            </>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAndExitModal;
