import Loader from "./Loader";

interface ILoadingButtonContent {
  buttonText: string;
  isLoading: boolean;
  variant?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark";
}

export const LoadingButtonContent = ({ buttonText, isLoading = false, variant = "primary" }: ILoadingButtonContent) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <span className="d-flex align-items-center text-nowrap gap-2">
        {buttonText} {isLoading && <Loader size={1} variant={variant} />}
      </span>
    </div>
  );
};
