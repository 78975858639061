import { activateSimsApi } from "@modules/dashboard/activateSims/activateSims-api-slice";
import { networkApi } from "@modules/dashboard/network/network-api-slice";
import { simcardsApi } from "@modules/dashboard/simCard/simcards-api-slice";
import { upsertSimcardThunk } from "@modules/dashboard/simCard/simcards-slice";
import { createListenerMiddleware, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

export type SimsPayload = {
  iccids: string[];
};

export type ActivateSimPayload = {
  plan_id: string;
  sims: { iccid: string; code: string }[];
};

export interface Meta {
  arg: Arg;
}
export interface Arg {
  type: string;
  endpointName: string;
  originalArgs: OriginalArgs;
  track: boolean;
}

export interface OriginalArgs {
  sims: Sim[];
  plan_id: string;
  tags: any[];
}

export interface Sim {
  iccid: string;
  code: string;
}

export const updateSims = createListenerMiddleware();
updateSims.startListening({
  /**
   * This middleware fetches the individual sims that need to be updated or added to the store
   */
  matcher: isAnyOf(
    simcardsApi.endpoints.removeTagToSims.matchFulfilled, // { tags: string[]; iccids: string[] }
    simcardsApi.endpoints.addTagToSims.matchFulfilled, // { tags: string[]; iccids: string[] }
    simcardsApi.endpoints.updateSimStatus.matchFulfilled, // { iccids: string[]; sim_state: SIM_STATE }
    networkApi.endpoints.assignSimToNetwork.matchFulfilled, // { id: string; iccids: string[] }
    networkApi.endpoints.removeSimFromNetwork.matchFulfilled, // { iccids: string[] }
  ),
  effect: async (action: PayloadAction<SimsPayload & Record<string, any>>, listenerApi) => {
    action.payload.iccids.forEach(async (iccid) => {
      await listenerApi.dispatch(upsertSimcardThunk(iccid));
    });
  },
});

export const updateActivatedSims = createListenerMiddleware();

updateActivatedSims.startListening({
  /**
   * This middleware fetches the individual sims that need to be updated or added to the store
   * following a successful sim activation
   */
  matcher: activateSimsApi.endpoints.activateSims.matchFulfilled,
  effect: async (action: PayloadAction<ActivateSimPayload, string>, listenerApi) => {
    action.payload.sims.forEach(async (sim) => {
      await listenerApi.dispatch(upsertSimcardThunk(sim.iccid));
    });
  },
});
