import { coreApi } from '@core/rtk-api';
import { ActivityResponse, Home } from 'src/types/types';

export const homeApi = coreApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getHome: builder.query<Home, void>({ query: () => ({ url: '/home' }), providesTags: ['Home'] }),
      getActivity: builder.query<ActivityResponse, void>({ query: () => ({ url: '/activity' }) }),
    };
  },
});

export const { useGetHomeQuery, useGetActivityQuery } = homeApi;
