import React, { forwardRef, useEffect } from "react";

export const IndeterminateCheckbox = forwardRef<HTMLInputElement>(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (typeof resolvedRef === "object" && resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <div className="form-check d-flex align-items-center">
        <input
          className="form-check-input"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </div>
    );
  }
);
