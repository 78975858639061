import { FormField } from '@modules/auth';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useEmailVerified } from 'src/hooks/useConfirmEmail';
import { LoadingButtonContent } from './LoadingButtonContent';
import { BaseModal } from './v2/BaseModal';

export const ConfirmEmailUpdateModal = () => {
  const location = useLocation();

  const { email, isEmailVerified, submitConfirmation, resendVerificationCode, updateEmailAndSendCode } =
    useEmailVerified();

  const [resendIsLoading, setResendIsLoading] = useState(false);
  const [countDown, setCountDown] = useState(30);
  const [showUpdateEmailForm, setShowUpdateEmailForm] = useState(false);

  const startCountDown = async ({ action }) => {
    setResendIsLoading(true);
    setCountDown(30);
    const intervalId = setInterval(() => {
      setCountDown((prev) => prev - 1);
    }, 1000);
    await action();
    setTimeout(() => {
      setResendIsLoading(false);
      clearInterval(intervalId);
    }, 30000);
  };

  if (isEmailVerified || !location.pathname.includes('dashboard')) return null;

  return (
    <>
      {!showUpdateEmailForm ? (
        <BaseModal
          title="Confirm Email Change"
          subtitle={`A confirmation code was sent to ${email}. Enter it below to verify your email and resume using the Simbase dashboard.`}
        >
          <Formik
            initialValues={{ mfaCode: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              const result = await submitConfirmation({ mfaCode: values.mfaCode });
              if (result) {
                values.mfaCode = '';
              }
              setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormField
                  required={true}
                  value={values.mfaCode}
                  handleChange={handleChange}
                  touched={touched.mfaCode}
                  handleBlur={handleBlur}
                  error={errors.mfaCode}
                  placeholder="123456"
                  name="mfaCode"
                  type="text"
                />
                <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
                  <LoadingButtonContent buttonText="Verify Code" isLoading={isSubmitting} variant="light" />
                </Button>
                <Button
                  variant="link"
                  className="w-100"
                  disabled={resendIsLoading}
                  onClick={async () => startCountDown({ action: resendVerificationCode })}
                >
                  {resendIsLoading ? `Resend code in ${countDown} seconds` : 'Resend verification code'}
                </Button>
                <Button
                  disabled={resendIsLoading}
                  variant="link"
                  className="w-100 text-danger"
                  onClick={() => {
                    values.mfaCode = '';
                    setShowUpdateEmailForm(true);
                  }}
                >
                  Update email address
                </Button>
              </Form>
            )}
          </Formik>
        </BaseModal>
      ) : (
        <BaseModal
          title="Confirm Email Change"
          subtitle={`Enter your updated email address below to receive a verification code.`}
        >
          <Formik
            initialValues={{ email: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              setShowUpdateEmailForm(false);
              await updateEmailAndSendCode({ email: values.email });
              setSubmitting(false);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormField
                  value={values.email}
                  handleChange={handleChange}
                  touched={touched.email}
                  handleBlur={handleBlur}
                  error={errors.email}
                  placeholder="email@example.com"
                  name="email"
                  type="text"
                />
                <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
                  <LoadingButtonContent buttonText="Send Verification Code" isLoading={isSubmitting} />
                </Button>
                <Button variant="link" className="w-100 text-danger" onClick={() => setShowUpdateEmailForm(false)}>
                  Back
                </Button>
              </Form>
            )}
          </Formik>
        </BaseModal>
      )}
    </>
  );
};
