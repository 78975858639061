import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormField } from '../../../auth';

const SignInForm = ({ onSubmit, onForgetPassword }) => {
  const navigate = useNavigate();

  return (
    <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FormField
            value={values.email}
            handleChange={handleChange}
            touched={touched.email}
            handleBlur={handleBlur}
            error={errors.email}
            placeholder="name@address.com"
            name="email"
            type="email"
            label="Email Address"
          />
          <FormField
            value={values.password}
            handleChange={handleChange}
            touched={touched.password}
            handleBlur={handleBlur}
            error={errors.password}
            placeholder="Enter your password"
            name="password"
            type="password"
            label="Password"
            hasForgotPassword
            onForgetPassword={onForgetPassword}
          />
          <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
            Sign in
          </Button>
          <div className="text-center">
            <small className="text-muted text-center">
              Don't have an account yet? &nbsp;
              <span
                className="cursor-pointer btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/auth/signup');
                }}
              >
                Sign up
              </span>
              .
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
