type RadioProps = {
  label: string;
  checked: boolean;
  onClick: () => void;
  labelClassname?: string;
};

export const Radio = ({ label, labelClassname = "", checked = false, onClick = () => {} }: RadioProps) => {
  return (
    <div className="form-check" onClick={onClick}>
      <input className="form-check-input" type="radio" checked={checked} readOnly={true} />
      <label className={`form-check-label ${labelClassname}`}>{label}</label>
    </div>
  );
};

export default Radio;
