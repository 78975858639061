import { SelectBox } from '@components/Atoms/SelectBox/SelectBox';
import { Loader } from '@components/Loader';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useState } from 'react';
import { Card } from 'react-bootstrap';
import { getCurrentYearMonth } from 'src/lib/utils/getCurrentYearMonth';
import { Sim } from 'src/types/types';
import SimpleTable from '../../../../components/Organisms/SimpleTable/SimpleTable';
import { CostDetails } from '../index';

type CostReportsProps = {
  months: string[];
  dashboardParamIsProcessing: boolean;
  costs: Sim[];
  costsResponseIsFetching: boolean;
  selectedMonth: string;
  setSelectedMonth: Dispatch<SetStateAction<string>>;
};
const CostReports = ({
  months,
  dashboardParamIsProcessing,
  costs,
  costsResponseIsFetching,
  selectedMonth,
  setSelectedMonth,
}: CostReportsProps) => {
  const currentYearMonth = getCurrentYearMonth();
  const [selectedIccid, setSelectedIccid] = useState(null);

  const columns = [
    {
      Header: 'ICCID',
      accessor: 'iccid',
      Cell: ({ value }) => (
        <span
          className="cursor-pointer btn-link"
          onClick={() => {
            setSelectedIccid(value);
          }}
        >
          {value}
        </span>
      ),
    },
    { Header: 'TOTAL COST', accessor: 'cost' },
    { Header: 'SIM SUBSCRIPTION', accessor: 'cost_month' },
    { Header: 'DATA', accessor: 'cost_data' },
    { Header: 'SMS', accessor: 'cost_sms' },
    { Header: 'NETWORKING', accessor: 'cost_netw' },
  ];

  if (selectedIccid) {
    return <CostDetails month={selectedMonth} iccid={selectedIccid} handleGoBack={() => setSelectedIccid(null)} />;
  }

  if (dashboardParamIsProcessing) return <Loader />;

  return (
    <div>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h4 className="card-header-title text-capitalize">SIM report</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <small className="form-text text-muted">
            Costs listed here are individual SIM related usage costs and may exclude taxes and certain service costs.
          </small>
          <SelectBox
            defaultValue={{
              label: 'This month',
              value: currentYearMonth,
            }}
            onChange={({ value }) => setSelectedMonth(value)}
            options={months?.map((value) => ({
              label: value === currentYearMonth ? 'This month' : dayjs(value).format('MMMM YYYY'),
              value,
            }))}
          />
        </Card.Body>
      </Card>
      <div>
        {costs && <SimpleTable hasSearch columns={columns} data={costs} processing={costsResponseIsFetching} />}
      </div>
    </div>
  );
};

export default CostReports;
