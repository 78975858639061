import { getAuthenticatedUser } from "@modules/auth/utils";
import { saveAs } from "file-saver";

export const downloadFile = async (url, fetchProps) => {
  try {
    const user = await getAuthenticatedUser();
    const token = user.session.idToken.toString();

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...fetchProps,
    });

    if (!response.ok) {
      throw new Error(response.toString());
    }

    const blob = await response.blob();

    // Download the file
    saveAs(blob, "simbase_" + fetchProps.profile + ".tar");
  } catch (error) {
    console.error("Failed to download file", error);
    throw new Error(error);
  }
};
