import { toast } from "react-toastify";

export const copyToClipboard = async (text, name) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success(`${name} copied to clipboard`);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};
