import { Icon } from '@components/Atoms/Icon/Icon';
import Loader from '@components/Loader';
import { InfoToolTip } from '@components/Molecules/InfoToolTip/InfoToolTip';
import { OptionToggle } from '@components/Molecules/OptionToggle/OptionToggle';
import { IMEI_LOCK_STATE, PUBLIC_IP_STATE } from '@core/constants';
import {
  useLazyGetActivityTimelineQuery,
  useLazyGetNetworkRegistrationQuery,
  useResetNetworkMutation,
  useUpdateImeiLockMutation,
} from '@modules/dashboard/simCard/simcards-api-slice';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useIsProcessing from 'src/lib/utils/hooks/useIsProcessing';
import { ActivityTimeline as ActivityTimelineType, GetNetworkRegistrationResponse, Simcard } from 'src/types/types';
import { ActivityTimeline, InfoCard, NetworkRegistrationModal } from '../../../index';
import MapBox from '../../MapBox';
import PublicIPModal from '../../PublicIPModal';
import TrafficPolicyModal from '../../TrafficPolicyModal';

type SimcardOverviewProps = {
  trafficPolicies: string[];
  simcard: Simcard;
  networkRegistrationData: GetNetworkRegistrationResponse;
  networkRegistrationIsProcessing: boolean;
  getNetworkRegistration: ReturnType<typeof useLazyGetNetworkRegistrationQuery>[0];
  activityTimelineData: ActivityTimelineType;
  getActivityTimeline: ReturnType<typeof useLazyGetActivityTimelineQuery>[0];
};

const OverviewComponent = ({
  trafficPolicies,
  simcard,
  networkRegistrationData,
  networkRegistrationIsProcessing,
  getNetworkRegistration,
  activityTimelineData,
  getActivityTimeline,
}: SimcardOverviewProps) => {
  const { t } = useTranslation();
  const { iccid } = useParams();
  // UpdateImeiLock
  const [updateImeiLock, updateImeiLockResponse] = useUpdateImeiLockMutation();

  // ResetNetwork
  const [resetNetwork, resetNetworkResponse] = useResetNetworkMutation();

  const [showNetworkRegistrationModal, setShowNetworkRegistrationModal] = useState(false);

  const [isTrafficPolicyOpen, setIsTrafficPolicyOpen] = useState(false);
  const [isPublicIPOpen, setIsPublicIPOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (simcard?.iccid) {
      getActivityTimeline({ iccid: simcard?.iccid });
    }
  }, [getActivityTimeline, simcard?.iccid]);
  const primaryInfo = [
    {
      key: 'Connection status',
      value: (
        <>
          <span
            className={`me-1 text-${
              simcard?.in_session && simcard?.last_update.toLowerCase() !== 'no session' ? 'success' : 'danger'
            }`}
          >
            ●
          </span>
          <span>{simcard?.last_update}</span>
        </>
      ),
    },
    { key: 'Usage this month', value: simcard?.usage_data },
    { key: 'Country', value: simcard?.country },
    { key: 'Network', value: simcard?.carrier },
    { key: 'Radio Access Network', value: simcard?.radio },
  ];

  const secondaryInfo = [
    { key: 'APN', value: simcard?.apn },
    { key: 'IMEI', value: simcard?.imei },
    { key: 'Hardware', value: simcard?.hardware },
    {
      key: 'IP',
      value: simcard?.ip,
      actionButton: (
        <>
          {!simcard?.network_id &&
            simcard?.ip !== PUBLIC_IP_STATE.TERMINATING_PUBLIC_IP &&
            simcard?.ip !== PUBLIC_IP_STATE.DEPLOYING_PUBLIC_ID && (
              <Button className="btn-primary" size="sm" onClick={() => setIsPublicIPOpen(true)}>
                {' '}
                {/**  setIsPublicIPOpen(true) */}
                {simcard?.public_ip_enabled ? 'Manage Fixed IP' : 'Assign Fixed IP'}
              </Button>
            )}
          {simcard?.network_id && (
            <Button
              className="btn-primary"
              size="sm"
              onClick={() => {
                navigate(`/dashboard/private-network/${simcard?.network_id}`);
              }}
            >
              See Network Details
            </Button>
          )}
        </>
      ),
    },
    { key: 'Throttle Speed (kbps)', value: simcard?.throttle },
  ];

  const handleNetworkReset = async () => {
    try {
      await resetNetwork({ iccid }).unwrap();
      toast.success(t('simcard.networkResetSuccessfully'));
    } catch (error) {
      console.error('Error resetting network:', error);
      toast.error(error.errorMessage || t('simcard.failedToResetNetwork'));
    }
  };

  const handleRegistrationEvents = () => {
    getNetworkRegistration({ iccid });
    setShowNetworkRegistrationModal(true);
  };

  const handleTheftProtectionClick = async () => {
    try {
      await updateImeiLock({
        iccids: [iccid],
        imei_lock: simcard.imei_lock === IMEI_LOCK_STATE.OFF ? IMEI_LOCK_STATE.ON : IMEI_LOCK_STATE.OFF,
      }).unwrap();
      toast.success(t('simcard.theftProtectionSuccessfullyUpdated'));
    } catch (error) {
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  const NetworkStatusInfoBox = (
    <div className="tw-w-60">
      <span>{t('infoBox.networkStatus')}</span>
    </div>
  );

  const TrafficPolicyInfoBox = (
    <div className="tw-flex tw-w-60 tw-flex-col tw-items-center tw-gap-6">
      <span className="tw-text-sm">{t('infoBox.trafficPolicy.sectionOne')}</span>
      <span className="tw-italic">{t('infoBox.trafficPolicy.sectionTwo')}</span>
    </div>
  );

  const isImeiLockProcessing = useIsProcessing([updateImeiLockResponse.isLoading]);
  if (!simcard) return <Loader />;
  return (
    <div className="d-flex flex-column overview">
      <div className="d-flex py-3">
        <div className="w-100 pe-3">
          <InfoCard infoItems={primaryInfo} />
        </div>
        <div className="w-100 pe-3">
          <InfoCard infoItems={secondaryInfo} />
        </div>
        <div className="w-100">
          <MapBox
            countryName={simcard.country}
            coords={[simcard.lon, simcard.lat]}
            isEmpty={isNaN(simcard.lon) || isNaN(simcard.lat)}
          />{' '}
        </div>
      </div>
      <Row className="mx-0 g-5">
        {activityTimelineData?.logs && (
          <Col sm={12} md={6} className="ps-0 pe-2">
            <div className="d-flex w-100">
              <ActivityTimeline activities={activityTimelineData.logs} />
            </div>
          </Col>
        )}
        <Col sm={12} md={6} className="pe-0 ps-2">
          <div className="vstack gap-3">
            <Card className="d-flex align-self-start my-0 w-100">
              <Card.Body>
                <div className="d-flex justify-content-between h-100">
                  <div className="d-flex">
                    <div className="d-flex align-items-center pe-4">
                      <i className="fe fe-radio" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="tw-mb-2 tw-flex tw-items-center tw-gap-2">
                        <h4 className="mb-0 network-status">Network Status</h4>
                        <InfoToolTip title={NetworkStatusInfoBox}>
                          <div className="tw-flex tw-items-center">
                            <Icon name="question_info" width={14} height={14} />
                          </div>
                        </InfoToolTip>
                      </div>
                      <p className="card-text small text-muted mb-1">
                        Check your connection and reset to retry connecting to the mobile network.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center ps-2">
                    <div className="w-100">
                      <Button
                        className="w-100 mb-1 text-nowrap network-buttons"
                        variant="primary"
                        size="sm"
                        onClick={handleNetworkReset}
                      >
                        {resetNetworkResponse.isLoading ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" />
                            {t('button.isLoading')}
                          </span>
                        ) : (
                          'Reset connection'
                        )}
                      </Button>
                    </div>
                    <div className="w-100">
                      <Button
                        className="text-nowrap w-100 network-buttons"
                        variant="light"
                        size="sm"
                        onClick={handleRegistrationEvents}
                      >
                        {networkRegistrationIsProcessing ? (
                          <span>
                            <span className="spinner-border spinner-border-sm me-2" />
                            {t('button.isLoading')}
                          </span>
                        ) : (
                          t('simcard.registrationEvents')
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <OptionToggle
              title={t('simCard.theftProtectionCard.header')}
              body={t('simCard.theftProtectionCard.body')}
              value={simcard.imei_lock === IMEI_LOCK_STATE.ON}
              handleOnClick={handleTheftProtectionClick}
              disabled={isImeiLockProcessing}
              bootstrapIcon={t('simCard.theftProtectionCard.icon')}
              isLoading={isImeiLockProcessing}
            />
            <Card className="w-100 my-0">
              <Card.Body>
                <div className="d-flex justify-content-between h-100">
                  <div className="d-flex">
                    <div className="d-flex align-items-center pe-4">
                      <i className="fe fe-cloud-drizzle" />
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <div className="tw-mb-2 tw-flex tw-items-center tw-gap-2">
                        <h4 className="mb-0 traffic-policy">{t('simCard.trafficPolicyCard.header')}</h4>
                        <InfoToolTip title={TrafficPolicyInfoBox}>
                          <div className="tw-flex tw-items-center">
                            <Icon name="question_info" width={14} height={14} />
                          </div>
                        </InfoToolTip>
                      </div>
                      <p className="card-text small text-muted mb-1">
                        Throttle the data speed of SIM after usage exceed monthly defined quota.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      className="me-2 text-nowrap"
                      variant="primary"
                      size="sm"
                      onClick={() => setIsTrafficPolicyOpen(true)}
                    >
                      Set traffic policy
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <TrafficPolicyModal
        options={trafficPolicies}
        isOpen={isTrafficPolicyOpen}
        iccid={simcard.iccid}
        handleClose={() => setIsTrafficPolicyOpen(false)}
        currentPolicy={simcard.policy}
      />
      {simcard && (
        <PublicIPModal
          isOpen={isPublicIPOpen}
          handleClose={() => setIsPublicIPOpen(false)}
          iccid={iccid}
          isManage={simcard.public_ip_enabled}
          ruleFirewall={simcard.public_ip_firewall}
          imsiProfile={simcard.imsi_profile}
          simProfile={simcard.sim_profile}
        />
      )}
      {networkRegistrationData?.networkRegistration && (
        <NetworkRegistrationModal
          isOpen={showNetworkRegistrationModal}
          handleClose={() => setShowNetworkRegistrationModal(false)}
          data={networkRegistrationData.networkRegistration}
        />
      )}
    </div>
  );
};

export const Overview = React.memo(OverviewComponent);
