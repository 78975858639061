type OverviewHeaderProps = {
  title: string;
  sidebarOpen: boolean;
};
const OverviewHeader = ({ title, sidebarOpen }: OverviewHeaderProps) => (
  <div className={`mb-3 tw-mt-28 ${sidebarOpen ? `sm:tw-mt-[68px]` : `headerNavigation:tw-mt-[68px]`}`}>
    <h1 className="tw-mb-[54px] tw-py-[10px] tw-text-[32px] tw-font-bold">{title}</h1>
  </div>
);

export default OverviewHeader;
