import Loader from '@components/Loader';
import { WorldMapWrapper } from '@components/Organisms/WorldMapWrapper/WorldMapWrapper';
import cs from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { PlanWithDynamicMap } from 'src/types/types';
import { CustomDropdownToggle } from '../../components';

type OtherPlanProps = {
  plans: PlanWithDynamicMap[];
  handleComplete: (params: { plan_id: string }) => Promise<void>;
};

const INITIAL_SELECTED_PLAN: PlanWithDynamicMap = {
  map: null,
  plan_id: '',
  name: null,
  currency: null,
  coverage: null,
  mb: 'X',
  sms: 'X',
  sms_to_device: 'X',
  countries: 'X',
  day: 'X',
  currency_symbol: 'X',
  dynamicMap: {},
};

const OtherPlan: React.FC<OtherPlanProps> = React.memo(({ plans, handleComplete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PlanWithDynamicMap>(INITIAL_SELECTED_PLAN);
  const [isLoading, setIsLoading] = useState(false);

  const handleDropdownClose = React.useCallback((isOpen: boolean) => {
    if (!isOpen) {
      setIsOpen(false);
    }
  }, []);

  const handlePlanClick = React.useCallback((plan: PlanWithDynamicMap) => {
    setSelectedPlan(plan);
    setIsOpen(false);
  }, []);

  const handleSelectPlan = React.useCallback(async () => {
    if (selectedPlan?.plan_id) {
      setIsLoading(true);
      await handleComplete({ plan_id: selectedPlan.plan_id });
      setIsLoading(false);
    }
  }, [selectedPlan, handleComplete]);

  return (
    <Card className='tw-p-0" tw-flex tw-h-full tw-flex-col'>
      <Card.Body className="tw-flex tw-h-full tw-flex-col !tw-p-4 md:!tw-p-2 lg:!tw-p-4">
        <WorldMapWrapper dynamicMap={selectedPlan?.dynamicMap} />
        <PlanSelector
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleDropdownClose={handleDropdownClose}
          selectedPlan={selectedPlan}
          plans={plans}
          handlePlanClick={handlePlanClick}
        />

        <hr className="m-0" />
        <PlanDetails selectedPlan={selectedPlan} />
        <Button className="w-100" variant="primary" onClick={handleSelectPlan} disabled={!selectedPlan.name}>
          {isLoading ? <Loader size={1} className="text-white" /> : 'Select plan'}
        </Button>
      </Card.Body>
    </Card>
  );
});

const PlanSelector: React.FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleDropdownClose: (isOpen: boolean) => void;
  selectedPlan: PlanWithDynamicMap;
  plans: PlanWithDynamicMap[];
  handlePlanClick: (plan: PlanWithDynamicMap) => void;
}> = React.memo(({ isOpen, setIsOpen, handleDropdownClose, selectedPlan, plans, handlePlanClick }) => (
  <>
    <div className="row g-0 align-items-center justify-content-center">
      <div className="col-auto">
        <Dropdown show={isOpen} onToggle={handleDropdownClose} autoClose="outside">
          <Dropdown.Toggle as={CustomDropdownToggle}>
            <div className="w-100 d-flex justify-content-center">
              <span
                className={cs('display-4 text-nowrap mt-4 mb-5', {
                  'text-muted': !selectedPlan.name,
                })}
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedPlan.name || 'Select any plan'}
                <i className="fe fe-chevron-down fs-1" />
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">
            {plans.map((plan, index) => (
              <Dropdown.Item key={index} className="px-3" onClick={() => handlePlanClick(plan)}>
                {plan.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  </>
));

const PlanDetails: React.FC<{
  selectedPlan: PlanWithDynamicMap;
}> = React.memo(({ selectedPlan }) => (
  <>
    <div className="d-flex justify-content-center">
      <div className="border-end py-4 d-flex justify-content-center flex-column align-items-center w-100">
        <h6 className="text-muted mb-2">SIM SUBSCRIPTION</h6>
        <div className="fw-700 fs-2">{`${selectedPlan?.currency_symbol}${selectedPlan?.day} / day`}</div>
      </div>
      <div className="py-4 d-flex justify-content-center flex-column align-items-center w-100">
        <h6 className="text-muted mb-2">DATA RATE</h6>
        <div className="fw-700 fs-2">{`${selectedPlan?.currency_symbol}${selectedPlan?.mb} / MB`}</div>
      </div>
    </div>
    <hr className="m-0" />
    <div className="mb-3">
      <ul className="list-group list-group-flush">
        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
          <small>SMS From Device</small>
          <small>{`${selectedPlan?.currency_symbol}${selectedPlan?.sms} / SMS`}</small>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
          <small>SMS To Device</small>
          <small>{`${selectedPlan?.currency_symbol}${selectedPlan?.sms_to_device} / SMS`}</small>
        </li>
        <li className="list-group-item d-flex align-items-center justify-content-between px-0">
          <small>Countries</small>
          <small>{selectedPlan?.countries}</small>
        </li>
      </ul>
    </div>
  </>
));

export default OtherPlan;
