import { Button } from '@components/Atoms/Button/Button';
import { SelectBox } from '@components/Atoms/SelectBox';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownFilters } from 'src/types/types';

type FilterDropdownProps = {
  filters: DropdownFilters;
  updateFilter: (column: string, value: { label: string; value: string }) => void;
  resetFilters: () => void;
};

export const FilterDropdown: React.FC<FilterDropdownProps> = ({ filters, updateFilter, resetFilters }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const activeFiltersCount = filters.filter(({ selectedOption }) => Object.keys(selectedOption).length > 0).length;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="tw-relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="tw-flex tw-items-center tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-shadow-sm hover:tw-bg-gray-50 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2"
      >
        <i className="fe fe-sliders tw-mr-2" />
        {` ${t('filter')}`}
        {activeFiltersCount > 0 && (
          <span className="tw-ml-2 tw-inline-flex tw-items-center tw-rounded-full tw-bg-indigo-100 tw-px-2.5 tw-py-0.5 tw-text-xs tw-font-medium tw-text-indigo-800">
            {activeFiltersCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="tw-absolute tw-right-0 tw-z-50 tw-mt-2 tw-w-64 tw-overflow-visible tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5">
          <div className="tw-py-1">
            <div className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2">
              <span className="tw-text-sm tw-font-medium tw-text-gray-900">{t('filters')}</span>
              <Button
                size="small"
                variant="borderless"
                className="tw-text-sm tw-text-gray-700 tw-underline hover:tw-no-underline"
                onClick={resetFilters}
              >
                {t('filter.clearFilters')}
              </Button>
            </div>
            {filters.map((item, index) => (
              <div key={index} className="tw-px-4 tw-py-2">
                <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
                  <span className="tw-text-sm tw-text-gray-700">{item.name}</span>
                  <div className="tw-relative tw-w-32">
                    <SelectBox
                      key={`${item.name}-${index}-${Object.keys(item.selectedOption).length}`}
                      options={item.options}
                      onChange={(option) => updateFilter(item.column, option)}
                      value={item.selectedOption.value ? item.selectedOption : undefined}
                      className="tw-w-full"
                      menuPlacement="auto"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
