import icon from '@assets/img/iot.png';
import { Dropdown } from '@components/Dropdown';
import Loader from '@components/Loader';
import {
  useAssignSimToNetworkMutation,
  useGetAllNetworksQuery,
  useRemoveSimFromNetworkMutation,
} from '@modules/dashboard/network/network-api-slice';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const AssignToNetworkModal = forwardRef((_, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState<Record<string, string>>();
  const [iccids, setIccids] = useState<string[]>([]);

  // RemoveSimsFromNetwork
  const [removeSimFromNetwork] = useRemoveSimFromNetworkMutation();

  // AssignSimToNetwork
  const [assignSimToNetwork] = useAssignSimToNetworkMutation();

  // GetAllNetworks
  const getAllNetworkResponse = useGetAllNetworksQuery();
  const { data: networks = [] } = getAllNetworkResponse;

  // GetSims
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore linter shut up

  useImperativeHandle(ref, () => ({
    open: ({ iccids }: { iccids: string[] }) => {
      setIsOpen(true);
      setIccids(iccids);
    },
  }));

  const close = () => {
    setErrorMessage('');
    setProcessing(false);
    setIsOpen(false);
  };

  const handleAssignNetwork = async () => {
    setErrorMessage('');
    setProcessing(true);

    try {
      if (selectedNetwork.key === 'unassign') {
        await removeSimFromNetwork({ iccids }).unwrap();
        toast.success(t('simcard.simsSuccessfullyRemovedFromNetworks'));
      } else {
        await assignSimToNetwork({
          id: selectedNetwork.key,
          iccids,
        }).unwrap();
        toast.success(t('simcard.simsAssignedToXNetwork', { selectedNetwork: selectedNetwork.value }));
      }
      close();
    } catch (error) {
      console.error('Error assigning/removing network:', error);
      setErrorMessage(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    } finally {
      setProcessing(false);
    }
  };

  const handleSelectNetwork = (option) => {
    setSelectedNetwork(option);
  };

  const networkDropdownOptions = useMemo(() => {
    if (getAllNetworkResponse.isLoading) {
      return [];
    }
    return [
      ...networks.map(({ network_name, network_id }) => ({
        key: network_id,
        value: network_name || '[NO NAME]',
      })),
      {
        key: 'unassign',
        value: 'No network',
      },
    ];
  }, [getAllNetworkResponse.data]);

  return (
    <Modal centered show={isOpen} onHide={() => close()}>
      <Modal.Body>
        <div className="d-flex justify-content-between border-bottom pb-3">
          <div className="d-flex">
            <div>
              <img src={icon} alt="icon" height={40} />
            </div>
            <div className="d-flex flex-column">
              <h4 className="mb-1">Assign to Private Network</h4>
              <small className="text-muted">Secure and control your IoT deployment</small>
            </div>
          </div>
          <div>
            <button
              className="btn-close"
              onClick={() => close()}
              data-test-id="assign-to-network-modal:cancel-button"
            ></button>
          </div>
        </div>
        <div className="">
          <div className="mt-4">
            <Dropdown
              options={networkDropdownOptions}
              selected={selectedNetwork}
              onClick={handleSelectNetwork}
              formLabel="Select the network SIM cards will be assigned to"
              defaultLabel="Select Network"
              className="w-100 text-nowrap"
              loading={getAllNetworkResponse.isLoading}
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <button className="btn btn-light" onClick={() => close()}>
              Cancel
            </button>
            <button
              disabled={processing || !selectedNetwork?.key}
              className="btn btn-primary d-flex align-items-center hstack gap-2"
              data-test-id="assign-to-network-modal:submit-button"
              onClick={handleAssignNetwork}
            >
              <span>{selectedNetwork?.key === 'unassign' ? 'Unassign' : 'Assign'}</span>
              <span>{processing && <Loader variant="light" size={1} />}</span>
            </button>
          </div>
        </div>
        {errorMessage && (
          <div className="mt-3">
            <Alert dismissible onClose={() => setErrorMessage('')} variant="danger my-0">
              {errorMessage}
            </Alert>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
});
