import { Card, Col, Row } from 'react-bootstrap';
import { nanoid } from '@reduxjs/toolkit';

const Activity = ({ title, subtitle, time, icon }) => {
  return (
    <div className='list-group-item'>
      <Row>
        <Col className='col-auto'>
          <div className='avatar avatar-sm'>
            <div className='avatar-title fs-lg bg-primary-soft rounded-circle text-primary'>
              <i className={`fe fe-${icon}`} />
            </div>
          </div>
        </Col>
        <div className='col '>
          <h5 className=''>{title}</h5>
          <p className='small text-gray-700 mb-0'>{subtitle}</p>
          <small className='text-muted'>{time}</small>
        </div>
      </Row>
    </div>
  );
};

export const ActivityTimeline = ({ activities = [] }) => {
  return (
    <Card className='w-100 h-100 mb-0'>
      <Card.Header>
        <h4 className='card-header-title text-capitalize'>Activity Timeline</h4>
      </Card.Header>
      <Card.Body>
        <div className='list-group list-group-flush list-group-activity '>
          {activities.slice(0, 3).map(({ title, message, timestamp, icon }) => (
            <Activity
              key={nanoid()}
              icon={icon}
              title={title}
              subtitle={message}
              time={timestamp}
            />
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ActivityTimeline;
