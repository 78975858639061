import { Card } from "react-bootstrap";

export const InfoCard = ({ infoItems }) => (
  <Card className="w-100 h-100 mb-0 info-card">
    <Card.Body className="py-3">
      <div className="d-flex flex-column">
        {infoItems.map(({ key, value, actionButton }) => (
          <div key={key} className="d-flex justify-content-between border-bottom-not-last py-3">
            <h4 className="card-header-title align-items-center d-flex text-capitalize">{key}</h4>
            <div className="d-flex">
              <div className="me-3">{actionButton}</div>
              <span className={` ${key} text-muted d-flex align-items-center`}>{value}</span>
            </div>
          </div>
        ))}
      </div>
    </Card.Body>
  </Card>
);

export default InfoCard;
