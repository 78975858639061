import { ToggleButton } from '@components/Atoms/ToggleButton/ToggleButton';
import ConfirmationModal from '@components/v2/ConfirmationModal';
import { fetchMFAPreference, updateMFAPreference } from 'aws-amplify/auth';
// import { CognitoUser } from "amazon-cognito-identity-js";
// import { Auth } from "aws-amplify";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QrCodeModal } from './QrCodeModal';

const MFA_STATES = {
  ENABLED: 'TOTP',
  DISABLED: 'DISABLED',
};

export const MfaToggle = ({ label = null }: { label?: string | null }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);

  const isMfaEnabled = async () => {
    try {
      const { preferred } = await fetchMFAPreference();
      setMfaEnabled(preferred === MFA_STATES.ENABLED);
    } catch (error) {
      console.error('Error fetching MFA preference:', error);
      toast.error(t('errorMessage.somethingWentWrongGetInTouch'));
    }
  };

  useEffect(() => {
    isMfaEnabled();
  }, []);

  const disableMfaModalRef = useRef(null);
  const verifyMfaModalRef = useRef(null);

  const disableMfa = async () => {
    try {
      await updateMFAPreference({ totp: 'DISABLED' });
      toast.success(t('mfa.toggle.successfullyDisabled'));
      setMfaEnabled(false);
    } catch (error) {
      console.error('Error disabling MFA', error);
      toast.error(t('mfa.toggle.failedToDisable'));
    }
  };

  const handleUpdateMfaPreference = async (requestedState: boolean): Promise<void> => {
    setIsLoading(true);
    if (requestedState) {
      verifyMfaModalRef.current.open();
    } else {
      disableMfaModalRef.current.open();
    }
    setIsLoading(false);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <ToggleButton
        handleChange={() => handleUpdateMfaPreference(!mfaEnabled)}
        value={mfaEnabled}
        disabled={isLoading}
        label={label}
      />
      <QrCodeModal ref={verifyMfaModalRef} setMfaEnabled={setMfaEnabled} />
      <ConfirmationModal
        ref={disableMfaModalRef}
        confirmCallback={disableMfa}
        title="Disable MFA"
        subtitle="Are you sure you want to disable MFA? You will be logged out. "
      />
    </div>
  );
};
