import ErrorMessage from '@components/ErrorMessage';
import { LoadingWrapper } from '@components/LoadingWrapper';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Payments as PaymentsType } from 'src/types/types';
import SimpleTable from '../../../../components/Organisms/SimpleTable/SimpleTable';

type PaymentsProps = {
  preferredTime: string;
  payments: PaymentsType[];
  paymentsResponseIsProcessing: boolean;
  paymentsResponseErrorMessage: string;
  setpaymentsResponseErrorMessage: Dispatch<SetStateAction<string>>;
};

const Payments = ({
  preferredTime,
  payments,
  paymentsResponseIsProcessing,
  paymentsResponseErrorMessage,
  setpaymentsResponseErrorMessage,
}: PaymentsProps) => {
  const { t } = useTranslation();

  const columns = [
    {
      Header: 'INVOICE ID',
      accessor: 'invoice_id',
      isVisible: false,
    },
    {
      Header: 'EVENT DATE',
      accessor: 'timestamp',
      extraInfo: t('timezone.extraInfo', { preferredTime: preferredTime }),
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
    },
    {
      Header: 'PAYMENT METHOD',
      accessor: 'type',
    },
  ];

  if (payments === null) {
    return <ErrorMessage message={paymentsResponseErrorMessage} onClose={() => setpaymentsResponseErrorMessage('')} />;
  }

  return (
    <div>
      <LoadingWrapper isLoading={paymentsResponseIsProcessing}>
        <SimpleTable title="Payments" data={payments} columns={columns} hasPerPage={false} hasExport={false} />
      </LoadingWrapper>
    </div>
  );
};

export default Payments;
