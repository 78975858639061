import { Alert } from "react-bootstrap";

const ErrorMessage = ({
  message = "",
  dismissible = true,
  className = "",
  onClose,
  alertClassname = "",
}) => {
  if (!message) {
    return null;
  }

  return (
    <div className={`my-3 ${className}`}>
      <Alert className={alertClassname} dismissible={dismissible} variant="danger" onClose={onClose}>
        {message}
      </Alert>
    </div>
  );
};

export default ErrorMessage;
