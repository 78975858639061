import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { FC, ReactNode } from 'react';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 10px',
    borderRadius: '5px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const TitleComponent: FC<{ children: ReactNode }> = ({ children }) => {
  return <>{children}</>;
};

type InfoToolTipProps = Omit<TooltipProps, 'title'> & {
  title: ReactNode;
};

export const InfoToolTip: FC<InfoToolTipProps> = ({ title, children, placement = 'bottom', ...rest }) => {
  return (
    <StyledTooltip title={<TitleComponent>{title}</TitleComponent>} placement={placement} arrow {...rest}>
      {children}
    </StyledTooltip>
  );
};
