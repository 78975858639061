import { getAuthenticatedUser } from "@modules/auth/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type EmailVerifiedState = {
  isEmailVerified: boolean;
  email: string;
  name?: string;
};

const initialState: EmailVerifiedState = {
  isEmailVerified: true,
  email: "",
  name: "",
};

export const checkCognitoEmailVerified = createAsyncThunk("emailVerified/checkCognitoEmailVerified", async () => {
  const { email, emailVerified, name } = await getAuthenticatedUser(true);
  return { email, emailVerified, name };
});

const emailVerifiedSlice = createSlice({
  name: "emailVerified",
  initialState,
  reducers: {
    updateEmail: (state, action) => {
      state.email = action.payload.email;
    },
    verifymEmail: (state) => {
      state.isEmailVerified = true;
    },
    unverifymEmail: (state) => {
      state.isEmailVerified = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCognitoEmailVerified.fulfilled, (state, action) => {
      state.isEmailVerified = action.payload.emailVerified;
      state.email = action.payload.email;
      state.name = action.payload.name;
    });
  },
});

export const { verifymEmail, unverifymEmail } = emailVerifiedSlice.actions;
export default emailVerifiedSlice;
