import { SubscriptionsActivate } from '@components/Organisms/SubscriptionsActivate/SubscriptionsActivate';
import { SubscriptionsBusinessPlan } from '@components/Organisms/SubscriptionsBusinessPlan/SubscriptionsBusinessPlan';
import { SubscriptionsIntro } from '@components/Organisms/SubscriptionsIntro/SubscriptionsIntro';
import { useGetPricingQuery } from '@modules/dashboard/network/network-api-slice';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, SetURLSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BusinessSubscription, Customer, UpdateSubscriptionArgs } from 'src/types/types';
import { Loader } from '../../../../components';
import { useUpdateSubscriptionMutation } from '../account-api-slice';

const BUSINESS_PLAN = {
  INTRO: 'intro',
  ACTIVATE: 'activate',
};

type SubscriptionsProps = {
  customer: Customer | undefined;
  subscriptionsResponseIsLoading: boolean;
  setSearchParams: SetURLSearchParams;
  searchParams: URLSearchParams;
};

const BusinessPlans = ['buseu', 'busus', 'busoc', 'busglobal'];

const Subscriptions: FC<SubscriptionsProps> = ({
  customer,
  subscriptionsResponseIsLoading,
  setSearchParams,
  searchParams,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    setSearchParams((prev) => {
      prev.set('subscription', customer?.customerDetails?.subscriptions?.active?.sku || 'selfservice');
      return prev;
    });
  }, [customer?.customerDetails?.subscriptions?.active]);

  const [step, setStep] = useState(BUSINESS_PLAN.INTRO);

  const getPricing = useGetPricingQuery();

  const { coverage_plan: coveragePlan, currency_symbol: currencySymbol } = getPricing?.data ?? {};

  const business_plan = getPricing?.data?.products.find((item) => item.product_type === 'business_plan');
  const {
    price: businessPlanDailyRate,
    data_rate: businessPlanDataRate,
    discount_rate: businessPlanDiscountRate,
  } = business_plan ?? {};

  const [updateSubscription, updateSubscriptionResponse] = useUpdateSubscriptionMutation();

  const handleUpdateSubscription = async (args: UpdateSubscriptionArgs) => {
    try {
      await updateSubscription(args).unwrap();
      toast.success(t('subscriptions.subscriptionUpdatedSuccessfully'));
    } catch (error) {
      const errorMessage = error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch');
      toast.error(errorMessage);
    }
  };

  if (subscriptionsResponseIsLoading) {
    return <Loader />;
  }

  const subscription = searchParams.get('subscription');

  return (
    <>
      {subscription === 'selfservice' && (
        <>
          {step === BUSINESS_PLAN.INTRO && <SubscriptionsIntro setStep={() => setStep(BUSINESS_PLAN.ACTIVATE)} />}
          {step === BUSINESS_PLAN.ACTIVATE && (
            <SubscriptionsActivate
              coveragePlan={coveragePlan}
              currencySymbol={currencySymbol}
              businessPlanDailyRate={businessPlanDailyRate}
              updateSubscription={handleUpdateSubscription}
              businessPlanDataRate={businessPlanDataRate}
              updateSubscriptionResponseIsLoading={updateSubscriptionResponse.isLoading}
              subscription={customer?.customerDetails?.subscriptions}
              businessPlanDiscountRate={businessPlanDiscountRate}
            />
          )}
        </>
      )}
      {BusinessPlans.includes(subscription) && (
        <SubscriptionsBusinessPlan
          subscription={customer?.customerDetails?.subscriptions?.active as BusinessSubscription}
          updateSubscription={handleUpdateSubscription}
        />
      )}
      {['legacy_business_plan', 'custom'].includes(subscription) && (
        <div className="tw-flex tw-h-[450px] tw-flex-col tw-gap-4">
          <div className="tw-flex tw-w-full">
            <div className="tw-w-full tw-rounded-lg tw-bg-white tw-shadow-lg">
              <div className="tw-flex tw-items-center tw-justify-between tw-p-4">
                <div className="tw-flex tw-flex-col">
                  <p className="tw-my-auto tw-text-center tw-text-xl">
                    This account already has an active Business Plan subscription. To update its status please{' '}
                    <Link to="https://www.simbase.com/support-request">contact support</Link>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Subscriptions;
