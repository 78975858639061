import { useTranslation } from 'react-i18next';
import { Icon } from '../Icon/Icon';

export const IconLinkCard = ({
  gap,
  paddingTop,
  paddingBottom,
  icon,
  iconHeight,
  iconWidth,
  text,
  href,
  className = '',
  dataTestid,
}: {
  gap: string;
  paddingTop: string;
  paddingBottom: string;
  icon: string;
  iconHeight: number;
  iconWidth: number;
  text: string;
  href: string;
  className?: string;
  dataTestid?: string;
}) => {
  const { t } = useTranslation();
  const classes = [
    'tw-flex',
    'tw-flex-grow',
    'tw-flex-col',
    'tw-items-center',
    'tw-justify-center',
    'tw-rounded-xl',
    'tw-bg-white',
    `tw-pt-${paddingTop}`,
    `tw-gap-${gap}`,
    `tw-pb-${paddingBottom}`,
    'tw-border',
    'tw-border-solid',
    'tw-border-sb-blue-grey-300',
  ].join(' ');

  return (
    <a data-testid={dataTestid} target="_blank" className={`${classes} ${className}`} href={href} aria-label={text}>
      <div>
        <Icon name={icon} width={iconWidth} height={iconHeight} />
      </div>
      <span className="tw-text-sb-blue-500 tw-text-center tw-text-base tw-font-semibold">{t(text)}</span>
    </a>
  );
};
