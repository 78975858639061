import logo from "@assets/img/logo-notext.png";

export const ErrorPage = () => {
  return (
    <div className="bg-light">
      <div className="container vh-100  ">
        <div className="vh-100 fs-2">
          <div className="vh-100 d-flex align-items-center">
            <div className="w-100 border rounded-3 pt-5 pb-2 shadow bg-white">
              <div className="w-100 d-flex justify-content-center mb-5">
                <img src={logo} width="70px" alt="logo" />
              </div>
              <p className=" display-4 fw-bold text-center mb-4">There was an unexpected error</p>
              <hr />
              <span className="w-100 d-flex justify-content-center mt-4">
                <div>
                  <p className="text-center text-muted fst-italic">Our team has already been notified, however</p>
                  <ul>
                    <li>
                      <span className="bold-p">If your issue is urgent</span> contact our customer support via{" "}
                      <a href="https://www.simbase.com/support-request">this form</a>.
                    </li>
                    <li>
                      Otherwise <a href="/dashboard">reload the dashboard</a>.
                    </li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
