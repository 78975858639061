export const handleCreatePermissions = (scopes: string[], transformedPermissions) => {
  const allResources = scopes.filter((item) => item.split(':')[0] === '*');

  const setChildPermissions = (children, parentValue) => {
    const newPermissions = {};
    children.forEach((child) => {
      if (parentValue === '*') {
        newPermissions[child.name] = ['Write', 'Read'];
      } else if (parentValue === 'read') {
        newPermissions[child.name] = child.options.includes('read') ? ['Read'] : ['None'];
      } else if (parentValue === '*' || parentValue.join('') === 'Write' || parentValue.join('') === 'WriteRead') {
        newPermissions[child.name] = ['Write', 'Read'];
      } else if (parentValue === 'read' || parentValue.join('') === 'Read') {
        newPermissions[child.name] = child.options.includes('read') ? ['Read'] : ['None'];
      } else {
        newPermissions[child.name] = ['None'];
      }
      if (child.children.length > 0) {
        Object.assign(newPermissions, setChildPermissions(child.children, newPermissions[child.name]));
      }
    });
    return newPermissions;
  };

  const processScopesForChildren = (children, scopesMap) => {
    const newPermissions = {};
    children.forEach((child) => {
      if (child.children.length > 0) {
        if (scopesMap[child.name]) {
          newPermissions[child.name] = scopesMap[child.name] === '*' ? ['Write', 'Read'] : ['Read'];
          Object.assign(newPermissions, setChildPermissions(child.children, newPermissions[child.name]));
        } else {
          newPermissions[child.name] = 'null';
          Object.assign(newPermissions, processScopesForChildren(child.children, scopesMap));
        }
      } else {
        newPermissions[child.name] = scopesMap[child.name]
          ? scopesMap[child.name] === '*'
            ? ['Write', 'Read']
            : ['Read']
          : ['None'];
      }
    });
    return newPermissions;
  };

  if (allResources.length) {
    return {
      allResources: allResources[0].split(':')[1] === '*' ? ['Write', 'Read'] : ['Read'],
      ...setChildPermissions(transformedPermissions.children, allResources[0].split(':')[1]),
    };
  } else {
    const scopesMap = scopes.reduce((acc, scope) => {
      const [resource, permission] = scope.split(':');
      acc[resource] = permission;
      return acc;
    }, {});

    return {
      allResources: 'null',
      ...processScopesForChildren(transformedPermissions.children, scopesMap),
    };
  }
};
