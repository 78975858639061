import React, { useState } from "react";
import { Dropdown as BSDropdown, Form, FormControl, InputGroup } from "react-bootstrap";

import Loader from "../Loader";

const CustomToggle = React.forwardRef(({ onClick, value, isChevronDown, formLabel, noMargins, defaultLabel }, ref) => (
  <Form.Group className="position-relative" ref={ref}>
    <Form.Label>{formLabel}</Form.Label>
    <InputGroup
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="p-0 position-relative"
    >
      <FormControl
        value={value}
        type="string"
        readOnly
        className="cursor-pointer select-border-radius"
        placeholder={defaultLabel}
      />
      <div onClick={onClick} className="ip-dropdown-chevron ">
        <i className={`fe fe-chevron-${isChevronDown ? "down" : "up"}`} />
      </div>
    </InputGroup>
  </Form.Group>
));

export const Dropdown = ({
  options,
  selected = {},
  onClick,
  defaultLabel = "Choose option",
  formLabel = "Dropdown",
  className,
  noMargins = false,
  loading = false,
}) => {
  const [isChevronDown, setIsChevronDown] = useState(true);

  return (
    <BSDropdown onToggle={(isVisible) => setIsChevronDown(!isVisible)} className={className}>
      <BSDropdown.Toggle
        as={CustomToggle}
        value={selected.value || ''}
        defaultLabel={defaultLabel}
        isChevronDown={isChevronDown}
        noMargins={noMargins}
        formLabel={formLabel}
      />
      <BSDropdown.Menu
        className="h-auto overflow-auto"
        style={{
          maxHeight: 330,
          width: "100%",
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          options.map(({ key, value }, i) => (
            <BSDropdown.Item
              active
              className={!key ? "bg-light" : ""}
              key={i}
              onClick={() => onClick({ key, value })}
              eventKey={i}
            >
              {value}
            </BSDropdown.Item>
          ))
        )}
      </BSDropdown.Menu>
    </BSDropdown>
  );
};
