import { LoadingButtonContent } from "@components/LoadingButtonContent";
import ConfirmationModal from "@components/v2/ConfirmationModal";
import { Checkbox, CustomDropdownToggle } from "@modules/dashboard/components";
import { useCreateTagMutation, useDeleteTagMutation } from "@modules/dashboard/simCard/simcards-api-slice";
import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, FormControl, InputGroup } from "react-bootstrap";
import { useTags } from "src/hooks/useTags";
import { Loader } from "..";

export type Checkmark = {
  label: string;
  checked: boolean;
  indeterminate: boolean;
  action: "add" | "remove" | "none";
  loading: boolean;
};

type ITagDropdown = {
  applyTags: (tagsToAdd: string[], tagsToRemove: string[]) => Promise<void>;
  checkmarks: Checkmark[];
  setCheckmarks: any;
  customDropdownToggle?: (onClick: () => void) => JSX.Element;
};

const TagDropdown = ({ applyTags, checkmarks, setCheckmarks, customDropdownToggle }: ITagDropdown) => {
  const { tags, getTagsResponse } = useTags();
  const [deleteTag] = useDeleteTagMutation();
  const [createTag, getCreateTagResponse] = useCreateTagMutation();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showCreateButton, setShowCreateButton] = useState(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  const [tagToDelete, setTagToDelete] = useState("");

  const handleClick = (label, index) => {
    const newCheckmark: Checkmark = {
      label,
      checked: !checkmarks[index]?.checked,
      indeterminate: false,
      action: checkmarks[index]?.checked ? "remove" : "add",
      loading: false,
    };
    setCheckmarks((checkmarks) => checkmarks.map((checkmark, i) => (i === index ? newCheckmark : checkmark)));
  };

  /**
   * This function reviews the changes made to the checkmarks and returns two lists:
   * 1. A list of tags to add
   * 2. A list of tags to remove
   */
  const prepTags = async () => {
    const tagsToAdd: string[] = [];
    const tagsToRemove: string[] = [];
    checkmarks.forEach((change) => {
      if (change.action === "add") {
        tagsToAdd.push(change.label);
      } else if (change.action === "remove") {
        tagsToRemove.push(change.label);
      }
    });

    setCheckmarks((checkmarks) =>
      checkmarks.map((checkmark) => {
        return {
          ...checkmark,
          loading: [...tagsToAdd, ...tagsToRemove].includes(checkmark.label) ? true : false,
        };
      }),
    );
    applyTags(tagsToAdd, tagsToRemove);
    setIsOpen(false);
  };

  /**
   * Shows the create button but disables it if a tag with the same name already exists
   */
  useEffect(() => {
    if (search.length > 0) {
      if (tags.some((tag) => tag.toLowerCase() === search.toLowerCase())) {
        setIsCreateButtonDisabled(true);
      } else {
        setIsCreateButtonDisabled(false);
      }
      setShowCreateButton(true);
    } else {
      setShowCreateButton(false);
    }
  }, [search]);

  const confirmDeleteTagModalRef = useRef(null);

  return (
    <>
      <Dropdown show={isOpen} onToggle={() => setIsOpen(!isOpen)} autoClose="outside">
        <Dropdown.Toggle as={CustomDropdownToggle}>
          {customDropdownToggle ? (
            customDropdownToggle(() => setIsOpen(!isOpen))
          ) : (
            <Button
              variant="light"
              className="me-2 p-2"
              onClick={() => setIsOpen(!isOpen)}
              data-test-id="simcards-table:tag-dropdown:toggle"
            >
              <div className="d-flex align-items-center">Assign tags</div>
            </Button>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ width: 180 }}>
          <Dropdown.Item className="px-3">
            <InputGroup className="p-0">
              <FormControl
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                data-test-id="tag-dropdown:search-and-create-input"
              />
            </InputGroup>
          </Dropdown.Item>
          <div className="px-3 py-2">
            {getTagsResponse.isSuccess ? (
              tags?.map(
                (tag, index) =>
                  (checkmarks[index]?.label.toLowerCase().includes(search.toLowerCase()) || search === "") && (
                    <Checkbox
                      key={index}
                      isLoading={checkmarks[index]?.loading}
                      label={checkmarks[index]?.label}
                      checked={checkmarks[index]?.checked}
                      indeterminate={checkmarks[index]?.indeterminate}
                      onClick={(label) => handleClick(label, index)}
                      hasAction={true}
                      actionIcon={<i className="fe fe-trash-2 text-muted" />}
                      handleAction={(e) => {
                        e.stopPropagation();
                        setTagToDelete(tag);
                        confirmDeleteTagModalRef.current.open();
                      }}
                      actionProps={{ "data-test-id": `tag-dropdown:delete-tag-button-${tag}` }}
                      data-test-id={`tag-dropdown:tag-checkbox-${tag}`}
                    />
                  ),
              )
            ) : (
              <Loader />
            )}
          </div>
          {showCreateButton && (
            <>
              <Dropdown.Item
                className=""
                data-test-id="tag-dropdown:create-tag-button"
                disabled={isCreateButtonDisabled || getCreateTagResponse.isLoading || getTagsResponse.isFetching}
                onClick={async () => await createTag({ tag: search })}
              >
                <LoadingButtonContent
                  buttonText="Create new"
                  isLoading={getCreateTagResponse.isLoading || getTagsResponse.isFetching}
                />
              </Dropdown.Item>
            </>
          )}
          {!showCreateButton && (
            <Dropdown.Item
              className=""
              disabled={Object.values(checkmarks).some((checkmark) => checkmark.action !== "none") ? false : true}
              onClick={() => prepTags()}
              data-test-id="tag-dropdown:apply-selected-tags"
            >
              <span>Apply</span>
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmationModal
        ref={confirmDeleteTagModalRef}
        title="Delete tag"
        subtitle={`Are you sure you want to delete ${tagToDelete}?`}
        confirmCallback={async () => await deleteTag({ tag: tagToDelete })}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
      />
    </>
  );
};

export default TagDropdown;
