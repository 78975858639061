import { coreApi } from '@core/rtk-api';
import { simcardsSlice } from '@modules/dashboard/simCard/simcards-slice';
import { signOut } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customerIoResetIdentify } from 'src/lib/utils/CustomerIo/customerIoUtils';
import { removeItem } from 'src/lib/utils/localStorage';

export const useSignout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const forceSignout = async () => {
    navigate('/auth');
    removeItem('simModalOpen');
    signOut();
    dispatch(coreApi.util.resetApiState());
    dispatch(simcardsSlice.actions.reset());
    customerIoResetIdentify();
  };

  return { forceSignout };
};
