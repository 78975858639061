import Loader from '@components/Loader';
import { Button, Card } from 'react-bootstrap';
import { Countries } from 'src/types/types';
import { useGetBillingBalanceQuery } from '../../billing-api-slice';

const AccountBalanceCard = ({ onConfirm, countries }: { onConfirm: (boolean) => void; countries: Countries }) => {
  const balanceResponse = useGetBillingBalanceQuery();
  const { currency_symbol: currencySymbol, balance } = balanceResponse.data ?? {};
  return (
    <Card className="w-100 me-4">
      <Card.Header>
        <h4 className="card-header-title text-capitalize">Account Balance</h4>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="fw-700 fs-2">Current balance</div>
          <div className={`display-5 mb-0 mt-8px text-${balance > 0 ? 'success' : 'danger'}`}>
            {balanceResponse.isLoading ? <Loader /> : `${currencySymbol} ${balance}`}
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">Add a one-time balance recharge</div>
          <Button className="text-nowrap" onClick={() => onConfirm(true)} disabled={!countries}>
            Add Balance
          </Button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default AccountBalanceCard;
