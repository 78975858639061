import { SimcardsListViewItem } from "@modules/dashboard/simCard/simcards-slice";
import { Table } from "@tanstack/react-table";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { utils, write } from "xlsx";

export const ExportButton = ({ table }: { table: Table<SimcardsListViewItem> }) => {
  const rows =
    table.getSelectedRowModel().rows.length > 0
      ? table.getSelectedRowModel().flatRows
      : table.getState().columnFilters?.length > 0 || table.getState().globalFilter
      ? table.getFilteredRowModel().flatRows
      : table.getCoreRowModel().flatRows;

  return (
    <div className=" d-flex justify-content-center">
      <DropdownButton
        variant="outline-primary"
        className="d-flex mx-auto "
        title={
          <span className=" w-100 ">
            <i className="fe fe-download" />
            <span className="text-center">
              &nbsp;Export {rows.length} {rows.length === 1 ? "SIM" : "SIMs"}
            </span>
          </span>
        }
      >
        <Dropdown.Item
          disabled={rows.length === 0}
          onClick={() => {
            // flattening tags
            const values = rows.map((row) => ({ ...row.original, tags: row.original.tags?.join(", ") }));

            // Excel Export
            const worksheet = utils.json_to_sheet(values);

            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Create a buffer
            const buf = write(workbook, { bookType: "xlsx", type: "buffer" });

            // Save to file
            saveAs(new Blob([buf], { type: "application/octet-stream" }), "data.xlsx");
          }}
        >
          .xlsx
        </Dropdown.Item>
        <Dropdown.Item
          disabled={rows.length === 0}
          onClick={() => {
            const values = rows.map((row) => row.original);
            // CSV Export
            const csv = Papa.unparse(values);

            // Create a Blob containing the CSV data
            const csvBlob = new Blob([csv], { type: "text/csv" });

            // Create a URL for the Blob
            const csvUrl = URL.createObjectURL(csvBlob);

            // Create an invisible link element to trigger the download
            const link = document.createElement("a");
            link.href = csvUrl;
            link.download = "product_data.csv";

            link.click();

            // Clean up by revoking the URL to release resources
            URL.revokeObjectURL(csvUrl);
          }}
        >
          .csv
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};
