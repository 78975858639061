import Loader from '@components/Loader';
// import { fetchDevices } from "aws-amplify/auth";
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForgetMfaDeviceMutation } from '../../account-api-slice';
import { MfaToggle } from './MfaToggle';

export const MfaSection = () => {
  // The AWS Amplify SDK v6 is buggy and doesn't store the remembered devices correctly. Putting the feature on pause until further developments.

  // const [devices, setDevices] = useState<{ id: string; name: string }[]>([]);
  // useEffect(() => {
  //   (async () => {
  //     const devices = await fetchDevices();
  //     // setDevices(devices);
  //   })();
  // }, []);
  // const deviceListModalRef = useRef(null);

  return (
    <Row>
      <Col>
        <h2 className="text-capitalize mb-1 hstack gap-2">
          <span>Multi-Factor Authentication</span>
        </h2>
        <div className="small text-muted mb-0">
          <p>
            Multi-factor authentication (MFA) is a security feature that requires users to enter a second authentication
            code to access their account.
          </p>
          {/* <DeviceListModal devices={devices} ref={deviceListModalRef} setDevices={setDevices} /> */}
        </div>
      </Col>
      <Col className="d-flex align-items-center justify-content-center">
        <div>
          <MfaToggle />
          {/* {devices.length > 0 && (
            <button onClick={() => deviceListModalRef.current.open()} className="btn btn-link mt-2 fs-5">
              Manage devices
            </button>
          )} */}
        </div>
      </Col>
    </Row>
  );
};

export const DeviceListModal = forwardRef(
  (
    {
      devices,
      setDevices,
    }: {
      devices: { id: string; name: string }[];
      setDevices: (devices: { id: string; name: string }[]) => void;
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    useImperativeHandle(ref, () => {
      return {
        open: () => {
          setIsOpen(true);
        },
      };
    });
    const { t } = useTranslation();
    const [forgetMfaDevice] = useForgetMfaDeviceMutation();
    const handleForgetDevice = async (device_id: string) => {
      try {
        await forgetMfaDevice({ device_id }).unwrap();
        const cleanedDevices = devices.filter((device) => device.id !== device_id);
        setDevices(cleanedDevices);
        if (cleanedDevices.length === 0) setIsOpen(false);
        toast.success(t('mfa.deviceForgottenSuccess'));
      } catch (error) {
        console.error('Error forgetting MFA device:', error);
        toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
      }
    };

    return (
      <>
        <Modal centered show={isOpen} backdrop="static" onHide={() => setIsOpen(false)}>
          <Modal.Header className="h3 mb-0" closeButton>
            <span>Your Registered MFA Devices</span>
          </Modal.Header>
          <p className="fs-5 text-muted px-4 pt-2">
            If MFA is activated, you won't be prompted when you access the Simbase dashboard from one of these devices.
          </p>

          <Modal.Body className="pt-3">
            <div className="vstack gap-2">
              {devices.map((device, index) => {
                return (
                  <div key={device.id}>
                    <Device deviceId={device.id} deviceName={device.name} handleForgetDevice={handleForgetDevice} />
                    {index !== devices.length - 1 && <hr />}
                  </div>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  },
);

const Device = ({
  deviceName,
  deviceId,
  handleForgetDevice,
}: {
  deviceName: string;
  deviceId: string;
  handleForgetDevice: (deviceId: string) => void;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClick = async () => {
    if (window.confirm(t('mfa.confirmForgetDevice'))) {
      setIsLoading(true);
      try {
        await handleForgetDevice(deviceId);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="py-1 d-flex align-items-center">
      <span className="text-muted">{deviceName}</span>
      <button
        disabled={isLoading}
        className="btn btn-outline-danger d-flex align-items-center ms-3"
        onClick={handleClick}
      >
        <span className="small text-nowrap">Forget Device</span>
        <span className="ms-2">{isLoading && <Loader size={1} variant="danger" />}</span>
      </button>
    </div>
  );
};
