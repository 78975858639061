import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Loader } from "..";

interface IConfirmationModalV2 {
  title?: string;
  subtitle?: string;
  confirmCallback?: any;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ConfirmationModal = forwardRef(
  (
    {
      title = "Confirm action",
      subtitle = "",
      confirmCallback,
      cancelButtonText = "Cancel",
      confirmButtonText = "Confirm",
    }: IConfirmationModalV2,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useImperativeHandle(ref, () => {
      return {
        open: () => {
          setIsOpen(true);
        },
        close: () => {
          setIsOpen(false);
        },
      };
    });

    const handleConfirm = async () => {
      setIsLoading(true);
      await confirmCallback();
      setIsOpen(false);
      setIsLoading(false);
    };
    return (
      <Modal dialogClassName="w-35" border centered show={isOpen} onHide={() => setIsOpen(false)}>
        <Modal.Header>
          <h3 className="header-title fw-600 ">{title}</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="fs-4 ">{subtitle}</div>
            <div className="d-flex justify-content-between mt-4">
              <div className="w-45">
                <Button className="w-100" variant="secondary" onClick={() => setIsOpen(false)}
                data-test-id="confirmation-modal:cancel-button">
                  {cancelButtonText}
                </Button>
              </div>
              <div className="w-45">
                <Button
                  className="w-100 d-flex justify-content-center align-items-center hstack gap-2"
                  variant="danger"
                  type="submit"
                  disabled={isLoading}
                  onClick={handleConfirm}
                  data-test-id="confirmation-modal:confirm-button"
                >
                  <span>{confirmButtonText}</span>
                  <span>{isLoading && <Loader size={1} variant="light" />}</span>
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  },
);

export default ConfirmationModal;
