import { ExportToCsv } from 'export-to-csv';

export const handleExport = (data: any[]) => {
  const csvExporter = new ExportToCsv({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });
  if (data.length) {
    csvExporter.generateCsv(data);
  }
};

export const showTooltip = (
  setTooltipVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  columnId: string,
) => {
  setTooltipVisibility((prev) => ({ ...prev, [columnId]: true }));
};

export const hideTooltip = (
  setTooltipVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  columnId: string,
) => {
  setTooltipVisibility((prev) => ({ ...prev, [columnId]: false }));
};
