import React from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';

const SimInputRow = ({ index, iccid, serialNumber, handleDelete, label }) => {
  return (
    <div className='d-flex mb-4'>
      <FloatingLabel controlId='iccid' label={label} className='me-3 w-75'>
        <Form.Control type='text' placeholder={label} value={iccid} readOnly />
      </FloatingLabel>
      <FloatingLabel
        controlId='serialNumber'
        label='SERIAL NUMBER'
        className='text-nowrap'
      >
        <Form.Control
          type='text'
          placeholder='SERIAL NUMBER'
          readOnly
          value={serialNumber}
        />
      </FloatingLabel>
      <div className='d-flex justify-content-center align-items-center px-1 me-n5'>
        <Button
          className='d-flex text-nowrap mx-2'
          onClick={() => handleDelete(index)}
        >
          Remove SIM
        </Button>
      </div>
    </div>
  );
};

export default SimInputRow;
