import autoRecharge from "@assets/img/autoRecharge.png";
import Loader from "@components/Loader";
import { useRef } from "react";
import { Button, Card } from "react-bootstrap";
import { useGetBillingBalanceQuery } from "../../billing-api-slice";
import { useAutoTopUp } from "../../hooks/useAutoTopUp";
import { usePaymentMethods } from "../../hooks/usePaymentMethods";
import AutoRecharge from "../modals/AutoRecharge";

const AutoRechargeCard = () => {
  const { paymentMethods } = usePaymentMethods();
  const { isTopUpFetchSuccess, autoTopUp } = useAutoTopUp();
  const balanceResponse = useGetBillingBalanceQuery();
  const { currency_symbol: currencySymbol } = balanceResponse.data ?? {}; // renaming vars to fit current code


  const autoTopupModalRef = useRef(null);
  return (
    <>
      <Card className="w-100 me-4">
        <Card.Header>
          <h4 className="card-header-title text-capitalize">Auto Top-up</h4>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center h-100">
            <div className="me-3">
              <img src={autoRecharge} alt="auto-recharge" height={64} />
            </div>
            <div className="d-flex flex-column justify-content-between mx-3 h-100">
              <div className="text-body">
                Enable Auto Top-up to automatically add balance to your account should it drop below a set threshold.
              </div>
              {!paymentMethods.find(({ preferred = false }) => preferred === true) && (
                <strong className="fs-5">To use this feature you need to set a default payment method</strong>
              )}
              {isTopUpFetchSuccess ? (
                <div className="mt-1">
                  {autoTopUp ? (
                    <span>
                      <span className="me-1 text-success">●</span>
                      Enabled
                    </span>
                  ) : (
                    <span>
                      <span className="me-1 text-danger">●</span>
                      Disabled
                    </span>
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div className="ms-3">
              <Button
                disabled={!autoTopUp && !paymentMethods.length} // find(({ preferred = false }) => preferred === true)
                onClick={() => {
                  autoTopupModalRef.current?.open();
                }}
                size="sm"
              >
                {!autoTopUp ? "Enable" : "Disable"}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <AutoRecharge ref={autoTopupModalRef} currencySymbol={currencySymbol} />
    </>
  );
};

export default AutoRechargeCard;
//
