import { Modal } from "react-bootstrap";

type IBaseModal = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  width?: "sm" | "md" | "lg";
  closeable?: boolean;
  onHide?: () => void;
  backdrop?: "static";
};

export const BaseModal = ({
  children,
  title,
  subtitle,
  width = "md",
  closeable = false,
  onHide,
  backdrop,
}: IBaseModal): React.JSX.Element => {
  // const [show, setShow] = useState(true);
  return (
    <Modal className={`modal-${width}`} border centered show={true} onHide={onHide} backdrop={backdrop}>
      <Modal.Header closeButton={closeable}>
        <h3 className="header-title fw-600 ">{title}</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="fs-4 ">{subtitle}</div>
        {children}
      </Modal.Body>
    </Modal>
  );
};
