import { Button } from '@components/Atoms/Button/Button';
import { Icon } from '@components/Atoms/Icon/Icon';
import { ReadWriteDisplay } from '@components/Atoms/ReadWriteDisplay/ReadWriteDisplay';
import { useDeleteApiKeyMutation } from '@modules/dashboard/integrations/integrations-api-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { PermissionNode } from 'src/types/types';
import { ApiBaseModal } from '../ApiBaseModal/ApiBaseModal';

const PermissionItem = ({ item, level = 0, selections, index }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(selections[item.name] === 'null');

  const getItemStyles = () => {
    if (level === 0) {
      return 'tw-flex tw-justify-between tw-px-6 tw-py-2.5 tw-bg-white tw-border-t-0 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-sb-blue-grey-300';
    } else if (level === 1) {
      return `tw-flex tw-justify-between ${index === 0 ? '' : 'tw-border-t tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-sb-blue-grey-300'}  tw-bg-sb-blue-grey-25  tw-py-2.5`;
    } else {
      return 'tw-flex tw-justify-between tw-bg-sb-blue-grey-25';
    }
  };

  const getLabelStyles = () => {
    if (level === 0) {
      return 'tw-text-base tw-font-semibold tw-text-sb-blue-300';
    } else if (level === 1) {
      return 'tw-text-base tw-font-semibold tw-text-sb-blue-grey-600';
    } else {
      return 'tw-pl-10 tw-text-sm tw-font-normal tw-text-sb-blue-grey-600';
    }
  };

  return (
    <div className={`${level === 0 ? '' : ''} ${level === 1 ? 'tw-flex tw-flex-col tw-px-6' : ''}`}>
      <div className={getItemStyles()}>
        <div onClick={() => item.children.length && setIsOpen(!isOpen)} className="tw-flex tw-items-center tw-gap-3">
          <span className={getLabelStyles()}>{t(`integrations.modal.${item.name}`)}</span>
          {!!item.children.length && level < 2 && (
            <div className="tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-sm tw-bg-sb-blue-grey-100">
              <Icon name={isOpen ? 'chevron_down' : 'chevron_up'} height={4} width={6} />
            </div>
          )}
        </div>
        <div className="tw-flex tw-w-[184px] tw-items-center">
          <ReadWriteDisplay
            tabs={item.options}
            selected={selections || ['None']}
            dropshadow={level === 0}
            property={item.name}
          />
        </div>
      </div>
      {isOpen && item.children && (
        <div className={level === 0 ? 'tw-flex tw-flex-col' : 'tw-flex tw-flex-col tw-gap-2.5 tw-py-2.5'}>
          {item.children.map((child, index) => (
            <PermissionItem key={child.name} item={child} level={level + 1} selections={selections} index={index} />
          ))}
        </div>
      )}
    </div>
  );
};

type ApiDetailsModalProps = {
  closeModal: () => void;
  open: boolean;
  data: PermissionNode;
  scopes: Record<string, string[] | string> | null;
  name: string;
  keyId: string;
  allowedIp?: string;
  expirationDate?: string;
};

export const ApiDetailsModal = ({
  closeModal,
  open,
  data,
  scopes,
  name,
  allowedIp,
  expirationDate,
  keyId,
}: ApiDetailsModalProps) => {
  const { t } = useTranslation();
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const handleDeleteApiKey = async () => {
    await toast.promise(deleteApiKey({ keyId: keyId }), {
      pending: t('integrations.modal.deletingApiKey'),
      success: t('integrations.modal.apiDeletedSuccessfully'),
      error: t('integrations.modal.failedToDeleteApiKey'),
    });
    closeModal();
  };

  return (
    <ApiBaseModal open={open} onClose={closeModal} title={t('integrations.modal.detailsTitle')}>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25 tw-p-6">
        <div className="tw-flex tw-justify-between">
          <span className="tw-font-semibold tw-text-sb-blue-500">{t('integrations.modal.keyName')}</span>
          <span className="tw-font-medium tw-text-sb-blue-grey-600">{name}</span>
        </div>
        {allowedIp && (
          <div className="tw-flex tw-justify-between">
            <span className="tw-font-semibold tw-text-sb-blue-500">{t('integrations.modal.ipRestrictions')}</span>
            <span className="tw-font-medium tw-text-sb-blue-grey-600">{allowedIp}</span>
          </div>
        )}
        {allowedIp && (
          <div className="tw-flex tw-justify-between">
            <span className="tw-font-semibold tw-text-sb-blue-500">{t('integrations.modal.expirationDate')}</span>
            <div className="tw-flex">
              <span className="tw-font-medium tw-text-sb-blue-grey-600">{expirationDate}</span>
            </div>
          </div>
        )}
      </div>
      <div className="tw-flex tw-flex-col tw-bg-sb-blue-grey-25">
        <PermissionItem item={data} selections={scopes} index={0} />
      </div>
      <div className='sm:tw-flex-row" tw-flex tw-flex-col-reverse tw-items-center tw-justify-center tw-gap-5 tw-bg-sb-blue-grey-100 tw-p-6'>
        <Button
          className="tw-w-full tw-border-sb-orange-600 tw-text-sb-orange-600"
          variant="outline"
          onClick={handleDeleteApiKey}
        >
          {t('button.delete')}
        </Button>
      </div>
    </ApiBaseModal>
  );
};
