import { ToggleButton } from '@components/Atoms/ToggleButton/ToggleButton';
import cs from 'classnames';
import { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTable } from 'react-table';
import Loader from '../../../../components/Loader';

const columns = [
  {
    accessor: 'type',
    Cell: ({ row }) => {
      return (
        <div className="d-flex flex-column">
          <div>{row.original.title}</div>
          <p className="small text-muted mb-md-0">{row.original.subtitle}</p>
        </div>
      );
    },
  },
  {
    accessor: 'value',
    Cell: ({ value, onClick, row, processing = false, isUpdating = false }) => {
      return (
        <div>
          {processing ? (
            <Loader flexPosition="justify-content-start" size={1.5} />
          ) : (
            <ToggleButton
              disabled={isUpdating}
              value={value}
              handleChange={() => {
                onClick('value', !value, row.index);
              }}
            />
          )}
        </div>
      );
    },
  },
];

const ToggleButtonCell = ({ cell, handleCheckboxClick, isUpdating }) => {
  const [processing, setProcessing] = useState(false);
  return (
    <>
      {cell.render('Cell', {
        isUpdating,
        onClick: async () => {
          setProcessing(true);
          await handleCheckboxClick();
          setProcessing(false);
        },
        processing,
      })}
    </>
  );
};

const CommunicationTable = ({ title, fields, updateSelf, getSelf, isUpdating, setIsUpdating, communication }) => {
  const { getTableProps, getTableBodyProps, prepareRow, rows } = useTable({
    columns: useMemo(() => columns, []),
    data: useMemo(
      () =>
        fields.map(({ field_name, field_id, field_value }) => ({
          title: field_name,
          id: field_id,
          value: field_value,
        })),
      [fields],
    ),
  });

  const handleToggleButtonClick = async ({ id, value }) => {
    const communications = {};

    communication.forEach(({ fields }) => {
      fields.forEach(({ field_id, field_value }) => {
        communications[field_id] = field_value;
      });
    });

    setIsUpdating(true);
    await updateSelf({
      communication: {
        ...communications,
        [id]: value,
      },
    });
    setIsUpdating(false);
    getSelf();
  };

  const handleUnsubAll = async () => {
    const communications = {};

    communication.forEach(({ fields }) => {
      fields.forEach(({ field_id, field_value }) => {
        communications[field_id] = field_value;
      });
    });

    const singleTableCommunication = {};

    fields.forEach(({ field_id }) => {
      singleTableCommunication[field_id] = false;
    });

    setIsUpdating(true);
    await updateSelf({
      communication: {
        ...communications,
        ...singleTableCommunication,
      },
    });
    setIsUpdating(false);
    getSelf();
  };

  return (
    <Card className="w-100 h-100 mb-0">
      <Card.Header className="border-0">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className={cs('card-header-title text-capitalize align-items-center d-flex', `fw-500`)}>{title}</h4>
        </div>
        <Button className="text-nowrap" variant="white" size="sm" onClick={handleUnsubAll}>
          Unsubscribe all
        </Button>
      </Card.Header>
      <div className="overflow-auto flex-grow-1">
        <table className="table table-sm table-hover table-nowrap card-table overflow-hidden" {...getTableProps()}>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td {...cell.getCellProps()} style={{ width: i === 0 ? '100%' : '0%' }}>
                        <ToggleButtonCell
                          cell={cell}
                          isUpdating={isUpdating}
                          handleCheckboxClick={() =>
                            handleToggleButtonClick({
                              ...row.original,
                              value: !row.original.value,
                            })
                          }
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default CommunicationTable;
