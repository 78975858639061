const ListPageOptions = ({ currentPageIndex, pageCount, gotoPage }) => {
  const toDisplay = [];
  for (let i = 0; i < pageCount; i++) {
    toDisplay.push({
      page: i + 1,
      active: currentPageIndex === i,
    });
  }

  return (
    <ul className="tw-flex tw-list-none tw-overflow-auto">
      {toDisplay.map(({ page, active }) => (
        <li
          key={page}
          className={`tw-rounded-none tw-border tw-border-solid tw-px-3 tw-py-2 tw-text-sm ${active ? 'tw-border-sb-blue-300 tw-bg-sb-blue-300' : 'tw-border-sb-blue-grey-300 tw-text-sb-blue-grey-600 hover:tw-bg-sb-blue-grey-50'} disabled:tw-border-sb-blue-grey-300 disabled:tw-bg-sb-blue-grey-300 disabled:tw-text-sb-blue-grey-500`}
          onClick={() => gotoPage(page - 1)}
        >
          <button
            className={`tw-relative tw-block tw-h-full tw-w-full tw-border-none tw-bg-transparent tw-p-0 ${active ? 'tw-text-white' : ''}`}
          >
            {page}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ListPageOptions;
