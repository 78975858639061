import { HomeIconName } from '@assets/img/icons/HomeIcons';
import { Icon } from '@components/Atoms/Icon/Icon';
import { useTranslation } from 'react-i18next';

const ICON_WRAPPER_STYLE = {
  apn: 'tw-bg-sb-green-400',
  orderSims: 'tw-bg-sb-green-200',
  manageBilling: 'tw-bg-sb-blue-50',
  dashboard: 'tw-bg-sb-blue-200',
};

const WRAPPER_STYLE = {
  apn: 'tw-shadow-sb-shadow-green-200',
  orderSims: 'tw-shadow-sb-shadow-green-50',
  manageBilling: 'tw-shadow-sb-shadow-blue-100',
  dashboard: 'tw-shadow-sb-shadow-blue-200',
};

type HomeHeaderCardProps = {
  header: string;
  body: string;
  linkText: string;
  href: string;
  icon: HomeIconName;
  className?: string;
};

export const HomeHeaderCard = ({ className, header, body, linkText, href, icon }: HomeHeaderCardProps) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="home-header-card"
      className={`tw-flex tw-flex-col tw-gap-7 tw-rounded-xl tw-bg-white tw-p-5 ${className} ${WRAPPER_STYLE[icon]}`}
    >
      <div
        className={`tw-flex tw-w-auto tw-items-center tw-justify-center tw-self-start tw-rounded-md tw-p-[10px] ${ICON_WRAPPER_STYLE[icon]}`}
      >
        <Icon name={icon} />
      </div>
      <span className="tw-text-sb-blue-500 tw-text-base tw-font-semibold">{t(header)}</span>
      <span className="tw-text-sb-blue-grey-600 tw-text-sm">{t(body)}</span>
      <a
        target="_blank"
        aria-label={`${t(linkText)} - ${t(header)}`}
        className="tw-text-sb-blue-500 tw-text-sm tw-font-semibold tw-no-underline"
        href={href}
        rel="noopener noreferrer"
      >
        {`${t(linkText)} >`}
      </a>
    </div>
  );
};
