import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { IError } from 'src/types/types';
import { FormField } from '../../../auth';

const EnterCodeForm = ({ onSubmit, user }) => {
  return (
    <Formik
      initialValues={{ code: '', password: '', confirmPassword: '' }}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: IError = {};
        if (!values.code) {
          errors.code = 'Required';
        }
        if (!values.password) {
          errors.password = 'Required';
        }
        if (!values.confirmPassword) {
          errors.password = 'Required';
        }
        return errors;
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <div className="pb-4">
            {`We have sent a code by email to ${user?.codeDeliveryDetails?.destination}. Enter it below to confirm your account.`}
          </div>
          <FormField
            value={values.code}
            handleChange={handleChange}
            touched={touched.code}
            handleBlur={handleBlur}
            error={errors.code}
            placeholder="code"
            name="code"
            label="Code"
          />
          <FormField
            value={values.password}
            handleChange={handleChange}
            touched={touched.password}
            handleBlur={handleBlur}
            error={errors.password}
            name="password"
            type="password"
            label="New Password"
          />
          <FormField
            value={values.confirmPassword}
            handleChange={handleChange}
            touched={touched.confirmPassword}
            handleBlur={handleBlur}
            error={errors.confirmPassword}
            name="confirmPassword"
            type="password"
            label="Enter New Password Again"
          />
          <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
            Change Password
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EnterCodeForm;
