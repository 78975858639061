import { Modal } from 'react-bootstrap';
import UserForm from './UserForm';

interface InviteMemberProps {
  isOpen: boolean;
  handleClose: () => void;
  handleCreate: (values: any) => void;
  user?: any;
}

const InviteMember = ({ isOpen, handleClose, handleCreate, user }: InviteMemberProps) => {
  return (
    <Modal
      dialogClassName='w-30'
      centered
      show={isOpen}
      onHide={() => handleClose()}
    >
      <Modal.Header closeButton>
        <h3 className='header-title'>Invite member</h3>
      </Modal.Header>
      <Modal.Body>
        <UserForm onSubmit={handleCreate} user={user} />
      </Modal.Body>
    </Modal>
  );
};

export default InviteMember;
