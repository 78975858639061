import {
  APN_LINK,
  BLOG_LINK,
  DOCUMENTATION_LINK,
  LEARN_ABOUT_THE_DASHBOARD_LINK,
  MANAGE_YOUR_BILLING,
  STORE_LINK,
  SUPPORT_LINK,
  SUPPORT_REQUEST_LINK,
} from './links';

export const GET_STARTED = [
  {
    title: 'home.headerCard.apn.header',
    body: 'home.headerCard.apn.body',
    url: APN_LINK,
    url_text: 'learn',
    icon: 'apn',
  },
  {
    title: 'home.headerCard.orderSims.header',
    body: 'home.headerCard.orderSims.body',
    url: STORE_LINK,
    url_text: 'shop',
    icon: 'orderSims',
  },
  {
    title: 'home.headerCard.manageBilling.header',
    body: 'home.headerCard.manageBilling.body',
    url: MANAGE_YOUR_BILLING,
    url_text: 'learn',
    icon: 'manageBilling',
  },
  {
    title: 'home.headerCard.learnDashboard.header',
    body: 'home.headerCard.learnDashboard.body',
    url: LEARN_ABOUT_THE_DASHBOARD_LINK,
    url_text: 'Explore',
    icon: 'dashboard',
  },
];

export const HELP = [
  {
    title: 'home.helpSection.blog',
    url: BLOG_LINK,
    icon: 'book',
  },
  {
    title: 'home.helpSection.helpCenter',
    url: SUPPORT_LINK,
    icon: 'life-buoy',
  },
  {
    title: 'home.helpSection.contactSupport',
    url: SUPPORT_REQUEST_LINK,
    icon: 'at-sign',
  },
  {
    title: 'home.helpSection.api',
    url: DOCUMENTATION_LINK,
    icon: 'share-2',
  },
];
