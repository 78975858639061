import { Button } from '@components/Atoms/Button/Button';
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import React from 'react';

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  clickOutsideToClose?: boolean;
}

export const ApiBaseModal: React.FC<BaseModalProps> = ({
  open,
  onClose,
  title,
  children,
  clickOutsideToClose = true,
}) => {
  return (
    <div className="tw-fixed tw-inset-0 tw-z-40 tw-flex tw-items-center tw-justify-center tw-overflow-visible tw-bg-black/50">
      <Transition appear show={open}>
        <Dialog
          as="div"
          className="tw-relative tw-z-50 tw-bg-white focus:tw-outline-none"
          onClose={clickOutsideToClose ? onClose : () => {}}
        >
          <div className="tw-fixed tw-inset-0 tw-w-screen tw-overflow-visible">
            <div className="tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-2.5">
              <TransitionChild
                enter="tw-ease-out tw-duration-300"
                enterFrom="tw-opacity-0 tw-transform-[tw-scale(95%)]"
                enterTo="tw-opacity-100 tw-transform-[tw-scale(100%)]"
                leave="tw-ease-in tw-duration-200"
                leaveFrom="tw-opacity-100 tw-transform-[tw-scale(100%)]"
                leaveTo="tw-opacity-0 tw-transform-[tw-scale(95%)]"
              >
                <DialogPanel className="tw-max-h-[calc(100vh-2.5rem)] tw-w-full tw-max-w-[800px] tw-overflow-auto tw-rounded-xl tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-backdrop-blur-2xl">
                  <div className="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-100 tw-p-5">
                    <DialogTitle
                      as="h2"
                      className="tw-font-base tw-mb-0 tw-text-center tw-font-semibold tw-text-sb-blue-500"
                    >
                      {title}
                    </DialogTitle>
                    <div className="tw-flex tw-items-center">
                      <Button className="tw-text-lg" variant="borderless" onClick={onClose}>
                        X
                      </Button>
                    </div>
                  </div>
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
