export const customTagsFilter = (row, _, filterValue) => {
  if (row.columnFilters?.tags === true) {
    return true;
  }

  if (row.original.tags?.includes(filterValue)) {
    return true;
  }
  return false;
};

/*
  This function is used to filter the data based on the coverage column
  This is how it works:
  - React table scans all the rows for every filter, if you have two active filters both are run, one after another.
  - If one row is returned true by the first filter but false by the second one, it will be considered false. 
  - With this logic, if the row has been found to match the first filter, it will skip the second check, making the filter inclusive. 
*/
export const customCoverageFilter = (row, _, filterValue) => {
  if (row.columnFilters?.coverage === true) {
    return true;
  }

  if (row.original.coverage === filterValue) {
    return true;
  }
  return false;
};
