import toTitleCase from '@utils/toTitleCase';
import React from 'react';
import { useTranslation } from 'react-i18next';

type TabLabel = 'read' | 'write' | 'none';
type TitleCaseTabLabel = 'Read' | 'Write' | 'None';

type ReadWriteDisplayProps = {
  tabs: TabLabel[];
  dropshadow?: boolean;
  selected: Record<string, TitleCaseTabLabel[]>;
  property: string;
};

const TAB_LABELS = {
  READ: 'Read' as const,
  WRITE: 'Write' as const,
  NONE: 'None' as const,
};

const toTitleCaseTabLabel = (tab: TabLabel): TitleCaseTabLabel => {
  return toTitleCase(tab) as TitleCaseTabLabel;
};

const Tab = ({ label, isSelected }: { label: TabLabel; isSelected: boolean }) => {
  const { t } = useTranslation();
  return (
    <div role="tab" aria-selected={isSelected} tabIndex={0} className={getTabClassName(isSelected, label)}>
      {t(toTitleCaseTabLabel(label))}
    </div>
  );
};

const getTabClassName = (isSelected: boolean, label: TabLabel) => {
  const baseClasses = 'tw-rounded-sm tw-px-2.5 tw-py-1 tw-text-sm tw-transition-colors';
  const selectedClasses = 'tw-bg-sb-blue-grey-100 tw-font-semibold';
  const unselectedClasses = 'tw-bg-white tw-font-normal tw-text-sb-blue-grey-600';

  if (!isSelected) return `${baseClasses} ${unselectedClasses}`;

  switch (toTitleCaseTabLabel(label)) {
    case TAB_LABELS.WRITE:
      return `${baseClasses} ${selectedClasses} tw-text-sb-blue-300`;
    case TAB_LABELS.READ:
      return `${baseClasses} ${selectedClasses} tw-text-sb-green-500`;
    default:
      return `${baseClasses} ${selectedClasses} tw-text-sb-orange-300`;
  }
};

export const ReadWriteDisplay = React.memo(({ tabs, dropshadow, selected, property }: ReadWriteDisplayProps) => {
  return (
    <div
      role="tablist"
      aria-label="Read Write Display options"
      className={`tw-flex tw-gap-1.5 tw-rounded-md tw-border tw-border-solid tw-border-sb-blue-grey-300 tw-bg-white tw-px-2 tw-py-1.5 ${
        dropshadow ? 'tw-shadow-read-write-radio-shadow' : ''
      }`}
    >
      {tabs.map((tab) => (
        <Tab key={tab} label={tab} isSelected={selected[property].includes(toTitleCaseTabLabel(tab))} />
      ))}
    </div>
  );
});
