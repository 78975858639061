import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

const BASE_CLASS =
  'tw-flex tw-justify-center tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-transparent tw-h-[27px] tw-w-[22px] hover:tw-cursor-pointer';
const FILLED_CLASS = '!tw-border-sb-blue-grey-300 tw-bg-sb-blue-grey-25';

type ArrowsButtonProps = {
  popUpOpen: boolean;
  setPopUpOpen: Dispatch<SetStateAction<boolean>>;
};

const ArrowsSVG: React.FC<{ isOpen: boolean }> = ({ isOpen }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="13" viewBox="0 0 6 13" fill="none">
    <path
      d="M0.0330124 3.90746C0.000531088 3.82315 -0.0080017 3.73035 0.00849332 3.6408C0.0249883 3.55126 0.0657699 3.469 0.12568 3.40442L2.69682 0.635355C2.73662 0.592445 2.78388 0.558405 2.8359 0.535179C2.88792 0.511954 2.94369 0.5 3 0.5C3.05631 0.5 3.11208 0.511954 3.1641 0.535179C3.21612 0.558405 3.26338 0.592445 3.30318 0.635355L5.87432 3.40442C5.93432 3.46896 5.97518 3.55123 5.99175 3.6408C6.00831 3.73037 5.99982 3.82322 5.96736 3.9076C5.9349 3.99197 5.87992 4.06408 5.80939 4.11478C5.73886 4.16549 5.65594 4.19252 5.57114 4.19245H0.428861C0.344107 4.19243 0.261262 4.16534 0.190802 4.11462C0.120342 4.06389 0.0654306 3.9918 0.0330124 3.90746ZM5.57114 8.80755H0.428861C0.344058 8.80748 0.261142 8.83451 0.19061 8.88522C0.120077 8.93592 0.0651001 9.00803 0.0326385 9.0924C0.000176763 9.17678 -0.00830998 9.26963 0.00825262 9.3592C0.0248152 9.44877 0.0656822 9.53104 0.12568 9.59558L2.69682 12.3646C2.73662 12.4076 2.78388 12.4416 2.8359 12.4648C2.88792 12.488 2.94369 12.5 3 12.5C3.05631 12.5 3.11208 12.488 3.1641 12.4648C3.21612 12.4416 3.26338 12.4076 3.30318 12.3646L5.87432 9.59558C5.93432 9.53104 5.97518 9.44877 5.99175 9.3592C6.00831 9.26963 5.99982 9.17678 5.96736 9.0924C5.9349 9.00803 5.87992 8.93592 5.80939 8.88522C5.73886 8.83451 5.65594 8.80748 5.57114 8.80755Z"
      fill={isOpen ? '#123466' : '#46719C'}
    />
  </svg>
);

export const ArrowsButton = ({ popUpOpen, setPopUpOpen }: ArrowsButtonProps) => {
  const [filled, setFilled] = useState(false);

  useEffect(() => {
    if (popUpOpen) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [popUpOpen]);

  const handleMouseDown = () => {
    if (!popUpOpen) {
      setFilled(true);
    }
  };

  const handleMouseEnter = () => {
    if (!popUpOpen) {
      setFilled(true);
    }
  };

  const handleMouseLeave = () => {
    if (!popUpOpen) {
      setFilled(false);
    }
  };

  const className = useMemo(() => {
    return `${BASE_CLASS} ${filled ? FILLED_CLASS : ''}`;
  }, [filled]);

  const handleClick = useCallback(() => {
    setPopUpOpen((prev) => !prev);
  }, [setPopUpOpen]);

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
      onClick={handleClick}
      data-testid="arrows-button"
    >
      <ArrowsSVG isOpen={popUpOpen} />
    </div>
  );
};
