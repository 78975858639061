import { Unit } from "src/types/types";

type DropdownOption = {
  key: string;
  value: string;
  price: string;
  unit: Unit;
};

const mapPrivateNetworksToDropdownOptions = (privateNetworks): DropdownOption[] => {
  const result = [];
  for (let item of privateNetworks) {
    result.push({
      key: item.product_name,
      value: item.subnet_size,
      price: item.price,
      unit: item.unit,
    });
  }

  result.sort((a, b) => Number(a.price) - Number(b.price));
  return result;
};

export default mapPrivateNetworksToDropdownOptions;
