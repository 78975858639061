import { ConfirmationModal } from '@components/ConfirmationModal';
import { GET_NETWORK_DETAILS_POLL_TIME, NETWORK_STATE } from '@core/constants';
import copyToClipboard from 'copy-to-clipboard';
import { useEffect, useRef, useState } from 'react';
import { Badge, Card, Col, FormControl, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEditNetworkMutation, useGetNetworkQuery, useTerminateNetworkMutation } from '../network-api-slice';

type NetworkResponse = {
  errorMessage?: string;
  message?: string;
};

const StateBadge = ({ state }) => {
  if (state === NETWORK_STATE.DEPLOYED) {
    return (
      <Badge className="d-flex align-items-center" bg="success">
        {state}
      </Badge>
    );
  }
  if (state === NETWORK_STATE.TERMINATED) {
    return (
      <Badge className="d-flex align-items-center" bg="danger">
        {state}
      </Badge>
    );
  }
  return (
    <Badge className="d-flex align-items-center" bg="warning">
      {state}
    </Badge>
  );
};

const HeaderCards = ({ id }) => {
  const { t } = useTranslation();
  // TerminateNetwork
  const [terminateNetwork] = useTerminateNetworkMutation();

  // EditNetwork
  const [editNetwork] = useEditNetworkMutation();

  // GetNetwork
  const getNetworkResponse = useGetNetworkQuery({ id });
  const { refetch: refetchNetwork } = getNetworkResponse;
  const { data: details } = getNetworkResponse ?? {};
  const { network_name, network_state, subnet, internet_access } = details ?? {};

  const [networkName, setNetworkName] = useState(network_name);
  const [editName, setEditName] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [networkNameProcessing, setNetworkNameProcessing] = useState(false);
  const [isNameConfirmModalOpen, setIsNameConfirmModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const nameRef = useRef<HTMLInputElement>();
  const intervalRef = useRef(null);

  useEffect(() => {
    if (showOverlay) {
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    }
  }, [showOverlay]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleNetworkStateChange = () => {
    setIsConfirmModalOpen(true);
  };

  const handleNameChange = async () => {
    if (!networkName.length) {
      toast.error(t('network.nameCannotBeEmpty'));
      setIsNameConfirmModalOpen(false);
    } else {
      setNetworkNameProcessing(true);
      await editNetwork({ id, name: networkName });
      setNetworkNameProcessing(false);
      setEditName(false);
      setIsNameConfirmModalOpen(false);
    }
  };

  const handleTerminateNetwork = async (): Promise<void> => {
    try {
      const response: NetworkResponse = await terminateNetwork({ id }).unwrap();
      await handleSuccessfulTermination(response);
    } catch (error) {
      handleTerminationError(error);
    }
  };

  const handleSuccessfulTermination = async (response: NetworkResponse): Promise<void> => {
    setIsConfirmModalOpen(false);
    if (response.message) {
      toast.success(response.message);
    }
    startNetworkPolling();
  };

  const handleTerminationError = (error: unknown): void => {
    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    toast.error(errorMessage);
    console.error('Error terminating network:', error);
  };

  const startNetworkPolling = (): void => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(() => {
      refetchNetwork();
    }, GET_NETWORK_DETAILS_POLL_TIME);
  };

  useEffect(() => {
    // When network becomes terminated stop polling for network state change (fetching network details)
    if (network_state === NETWORK_STATE.TERMINATED && intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, [network_state]);

  if (network_state === NETWORK_STATE.TERMINATED) {
    return <Navigate replace to="/dashboard/private-network" />;
  }

  return (
    <div className="d-flex">
      <div className="w-100 pe-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">name</h6>
                <div className="d-flex align-items-center justify-content-between">
                  <FormControl
                    className={`fw-bold py-0 header-card-title ${!editName ? 'border-0 ps-0' : 'ps-2'}`}
                    onClick={() => {
                      if (!editName) {
                        setEditName(true);
                      }
                    }}
                    onBlur={async (e) => {
                      if (e?.relatedTarget?.id === 'save-name-change') {
                        await setIsNameConfirmModalOpen(true);
                      } else {
                        setNetworkName(network_name);
                      }
                      setEditName(false);
                    }}
                    ref={nameRef}
                    value={networkName}
                    onChange={(e) => setNetworkName(e.target.value)}
                    type="text"
                  />
                  <div className="ms-2 d-flex align-items-end">
                    {networkNameProcessing ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <>
                        {!editName && (
                          <span
                            onClick={() => {
                              setEditName(true);
                              nameRef.current.focus();
                            }}
                            className="h2 fe fe-edit text-muted mb-0 cursor-pointer"
                          />
                        )}
                        {editName && (
                          <span
                            onClick={() => setIsNameConfirmModalOpen(true)}
                            className={`h2 fe fe-save text-muted mb-0 cursor-pointer`}
                            tabIndex={0}
                            id="save-name-change"
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="w-100 px-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">state</h6>
                <div className="d-flex justify-content-between">
                  <StateBadge state={network_state} />
                  <span
                    onClick={handleNetworkStateChange}
                    className={`h2 fe fe-edit text-muted mb-0 cursor-pointer`}
                    tabIndex={0}
                    id="save-name-change"
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="w-100 px-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-center gx-0 h-100">
              <Col>
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">subnet</h6>
                <div className="d-flex align-items-center justify-content-between">
                  <h2 className="header-title">{subnet}</h2>
                  <OverlayTrigger
                    placement="top"
                    show={showOverlay}
                    overlay={(props) => <Tooltip {...props}>Copied to clipboard!</Tooltip>}
                  >
                    <span
                      onClick={() => {
                        copyToClipboard(subnet);
                        if (!showOverlay) {
                          setShowOverlay(true);
                        }
                      }}
                      className={`h2 fe fe-clipboard text-muted mb-1 cursor-pointer`}
                    />
                  </OverlayTrigger>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <div className="w-100 ps-3">
        <Card className="mb-0 h-100">
          <Card.Body>
            <Row className="align-items-start gx-0 h-100">
              <Col className="mt-1">
                <h6 className="text-uppercase text-muted mb-2 text-uppercase">internet access</h6>
                <Badge className="px-3" bg="primary">
                  {internet_access}
                </Badge>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
      <ConfirmationModal
        isOpen={isNameConfirmModalOpen}
        title="Change Private Network Name"
        handleCancel={() => {
          setIsNameConfirmModalOpen(false);
          setNetworkName(network_name);
        }}
        handleConfirm={handleNameChange}
        subtitle={`Are you sure you want to change name to ${networkName}?`}
      />
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        title="Delete Private Network"
        handleCancel={() => setIsConfirmModalOpen(false)}
        handleConfirm={handleTerminateNetwork}
        subtitle={`Are you sure you want to delete ${network_name}? This action can not be undone. We cannot recover the network after termination.`}
      />
    </div>
  );
};
export default HeaderCards;
