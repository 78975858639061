import { FormField } from '@modules/auth';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IError } from 'src/types/types';

const SignUpForm = ({ onSubmit }) => {
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{ email: '', name: '', password: '' }}
      validate={(values) => {
        const errors: IError = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (!values.password) {
          errors.password = 'Required';
        } else if (!/[0-9]+/.test(values.password)) {
          errors.password = 'Password must contain at least one number';
        } else if (!/[A-Z]+/.test(values.password)) {
          errors.password = 'Password must contain at least one uppercase letter';
        } else if (!/[a-z]+/.test(values.password)) {
          errors.password = 'Password must contain at least one lowercase letter';
        } else if (
          // eslint-disable-next-line
          !/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/.test(values.password)
        ) {
          errors.password = 'Password must contain at least one special character';
        } else if (values.password.length < 8) {
          errors.password = 'Password must have at least 8 characters';
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <FormField
            value={values.email}
            handleChange={handleChange}
            touched={touched.email}
            handleBlur={handleBlur}
            error={errors.email}
            placeholder="name@address.com"
            name="email"
            type="email"
            label="Email Address"
          />
          <FormField
            value={values.name}
            handleChange={handleChange}
            touched={touched.name}
            handleBlur={handleBlur}
            error={errors.name}
            placeholder="Name"
            name="name"
            label="Name"
          />
          <FormField
            value={values.password}
            handleChange={handleChange}
            touched={touched.password}
            handleBlur={handleBlur}
            error={errors.password}
            placeholder="Enter your password"
            name="password"
            type="password"
            label="Password"
          />
          <Button type="submit" variant="primary" className="w-100 mb-3 mt-3 lift" disabled={isSubmitting}>
            Sign up
          </Button>
          <div className="text-center">
            <small className="text-muted text-center">
              Already have an account &nbsp;
              <span
                className="btn-link cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/auth/signin');
                }}
              >
                Log in
              </span>
              .
            </small>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUpForm;
