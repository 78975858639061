import Loader from '@components/Loader';
import { getAuthenticatedUser } from '@modules/auth/utils';
import { setUpTOTP, updateMFAPreference, verifyTOTPSetup } from 'aws-amplify/auth';
import { QRCodeCanvas } from 'qrcode.react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { copyToClipboard } from 'src/lib/utils/utils';

export const QrCodeModal = forwardRef(
  (
    {
      setMfaEnabled,
    }: {
      setMfaEnabled: (boolean) => void;
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    useImperativeHandle(ref, () => {
      return {
        open: () => {
          setStep('scan');
          setIsOpen(true);
        },
        close: () => {
          setIsOpen(false);
        },
      };
    });
    const [step, setStep] = useState<'scan' | 'verify'>('scan');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [qrCodeString, setQrCodeString] = useState<string | undefined>(undefined);
    const [sharedSecret, setSharedSecret] = useState<string | null>(null);

    useEffect(() => {
      (async () => {
        try {
          const details = await setUpTOTP();
          setSharedSecret(details.sharedSecret);
          const url =
            'otpauth://totp/' +
            (await getAuthenticatedUser()).email +
            '?secret=' +
            details.sharedSecret +
            '&issuer=' +
            'Simbase';
          setQrCodeString(url);
        } catch (error) {
          console.error('Error setting up TOTP:', error);
          toast.error(t('errorMessage.somethingWentWrongGetInTouch'));
        }
      })();
    }, []);

    const handleMfaVerification = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      try {
        await verifyTOTPSetup({
          code: e.target.verificationCode.value,
        });
        toast.success(t('mfa.successfullyVerified'));
        setIsOpen(false);
        setMfaEnabled((state) => !state);
      } catch (error) {
        toast.error(t('errorMessage.somethingWentWrongGetInTouch'));
        if (process.env.NODE_ENV === 'development') {
          console.error('TOTP verification error:', error);
        }
      }

      try {
        await updateMFAPreference({ totp: 'PREFERRED' });
      } catch (error) {
        toast.error(t('errorMessage.somethingWentWrongGetInTouch'));
      }

      setIsLoading(() => false);
    };

    // Autofocus on input text
    const inputRef = useRef(null);
    useEffect(() => {
      if (step === 'verify') {
        inputRef.current.focus();
      }
    }, [step]);

    return (
      <Modal centered show={isOpen} backdrop="static" onHide={() => setIsOpen(false)}>
        {step === 'scan' && (
          <>
            <Modal.Header className="h3 mb-0" closeButton>
              Register your MFA Device
            </Modal.Header>
            <Modal.Body className="pt-3">
              <div className="mx-auto w-80 text-center">
                <p>Scan the QR code below to setup Time-based One-Time Password (TOTP) in your authenticator app.</p>
              </div>
              <div className="vstack gap-4 border border-2 rounded pt-4">
                <div className="d-flex align-items-center justify-content-center">
                  {qrCodeString ? <QRCodeCanvas value={qrCodeString} size={128} /> : <Loader />}
                </div>
                <div
                  className="d-flex align-items-center justify-content-center cursor-pointer"
                  onClick={() => copyToClipboard(sharedSecret, 'MFA Code')}
                >
                  <pre>
                    {sharedSecret}
                    <i className="fe fe-copy ms-2" />
                  </pre>
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center">
                <button className="btn btn-primary mt-4" onClick={() => setStep('verify')}>
                  Enter verification code
                </button>
              </div>
            </Modal.Body>
          </>
        )}

        {step === 'verify' && (
          <>
            <Modal.Header className="h3 mb-0" closeButton>
              Verify your MFA Device
            </Modal.Header>
            <Modal.Body className="pt-3">
              <div className="mx-auto w-80 text-center">
                <p>Enter the 6-digit code generated by your authenticator app.</p>
              </div>
              <form onSubmit={handleMfaVerification}>
                <div className="vstack gap-3">
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      ref={inputRef}
                      type="text"
                      name="verificationCode"
                      className="form-control w-50 has-validation"
                      placeholder="123456"
                      pattern="\d{6}"
                      required
                    />
                  </div>
                  {/* <div className="form-check d-flex justify-content-center hstack gap-2 align-items-center">
                    <input
                      className="form-check-input"
                      name="rememberDevice"
                      type="checkbox"
                      id="flexCheckChecked"
                    />
                    <label className="form-check-label small text-muted " htmlFor="flexCheckChecked">
                      Remember this device
                    </label>
                  </div>
                 */}
                  <div className="d-flex justify-content-center hstack gap-3">
                    <button className="btn btn-outline-primary w-25" onClick={() => setStep('scan')}>
                      Back
                    </button>

                    <button
                      className="btn btn-primary w-25 d-flex justify-content-center align-items-center hstack gap-2"
                      disabled={isLoading}
                      type="submit"
                    >
                      <span>Verify</span>
                      <span>{isLoading && <Loader size={1} variant="light" />}</span>
                    </button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </>
        )}
      </Modal>
    );
  },
);
