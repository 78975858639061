import { useState } from "react";

import { version } from "../../package.json";
import { useEventListener } from "../lib/utils/hooks/useEventListener";

const Version = () => {
  const [showVersion, setShowVersion] = useState(false);

  useEventListener("keydown", (e: any) => {
    if (e?.metaKey && e?.ctrlKey && e?.code === "KeyL") {
      setShowVersion(!showVersion);
    }
  });

  return <>{showVersion && <small className="version-text">{`Version: ${version}`}</small>}</>;
};

export default Version;
