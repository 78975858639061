import { PageNavBar } from '@components/Molecules/PageNavBar/PageNavBar';
import { useGetSelfQuery } from '@modules/dashboard/account/account-api-slice';
import { useLazyGetDashboardParametersQuery } from '@modules/dashboard/simCard/simcards-api-slice';
import useIsProcessing from '@utils/hooks/useIsProcessing';
import { convertTimeFields } from '@utils/timezoneConversion';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BillingDetails, Countries, Customer } from 'src/types/types';
import { OverviewHeader } from '../../components';
import {
  useGetInvoicesQuery,
  useLazyGetCostsQuery,
  useLazyGetPaymentsQuery,
  useLazyGetVasOverviewQuery,
  useLazyGetVasQuery,
} from '../billing-api-slice';
import { Invoices, Overview, Settings, SimReport } from '../index';
import Payments from './Payments';
import Subscriptions from './Subscriptions';

const currentYearMonth = dayjs().format('YYYY-MM');

const TABS = {
  OVERVIEW: 'overview',
  INVOICES: 'invoices',
  PAYMENTS: 'payments',
  SIM_REPORT: 'sim_report',
  SUBSCRIPTIONS: 'subscriptions',
  SETTINGS: 'billing_settings',
};
const tabs = [
  { label: 'Overview', key: 'overview' },
  { label: 'Invoices', key: 'invoices' },
  { label: 'Payments', key: 'payments' },
  { label: 'SIM Report', key: 'sim_report' },
  { label: 'Subscriptions', key: 'subscriptions' },
  { label: 'Billing Settings', key: 'billing_settings' },
];

type BillingProps = {
  sidebarOpen: boolean;
  countriesData: Countries;
  customerResponseDetails: Customer;
  customerResponseIsFetching: boolean;
  billingDetails: BillingDetails;
  updateBillingDetails: (details: Partial<BillingDetails>) => void;
  billingDetailsAreReady: boolean;
};
const Billing = ({
  sidebarOpen,
  countriesData,
  customerResponseDetails,
  customerResponseIsFetching,
  billingDetails,
  updateBillingDetails,
  billingDetailsAreReady,
}: BillingProps) => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: TABS.OVERVIEW });
  const tab = searchParams.get('tab') || null;
  const [selectedMonth, setSelectedMonth] = useState<string | null>(currentYearMonth);

  const setTab = (tab) => {
    setSearchParams((prev) => {
      prev.set('tab', tab);
      return prev;
    });
  };

  // GetSelf
  const { data: userData } = useGetSelfQuery();

  // PreferedTimezone
  const defaultTimezone = moment.tz.guess();
  const [preferredTime, setPreferredTime] = useState(defaultTimezone);
  useEffect(() => {
    if (userData?.preferred_timezone) {
      setPreferredTime(userData.preferred_timezone);
    }
  }, [userData?.preferred_timezone]);

  // GetPayments
  const [payments, setPayments] = useState(null);
  const [paymentsResponseErrorMessage, setpaymentsResponseErrorMessage] = useState('');
  const [getPayments, getPaymentsResponse] = useLazyGetPaymentsQuery();
  useEffect(() => {
    getPayments({ count: 20 });
  }, []);
  useEffect(() => {
    if (getPaymentsResponse?.isSuccess) {
      setPayments((prevState) => {
        const mergedPayments = [...(prevState ?? []), ...(getPaymentsResponse.data?.payments ?? [])];
        return convertTimeFields(mergedPayments, preferredTime, ['timestamp']);
      });
      const cursor = getPaymentsResponse.data?.cursor ?? null;
      if (cursor) getPayments({ count: 20, cursor });
    }
    if (getPaymentsResponse?.isError) {
      setpaymentsResponseErrorMessage('Error fetching payments');
    }
  }, [getPaymentsResponse?.isSuccess, getPaymentsResponse?.isError, preferredTime, getPayments]);
  const paymentsResponseIsProcessing = useIsProcessing([
    getPaymentsResponse?.isLoading,
    getPaymentsResponse?.isFetching,
  ]);

  // GetInvoices
  const { data: invoicesData, isSuccess: invoicesIsSuccess } = useGetInvoicesQuery();

  // GetDashboardParams
  const [getDashboardParams, getDashboardParamsResponse] = useLazyGetDashboardParametersQuery();
  const { data: dashboardParamsData } = getDashboardParamsResponse;
  useEffect(() => {
    getDashboardParams();
    // eslint-disable-next-line
  }, []);
  const dashboardParamIsProcessing = useIsProcessing([
    getDashboardParamsResponse.isFetching,
    getDashboardParamsResponse.isLoading,
  ]);

  // GetVas
  const [getVas, getVasResponse] = useLazyGetVasQuery();
  const { data: vasData } = getVasResponse;
  useEffect(() => {
    getVas();
  }, []);

  // GetVasOverview
  const [getVasOverview, getVasOverviewResponse] = useLazyGetVasOverviewQuery();
  const { data: vasOverviewData } = getVasOverviewResponse;
  useEffect(() => {
    getVasOverview({ params: { month: selectedMonth } });
  }, [selectedMonth]);

  // GetCosts
  const [getCosts, costsResponse] = useLazyGetCostsQuery();
  const { data: costsResponseData, isFetching: costsResponseIsFetching } = costsResponse;
  useEffect(() => {
    getCosts(selectedMonth);
  }, [getCosts, selectedMonth]);

  return (
    <div className="d-flex justify-content-center">
      <div className={`mx-auto tw-flex tw-w-full tw-items-center tw-justify-center tw-px-6`}>
        <div className="tw-flex tw-max-w-[1240px] tw-flex-grow tw-flex-col">
          <OverviewHeader title="Billing" sidebarOpen={sidebarOpen} />

          <PageNavBar tabs={tabs} onSelect={setTab} />

          <div className="py-4 h-100">
            {(tab === TABS.OVERVIEW || tab === null) && (
              <Overview
                handleEditBillingDetails={() => setTab(TABS.SETTINGS)}
                tax={customerResponseDetails?.customerDetails?.tax?.tax}
                customerResponseIsFetching={customerResponseIsFetching}
                billingDetails={billingDetails}
                updateBillingDetails={updateBillingDetails}
              />
            )}
            {tab === TABS.INVOICES && (
              <Invoices invoices={invoicesData?.records} invoicesIsSuccess={invoicesIsSuccess} />
            )}
            {tab === TABS.PAYMENTS && (
              <Payments
                preferredTime={preferredTime}
                payments={payments}
                paymentsResponseIsProcessing={paymentsResponseIsProcessing}
                paymentsResponseErrorMessage={paymentsResponseErrorMessage}
                setpaymentsResponseErrorMessage={setpaymentsResponseErrorMessage}
              />
            )}
            {tab === TABS.SIM_REPORT && (
              <SimReport
                months={dashboardParamsData?.months}
                dashboardParamIsProcessing={dashboardParamIsProcessing}
                costs={costsResponseData?.sims}
                costsResponseIsFetching={costsResponseIsFetching}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
              />
            )}
            {tab === TABS.SUBSCRIPTIONS && (
              <Subscriptions
                months={dashboardParamsData?.months}
                vas={vasData}
                vasIsSuccess={getVasResponse?.isSuccess}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                vasOverviewItems={vasOverviewData?.items}
                vasOverviewIsFetching={getVasOverviewResponse?.isFetching}
                currentYearMonth={currentYearMonth}
              />
            )}
            {tab === TABS.SETTINGS && (
              <Settings
                countries={countriesData}
                customer={customerResponseDetails}
                customerResponseIsFetching={customerResponseIsFetching}
                billingDetails={billingDetails}
                updateBillingDetails={updateBillingDetails}
                billingDetailsAreReady={billingDetailsAreReady}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
