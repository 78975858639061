import { Card } from "react-bootstrap";

const DisplayCard = ({ footer, image, leftContent, rightContent, subtitle, title, displayImage }) => {
  return (
    <Card className="mb-0 h-100">
      <img
        src={displayImage}
        alt="..."
        className="card-img-top card-image"
        style={{
          display: "flex",
          height: 200,
        }}
      />
      <Card.Body className="text-center d-flex flex-column justify-content-end">
        <div className="avatar avatar-xl card-avatar-top mb-2 d-flex justify-content-center ms-auto me-auto">
          <img src={image} className="rounded-circle border border-4 border-card bg-white h-100" alt="..." />
        </div>
        <h3>{title}</h3>
        <small className="text-muted text-nowrap">{subtitle}</small>
        <hr className="m-0" />
        <div className="d-flex justify-content-center">
          <div className="border-end py-3 d-flex justify-content-center flex-column align-items-center w-100">
            <h6 className="text-muted mb-2">{leftContent.label}</h6>
            <div className="fw-700 fs-2">{leftContent.value}</div>
          </div>
          <div className="py-3 d-flex justify-content-center flex-column align-items-center w-100">
            <h6 className="text-muted mb-2">{rightContent.label}</h6>
            <div className="fw-700 fs-2">{rightContent.value}</div>
          </div>
        </div>
        <hr className="m-0" />
        {footer}
      </Card.Body>
    </Card>
  );
};

export default DisplayCard;
