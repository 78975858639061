import { useAppDispatch, useAppSelector } from "@core/rtk-hooks";
import { useEffect } from "react";
import { fetchSimcardsThunk, updateSimcardsThunk } from "../simcards-slice";

export const useSimcards = () => {
  const dispatch = useAppDispatch();
  const simcardsState = useAppSelector((state) => state.simcardsState);
  const simcards = useAppSelector((state) => state.simcardsState.simcards);

  useEffect(() => {
    if (simcardsState.simcards.length === 0) {
      dispatch(fetchSimcardsThunk());
    } else {
      dispatch(updateSimcardsThunk());
    }
  }, []);

  return {
    refetch: () => dispatch(updateSimcardsThunk()),
    simcards,
    isLoading: simcardsState.isLoading,
    isFetching: simcardsState.isFetching,
    isFulfilled: simcardsState.isFulfilled,
    status: simcardsState.status,
    error: simcardsState.error,
  };
};
