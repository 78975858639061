import { convertTimeFields } from '@utils/timezoneConversion';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NetworkRegistrationRecord } from 'src/types/types';
import SimpleTable from '../../../../components/Organisms/SimpleTable/SimpleTable';
import { useGetSelfQuery } from './../../account/account-api-slice';

type NetworkRegistrationModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  data: NetworkRegistrationRecord[];
};

const NetworkRegistrationModal = ({ isOpen, handleClose, data }: NetworkRegistrationModalProps) => {
  const { t } = useTranslation();
  const { data: userData } = useGetSelfQuery();

  const defaultTimezone = moment.tz.guess();
  const [preferredTime, setPreferredTime] = useState(defaultTimezone);
  const [formattedData, setFormattedData] = useState(data);

  useEffect(() => {
    if (userData?.preferred_timezone) {
      setPreferredTime(userData.preferred_timezone);
    }
  }, [userData]);

  useEffect(() => {
    if (data) {
      setFormattedData(convertTimeFields(data, preferredTime, ['timestamp']));
    }
  }, [data, preferredTime]);

  const columns = [
    {
      Header: 'COUNTRY',
      accessor: 'country',
    },
    {
      Header: 'NETWORK',
      accessor: 'carrier',
    },
    {
      Header: 'TIME',
      accessor: 'timestamp',
      extraInfo: t('timezone.extraInfo', { preferredTime: preferredTime }),
    },
    {
      Header: 'CONNECTION STATUS',
      accessor: 'result_code',
      Cell: ({ value }) => (
        <span className={`me-1 ${value === 'Allowed' ? 'text-success' : 'text-danger'}`}>{`● ${value}`}</span>
      ),
    },
  ];

  return (
    <Modal centered show={isOpen} onHide={handleClose}>
      <ModalBody>
        <SimpleTable
          title="Network events"
          titleFontWeight="500"
          data={formattedData}
          columns={columns}
          hasPerPage={false}
          hasExport={false}
          initialPageSize={10}
        />
      </ModalBody>
    </Modal>
  );
};

export default NetworkRegistrationModal;
