import { PermissionNode, ResourceItem } from 'src/types/types';

export const transformPermissionsData = (data: ResourceItem[]): PermissionNode => {
  const root = { name: 'allResources', children: [], options: ['none', 'read', 'write'] };
  const map = { '': root };

  data.forEach((item) => {
    const parts = item.resource.split('.');
    let currentPath = '';
    let parent = root;

    parts.forEach((part) => {
      currentPath += (currentPath ? '.' : '') + part;
      if (!map[currentPath]) {
        const newItem = {
          name: part,
          fullName: currentPath,
          options: ['none', ...item.permissions],
          children: [],
        };
        map[currentPath] = newItem;
        parent.children.push(newItem);
      }
      parent = map[currentPath];
    });
  });

  return root as PermissionNode;
};
