import TransparentInput from '@components/Atoms/TrasparentInput/TransparentInput';
import Loader from '@components/Loader';
import { Select } from '@components/Molecules/Select/Select';
import ConfirmationModal from '@components/v2/ConfirmationModal';
import { PAGE_SIZE_OPTIONS, SIM_STATE } from '@core/constants';
import {
  ColumnFiltersState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { updateColumnsWithCustomDataSorting } from '@utils/simCardUtils/simCardUtils';
import { LayoutGroup } from 'framer-motion';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getItem } from 'src/lib/utils/localStorage';
import { useSimcards } from '../../hooks/useSimcards';
import { useUpdateSimStatusMutation } from '../../simcards-api-slice';
import { SimcardsListViewItem } from '../../simcards-slice';
import NoSimsModal from '../NoSimsModal';
import ActionBar from './ActionBar';
import { columns } from './columns';
import { customCoverageFilter, customTagsFilter } from './filters';
import { AssignToNetworkModal } from './modals/AssignToNetworkModal';
import { SendSmsModal } from './modals/SendSmsModal';
import RowDropdown from './RowDropdown';
import { Sidebar } from './sidebar/Sidebar';

declare module '@tanstack/table-core' {
  interface FilterFns {
    tagsFilter: FilterFn<unknown>;
    coverageFilter: FilterFn<unknown>;
  }
}

export const SimcardsTableV2 = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});
  const navigate = useNavigate();

  const [updateSimStatus] = useUpdateSimStatusMutation();

  const getSimsResponse = useSimcards();
  const { simcards } = getSimsResponse;

  const searchValue = searchParams.get('search') || '';
  const [globalFilter, setGlobalFilter] = useState<string>(searchValue);
  const [inputValue, setInputValue] = useState<string>(searchValue);

  const debouncedSetSearchParams = useMemo(
    () =>
      debounce((value: string) => {
        setSearchParams((prev) => {
          if (value) {
            prev.set('search', value);
          } else {
            prev.delete('search');
          }
          return prev;
        });
      }, 500),
    [setSearchParams],
  );

  useEffect(() => {
    const currentSearchValue = searchParams.get('search') || '';
    setInputValue(currentSearchValue);
    setGlobalFilter(currentSearchValue);
  }, [searchParams]);

  function handleFilter(value: string) {
    setInputValue(value);
    setGlobalFilter(value);
    debouncedSetSearchParams(value);
  }

  const sortingValue = searchParams.get('sort') || null;
  const desc = searchParams.get('desc') || null;
  const [sorting, setSorting] = useState<SortingState>(
    sortingValue ? [{ id: sortingValue, desc: desc === 'true' }] : [],
  );

  useEffect(() => {
    setSearchParams((prev) => {
      if (sorting[0]) {
        prev.set('sort', sorting[0].id);
        prev.set('desc', sorting[0].desc.toString());
        return prev;
      } else {
        prev.delete('sort');
        prev.delete('desc');
        return prev;
      }
    });
  }, [sorting]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const sims = simcards;

  const noSimsModalRef = useRef(null);
  useEffect(() => {
    if (getSimsResponse.isFulfilled) {
      if (sims.length === 0 && getItem('simModalOpen') !== 'opened') {
        noSimsModalRef.current.open();
      }
    }
  }, [getSimsResponse.isFulfilled]);

  // modals
  const smsModalRef = useRef(null);
  const assignToNetworkModalRef = useRef(null);

  // delete sim
  const deleteSimModalRef = useRef(null);
  const handleDeleteSim = async () => {
    const iccids = table.getSelectedRowModel().rows.map((row) => row.original.iccid);
    try {
      await updateSimStatus({
        iccids,
        sim_state: SIM_STATE.DELETED,
      }).unwrap();
      toast.success(`${iccids.length > 1 ? 'Sims' : 'Sim'} deleted successfully`);
    } catch (error) {
      toast.error(error.errorMessage || t('errorMessage.somethingWentWrongGetInTouch'));
    } finally {
      deleteSimModalRef.current.close();
    }
  };

  const updatedColumns = useMemo(() => updateColumnsWithCustomDataSorting(columns), [columns]);

  // table definition
  const table: Table<SimcardsListViewItem> = useReactTable({
    data: sims,
    columns: updatedColumns,
    getCoreRowModel: getCoreRowModel(), //row model
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    filterFns: {
      tagsFilter: customTagsFilter,
      coverageFilter: customCoverageFilter,
    },
    state: {
      globalFilter,
      sorting,
      columnFilters,
      columnVisibility: {
        network_id: false,
        search_iccid: false,
        search_sim_state: false,
        actions: false,
      },
    },
    globalFilterFn: 'includesString',
  });

  const { pageIndex } = table.getState().pagination;
  const pageCount = table.getPageCount();

  // Little hack to get tha page started with 50
  // Consider saving this preference in user profile or local storage
  useEffect(() => table.setPageSize(50), []); // eslint-disable-line react-hooks/exhaustive-deps

  // This returns the count of sims that are selected, filtered or all
  // In this order of priority
  return (
    <div className="tw-flex tw-h-full tw-gap-1.5">
      <Sidebar table={table} />
      <Card className="tw-w-full">
        <Card.Header className="">
          <div className="d-flex justify-content-between align-items-center">
            <div className="w-75">
              <TransparentInput
                placeholder="Search Device Name, ICCID and more"
                value={inputValue}
                onChange={(e) => handleFilter(e.target.value)}
                icon="search"
              />
            </div>
            <Select
              options={PAGE_SIZE_OPTIONS}
              selected={PAGE_SIZE_OPTIONS.find(({ value }) => value === table.getState().pagination.pageSize)}
              onClick={(e) => {
                table.setPageSize(Number(e.value));
              }}
            />
          </div>
        </Card.Header>
        <LayoutGroup>
          <ActionBar
            table={table}
            deleteSimModalRef={deleteSimModalRef}
            smsModalRef={smsModalRef}
            assignToNetworkModalRef={assignToNetworkModalRef}
          />
          <div className="table-responsive tw-w-full">
            <table className="table-auto tw-text-s tw-w-full tw-bg-white">
              <thead className="text-uppercase">
                {getSimsResponse.isLoading && table.getRowModel().rows.length === 0 ? (
                  <tr className="bg-light w-100">
                    <td>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  table.getHeaderGroups().map((headerGroup, index) => (
                    <tr key={`${headerGroup.id}_${index}`}>
                      {headerGroup.headers.map((header, index) => (
                        <th
                          key={`${header.id}_${index}`}
                          colSpan={header.id === 'actions' ? 2 : 0}
                          className={`${header.id === 'actions' && 'w-0'} tw-border-b tw-border-gray-200 tw-p-4 tw-text-xs tw-text-custom-header-grey`}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: header.column.getCanSort() ? 'tw-cursor-pointer tw-select-none' : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))
                )}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={`${row.original.iccid}_${index}`}
                    className="tw-h-full tw-border-b tw-border-gray-200 hover:tw-bg-gray-100"
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <td
                        key={`${cell.id}_${index}`}
                        className={`${cell.column.id !== 'select' && 'tw-cursor-pointer'} tw-p-4`}
                        onClick={() => cell.column.id !== 'select' && navigate(`${row.original.iccid}`)}
                        data-test-id={`simcards-table:${cell.column.id}-${index}`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                    <td className="tw-relative tw-h-full tw-border-t tw-p-4">
                      <div className="tw-flex tw-items-center tw-justify-center">
                        <RowDropdown
                          row={row}
                          smsModalRef={smsModalRef}
                          assignToNetworkModalRef={assignToNetworkModalRef}
                          deleteSimModalRef={deleteSimModalRef}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </LayoutGroup>
        <div className="card-footer d-flex justify-content-between mx-0 px-0 mt-auto">
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
            className="page-link border-end py-3 px-4 d-flex list-pagination-prev pagination pagination-tabs card-pagination justify-content-center align-items-center"
            style={{ borderBottomLeftRadius: '0.4rem' }}
          >
            <span>
              <i className="fe fe-arrow-left me-1 text-muted" />
              &nbsp;Prev
            </span>
          </button>
          <ul className="list-pagination pagination pagination-tabs card-pagination overflow-auto">
            {Array.from({ length: pageCount }, (_, index) => index + 1).map((index) => {
              return (
                <li
                  key={index}
                  className={pageIndex + 1 === index ? 'active' : ''}
                  onClick={() => table.setPageIndex(index - 1)}
                >
                  <button className="page">{index}</button>
                </li>
              );
            })}
          </ul>
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
            className="page-link py-3 px-4 border-start d-flex list-pagination-prev pagination pagination-tabs card-pagination align-items-center justify-content-center"
            style={{ borderBottomRightRadius: '0.4rem' }}
          >
            <span className="">
              <i className="fe fe-arrow-right" />
              &nbsp;Next
            </span>
          </button>
        </div>
      </Card>
      <ConfirmationModal
        title="Delete SIM Card"
        subtitle={`Are you sure you want to delete ${
          table.getSelectedRowModel().rows.length === 0
            ? '1 SIM card'
            : `${table.getSelectedRowModel().rows.length} SIM cards`
        }? This action can not be undone.`}
        ref={deleteSimModalRef}
        confirmCallback={handleDeleteSim}
      />
      <SendSmsModal ref={smsModalRef} />
      <AssignToNetworkModal ref={assignToNetworkModalRef} />
      <NoSimsModal ref={noSimsModalRef} />
    </div>
  );
};
