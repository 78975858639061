// import { Auth } from "aws-amplify";
import { signUp } from 'aws-amplify/auth';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SignUpForm } from '../index';

type SignUpParameters = {
  password: string;
  email: string;
  phone_number: string;
};

const SignUp = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (
    { password, email, name },
    { setSubmitting }: SignUpParameters & { setSubmitting: (submitting: boolean) => void },
  ) => {
    setErrorMessage('');
    const lowerEmail = email.toLowerCase();
    try {
      const { nextStep } = await signUp({
        username: lowerEmail,
        password,
        options: {
          userAttributes: {
            email: lowerEmail,
            name,
          },
        },
      });

      if (nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
        navigate('/auth/signup/confirm', { state: { lowerEmail } });
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
    setSubmitting(false);
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm={12} md={5} lg={6} xl={4} className="px-lg-6 my-5 align-self-center">
          <h1 className="display-4 text-center mb-3">Create a free account</h1>
          <p className="text-muted text-center mb-5">Start connecting your devices.</p>
          <SignUpForm onSubmit={handleSubmit} />
          <span className="text-danger">{errorMessage}</span>
        </Col>
        <Col sm={12} md={7} lg={6} xl={8} className="d-none d-lg-block px-0">
          <div className="bg-cover h-100 min-vh-100 bg-sign-in" />
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
