import * as Sentry from "@sentry/react";
import moment from "moment-timezone";

export function convertLogTimes(logs: string[], timezone: string) {
  const isoRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
  const otherRegex = /\w{3} \w{3} \d{2} \d{2}:\d{2}:\d{2} \d{4}/;

  const formatCheck = logs.filter((log) => {
    return isoRegex.test(log) || otherRegex.test(log);
  });

  if (formatCheck.length !== logs.length) {
    Sentry.captureException(new Error("Change in logs format"), {
      level: "error",
    });
  }
  return logs.map((log) => {
    let newLog = log;

    if (isoRegex.test(log)) {
      const match = log.match(isoRegex)[0];
      const utcTime = moment.utc(match, "YYYY-MM-DD HH:mm:ss");
      const localTime = utcTime.tz(timezone).format("YYYY-MM-DD HH:mm:ss");
      newLog = log.replace(match, localTime);
    } else if (otherRegex.test(log)) {
      const match = log.match(otherRegex)[0];
      const utcTime = moment.utc(match, "ddd MMM DD HH:mm:ss YYYY");
      const localTime = utcTime.tz(timezone).format("ddd MMM DD HH:mm:ss YYYY");
      newLog = log.replace(match, localTime);
    }

    return newLog;
  });
}
